import React, { useState, useEffect, useRef } from "react";
import iconUp from "../../../assets/icons/ico_up.png";
import iconDown from "../../../assets/icons/ico_down.png";
import { numberWithCommas } from "../../../utils/utils";
import OrderListItem from "./OrderListItem";
import {
  customNumChartWon,
  customPrice,
  customCurrentPrice,
} from "../styles.css";
interface OrderListProps {
  marketPrice: any;
  minPrice: number;
  maxPrice: number;
  height: number;
  tradeBuy: Array<any>;
  lastPrice: number;
  inputs: any;
  setInputs: any;
}

const OrderList: React.FC<OrderListProps> = ({
  marketPrice,
  minPrice,
  maxPrice,
  height,
  tradeBuy,
  lastPrice,
  inputs,
  setInputs,
}) => {
  const firstLoad = useRef(false);
  const contentRef = useRef(null);
  const middleRef = useRef(null);
  const firstBuyItem = tradeBuy.find((item) => item.tradeType === "BUY");

  const handleClickOrderPrice = (type: string) => {
    setInputs({
      ...inputs,
      price:
        type === "up"
          ? String(maxPrice)
          : type === "down"
          ? String(minPrice)
          : String(marketPrice?.price),
    });
  };

  useEffect(() => {
    if (!middleRef.current) return;
    if (firstLoad.current) return;
    // if (!(sellList.length || buyList.length)) return;

    firstLoad.current = true;
    const getAbsoluteTop = (element: any) => {
      return window.pageYOffset + element.getBoundingClientRect().top;
    };

    const parentElement = contentRef.current;
    const parentAbsoluteTop = getAbsoluteTop(parentElement);
    const absoluteTop = getAbsoluteTop(middleRef.current);
    const relativeTop = absoluteTop - parentAbsoluteTop;

    if (contentRef.current) {
      (contentRef.current as HTMLElement).scrollTo(0, relativeTop - height / 2);
    }
  }, [tradeBuy]);

  return (
    <div
      className="sell_wrap07_lt"
      ref={contentRef}
      style={{
        height: height,
        overflowY: "scroll",
      }}
    >
      <table className="num_chart">
        <tbody>
          <tr
            className="upper_price num_chart_st"
            onClick={() => handleClickOrderPrice("up")}
          >
            <td>
              <p className="fc-rd01">{numberWithCommas(maxPrice)}</p>
              <span className="fc-rd01">
                <img src={iconUp} alt="" />
                상한가
              </span>
            </td>
          </tr>
          {/* {sellList.map((item, index) => {
            const donPrice = item.orderPrice * 3.75;
            return (
              <OrderListItem
                key={index}
                lastPrice={lastPrice}
                data={item}
                donPrice={donPrice}
                inputs={inputs}
                setInputs={setInputs}
              />
            );
          })} */}
          {tradeBuy.map((item, index) => {
            const donPrice = item.orderPrice * 3.75;
            const isFirstBuy = item === firstBuyItem;
            const isMarketPrice = item.tradeType === null;
            if (isMarketPrice) {
              return (
                <tr
                  key={index}
                  className="now_price"
                  onClick={() => handleClickOrderPrice("market")}
                >
                  <td className={`num_chart_won ${customNumChartWon}`}>
                    <p
                      className={`${
                        marketPrice?.price &&
                        (marketPrice?.price > lastPrice
                          ? "fc-rd01"
                          : marketPrice?.price < lastPrice
                          ? "fc-bl01"
                          : "fc-gr01")
                      } ${customPrice}`}
                    >
                      <span className={customCurrentPrice}>현재가 </span>
                      {marketPrice?.price &&
                        numberWithCommas(marketPrice?.price)}
                    </p>
                    <span>
                      {marketPrice?.price &&
                        numberWithCommas(marketPrice.price * 3.75)}
                      /돈
                    </span>
                  </td>
                </tr>
              );
            }
            return (
              <React.Fragment key={index}>
                {isFirstBuy && <div ref={middleRef} />}
                <OrderListItem
                  key={index}
                  lastPrice={lastPrice}
                  data={item}
                  donPrice={donPrice}
                  inputs={inputs}
                  setInputs={setInputs}
                />
              </React.Fragment>
            );
          })}
          <tr
            className="lower_price num_chart_st"
            onClick={() => handleClickOrderPrice("down")}
          >
            <td>
              <p className="fc-bl01">{numberWithCommas(minPrice)}</p>
              <span className="fc-bl01">
                <img src={iconDown} alt="" />
                하한가
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      {/* <table className="num_chart">
        <tbody>
          <tr
            className="now_price"
            onClick={() => handleClickOrderPrice("market")}
          >
            <td className="num_chart_won">
              <p
                className={`${
                  marketPrice?.price &&
                  (marketPrice?.price > lastPrice
                    ? "fc-rd01"
                    : marketPrice?.price < lastPrice
                    ? "fc-bl01"
                    : "fc-gr01")
                }`}
              >
                {marketPrice?.price && numberWithCommas(marketPrice?.price)}
              </p>
              <span>
                {marketPrice?.price &&
                  numberWithCommas(marketPrice.price * 3.75)}
                /돈
              </span>
            </td>
          </tr>
        </tbody>
      </table> */}
      {/* <div ref={middleRef} /> */}
      {/* <table className="num_chart lower_price">
        <tbody>
          {buyList.map((item, index) => {
            const donPrice = item.orderPrice * 3.75;
            return (
              <OrderListItem
                key={index}
                lastPrice={lastPrice}
                data={item}
                donPrice={donPrice}
                inputs={inputs}
                setInputs={setInputs}
              />
            );
          })}
          <tr
            className="num_chart_st lower_price"
            onClick={() => handleClickOrderPrice("down")}
          >
            <td>
              <p className="fc-bl01">{numberWithCommas(minPrice)}</p>
              <span className="fc-bl01">
                <img src={iconDown} alt="" />
                하한가
              </span>
            </td>
          </tr>
        </tbody>
      </table> */}
    </div>
  );
};

export default OrderList;
