import { useDispatch } from "react-redux";
import useCustomMutation from "@/hooks/queries/useCustomMutation";
import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import * as APIS from "@/services/apis";
import { routes } from "@/routes";
import { showToast } from "@/features/toast/toastSlice";

export interface IDeliveryInfo {
  postCode: string;
  address: string;
  addressDetail: string;
  addressName: string;
  receiver: string;
  phone: string;
  mobile: string;
  memo: string;
  isDefault: boolean;
}

interface IError {
  response?: {
    data?: {
      message: string;
    };
  };
}

const useAddressDelete = (addressRefetch: () => void) => {
  const dispatch = useDispatch();

  const mutationFn = async (editId: number): Promise<any> => {
    dispatch(showLoading());
    try {
      const response = await APIS.deleteAddress(editId);
      dispatch(hideLoading());
      if (response.data.success) {
        dispatch(
          showToast({ message: "배송지가 삭제되었습니다.", icon: "success" }),
        );
        addressRefetch();
        return response.data.data;
      } else {
        dispatch(showToast({ message: response.data.message, icon: "error" }));
      }
    } catch (error) {
      dispatch(hideLoading());
      const err = error as IError;
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          showToast({ message: err.response.data.message, icon: "error" }),
        );
      }
      throw error;
    }
  };

  const mutation = useCustomMutation(
    [routes.deliveryAddressManagement, "/delete"],
    mutationFn,
  );

  return mutation;
};

export default useAddressDelete;
