import React from "react";
import iconLeft from "../../../assets/icons/icon_left.png";
import imgSecurity from "../../../assets/images/img_security2.png";
import * as APIS from "../../../services/apis";
import { postMessage } from "@/utils/utils";
import { routes } from "@/routes";
import colors from "@assets/styles/colors.css";
import * as styles from "../style.css";

const isDev = import.meta.env.VITE_SERVICE_TYPE === "DEVELOPMENT";
var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

interface AuthenticationProps {
  setProgressScreen: React.Dispatch<
    React.SetStateAction<"home" | "agency" | "date" | "auth" | "completed">
  >;
}

const Authentication: React.FC<AuthenticationProps> = ({
  setProgressScreen,
}) => {
  const purpose = "SIGNUP";

  const handleClickVerification = () => {
    APIS.getSelfVerificationRequestId(purpose).then(
      ({ data: { requestId } }) => {
        if (isDev && !isMobile) {
          window.location.href = `${
            import.meta.env.VITE_API_URL
          }/api/v2/user/verification/self/start?requestId=${requestId}`;
          postMessage({
            command: "WINDOW_OPEN",
            param: {
              visible: true,
              title: "본인인증",
              url: `${
                import.meta.env.VITE_API_URL
              }/api/v2/user/verification/self/start?requestId=${requestId}`,
            },
          });
        } else {
          postMessage({
            command: "TAB_OPEN",
            param: {
              url: `${
                import.meta.env.VITE_API_URL
              }/api/v2/user/verification/self/start?requestId=${requestId}`,
              gateway: routes.authGateway,
            },
          });
        }
      },
    );
  };
  return (
    <div className="sub_bottom security_tab">
      <div className="sub_bottom_wrap">
        <div className="sub_top01" onClick={() => setProgressScreen("home")}>
          <h3 className="sub_bottom_tit">
            <img src={iconLeft} alt="" className="icon_back" />
            본인인증
          </h3>
        </div>
        <div className="security_txt">
          <img src={imgSecurity} />
          <h3>
            안전한 거래를 위해
            <br />
            본인 인증이 필요합니다.
          </h3>
        </div>
      </div>
      <div className={styles.subBtnWrap}>
        <button
          className={styles.subWrap02Btn}
          onClick={handleClickVerification}
        >
          본인 인증 하기
        </button>
      </div>
    </div>
  );
};

export default Authentication;
