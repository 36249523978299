import React from "react";
import {
  afterAssetContainer,
  afterAssetTitle,
  afterAssetLow,
} from "../styles.css";
import { numberWithCommas } from "@/utils/utils";

interface IAfterAssetProps {
  afterGold: number;
  afterSilver: number;
  afterKrw: number;
  afterPoint: number;
}

const AfterAsset: React.FC<IAfterAssetProps> = ({
  afterGold,
  afterSilver,
  afterKrw,
  afterPoint,
}) => {
  return (
    <div className={afterAssetContainer}>
      <h3 className={afterAssetTitle}>거래 후 잔고</h3>
      <div className="shadow88_ul02">
        <ul className={afterAssetLow}>
          <li className="shadow88_li01">
            <p>금</p>
          </li>
          <li className="shadow88_li02">
            <p>{numberWithCommas(afterGold)} g</p>
          </li>
        </ul>
        <ul className={afterAssetLow}>
          <li className="shadow88_li01">
            <p>은</p>
          </li>
          <li className="shadow88_li02">
            <p>{numberWithCommas(afterSilver)} g</p>
          </li>
        </ul>
        <ul className={afterAssetLow}>
          <li className="shadow88_li01">
            <p>원화</p>
          </li>
          <li className="shadow88_li02">
            <p>{numberWithCommas(afterKrw)} 원</p>
          </li>
        </ul>{" "}
        <ul className={afterAssetLow}>
          <li className="shadow88_li01">
            <p>포인트</p>
          </li>
          <li className="shadow88_li02">
            <p>{numberWithCommas(afterPoint)} pt</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AfterAsset;
