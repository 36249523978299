import { RootState } from "@/app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RecommendShop {
  id: number | null;
  name: string;
  [key: string]: any;
}
interface SignUpState {
  purpose: string;
  requestId: string;
  termsTypes: any; //string[]
  shopCity: RecommendShop;
  shopDistrictFirst: RecommendShop;
  shopDistrictSecond: RecommendShop;
  recommendShop: RecommendShop | null;
  email: string;
}
// City 시
// District 구

const initialState: SignUpState = {
  purpose: "",
  requestId: "",
  termsTypes: [],
  shopCity: { id: 0, name: "" },
  shopDistrictFirst: { id: null, name: "" },
  shopDistrictSecond: { id: null, name: "" },
  recommendShop: { id: null, name: "" },
  email: "",
};

const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    initSignUp: (state, action: PayloadAction<Partial<SignUpState>>) => {
      return { ...initialState, ...action.payload };
    },

    updateSignUp: (state, action: PayloadAction<Partial<SignUpState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { initSignUp, updateSignUp } = signUpSlice.actions;
export const selectSignUp = (state: RootState) => state.signUp;
export default signUpSlice.reducer;
