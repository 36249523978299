import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DetailHeader from "@components/layouts/DetailHeader";
import useEventList, { EventItemType } from "./hooks/useEventList";
import EventItem from "./EventItem";
import { ButtonBase } from "@mui/material";
import { postMessage } from "@/utils/utils";

type Props = {};

function Event({}: Props) {
  const navigate = useNavigate();
  const { data } = useEventList();
  const [tag, setTag] = useState("ongoing");
  const [openEventList, setOpenEventList] = useState<EventItemType[]>([]);
  const [closedEventList, setClosedEventList] = useState<EventItemType[]>([]);

  useEffect(() => {
    // console.log(data);
    if (data?.openEventList) setOpenEventList(data?.openEventList);
    if (data?.closedEventList) setClosedEventList(data?.closedEventList);
  }, [data?.openEventList, data?.closedEventList]);

  return (
    <div className="detail">
      <DetailHeader title="이벤트" backCallback={() => navigate(-1)} />
      <div className="content">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ButtonBase
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              height: "48px",
              borderBottom: tag === "ongoing" ? "2px solid #00BAFF" : 0,
              margin: "0 16px",
            }}
            onClick={() => setTag("ongoing")}
          >
            <span
              style={{
                fontSize: "16px",
                color: tag === "ongoing" ? "#00BAFF" : "#999",
                fontWeight: "700",
              }}
            >
              진행중인 이벤트
            </span>
          </ButtonBase>
          <div
            style={{ width: "2px", height: "16px", backgroundColor: "#DEEBF3" }}
          />
          <ButtonBase
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              height: "48px",
              borderBottom: tag === "closed" ? "2px solid #00BAFF" : 0,
              margin: "0 16px",
            }}
            onClick={() => setTag("closed")}
          >
            <span
              style={{
                fontSize: "16px",
                color: tag === "closed" ? "#00BAFF" : "#999",
              }}
            >
              종료된 이벤트
            </span>
          </ButtonBase>
        </div>
        <div
          style={{
            backgroundColor: "#F5F5F5",
            padding: "0 24px 24px",
            height: "100%",
          }}
        >
          {tag === "ongoing" ? (
            openEventList.length ? (
              openEventList.map((item, index) => {
                return (
                  <EventItem
                    key={index}
                    item={item}
                    type={"ongoing"}
                    onClick={() => {
                      postMessage({
                        command: "WINDOW_OPEN",
                        param: {
                          url: item.content,
                        },
                      });
                      window.open(item.content);
                    }}
                  />
                );
              })
            ) : (
              <div style={{ marginTop: 20, flex: 1, alignItems: "center" }}>
                진행중인 이벤트가 없습니다.
              </div>
            )
          ) : closedEventList.length ? (
            closedEventList.map((item, index) => {
              return <EventItem key={index} item={item} type={"closed"} />;
            })
          ) : (
            <div style={{ marginTop: 20, flex: 1, alignItems: "center" }}>
              종료된 이벤트가 없습니다.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Event;
