import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import useCustomQuery from "@/hooks/queries/useCustomQuery";
import { routes } from "@/routes";
import { useDispatch } from "react-redux";
import * as APIS from "@/services/apis";

export type GoodsListInfo = Array<{
  goodsId: number;
  image?: string;
  brand?: string;
  goodsName: string;
  goodsGram: number;
  standardPrice: number;
  isSoldOut: boolean;
  sellingPrice: number;
  createdAt: string;
  updatedAt: string;
  assetType: string;
  detailMallUrl: any;
  salesCount: number;
  discountRate: number;
  vreviewId?: string;
}>;

const useGoodsList = () => {
  const dispatch = useDispatch();

  const fetchGoodsList = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getGoodsList();
      dispatch(hideLoading());
      return response.data.data as GoodsListInfo;
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading } = useCustomQuery(
    [routes.buy, "list"],
    fetchGoodsList,
  );
  return { data, error, isLoading };
};

export default useGoodsList;
