import { useNavigate } from "react-router-dom";
import DetailHeader from "@components/layouts/DetailHeader";
import useGetNotification from "./hooks/useGetNotification";
import { formatDateString, formatTime } from "@/utils/utils";
import {
  bodyWrap,
  contentWrap,
  dateWrap,
  listIcon,
  listIconWrap,
  listWrap,
  titleWrap,
} from "./styles.css";
import ico_Banking from "@/assets/icons/ico_banking.png";
import ico_Appraisal from "@/assets/icons/ico_appraisal.png";
import ico_Trading from "@/assets/icons/ico_trading.png";
import ico_Withdrawal from "@/assets/icons/ico_withdrawal.png";
import ico_Event from "@/assets/icons/ico_event.png";
import ico_Default from "@/assets/icons/ico_default.png";

type Props = {};

const notiType = [
  { title: "입/출금", source: ico_Banking },
  { title: "간편감정", source: ico_Appraisal },
  { title: "매수/매도", source: ico_Trading },
  { title: "실물인출", source: ico_Withdrawal },
  { title: "이벤트", source: ico_Event },
  { title: "푸시 알림", source: ico_Default },
  { title: "관리자 푸시 알림", source: ico_Default },
  { title: "마케팅", source: ico_Default },
];

function Notification({}: Props) {
  const navigate = useNavigate();
  const { notificationInfo } = useGetNotification();
  return (
    <div>
      <DetailHeader
        title="알림"
        backCallback={() => {
          navigate(-1);
        }}
      />
      <div className="sub_wrap02">
        {notificationInfo?.map((item) => {
          let source = notiType.find(
            (noti) => noti.title === item.pushCategory,
          );
          return (
            <div key={`${item.pushedAt}_${item.pushType}`} className={listWrap}>
              <div className={listIconWrap}>
                <img className={listIcon} src={source?.source} alt="" />
              </div>
              <div className={contentWrap}>
                <div className={titleWrap}>
                  <div>{item.title}</div>
                  <div className={dateWrap}>
                    <span>{formatTime(new Date(item.pushedAt))}</span>
                  </div>
                </div>
                <div className={bodyWrap}>
                  <span>{item.body}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Notification;
