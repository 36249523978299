import React from "react";
import iconLeft from "../../../assets/icons/icon_left.png";
import Calendar from "../../../components/common/calendar/Calendar";
import colors from "@assets/styles/colors.css";

interface ReservationDataProps {
  shopId?: number | null;
  visitDate?: any;
}
interface SelectDateProps {
  goHome: () => void;
  reservationData: ReservationDataProps;
  setReservationData: React.Dispatch<React.SetStateAction<object>>;
}

const SelectDate: React.FC<SelectDateProps> = ({
  goHome,
  reservationData,
  setReservationData,
}) => {
  const handleClickSelect = () => {
    if (reservationData.visitDate) {
      goHome();
    }
  };
  return (
    <div className="sub_bottom cal_tab">
      <div className="sub_bottom_wrap">
        <div className="sub_top01">
          <h3 className="sub_bottom_tit">
            <img src={iconLeft} alt="" className="icon_back" onClick={goHome} />
            예약일 선택
          </h3>
        </div>
        <Calendar
          reservationData={reservationData}
          setReservationData={setReservationData}
        />
      </div>
      <div className="sub_btn_wrap">
        <button
          className={`sub_wrap02_btn ${reservationData.visitDate ? "on" : ""}`}
          onClick={handleClickSelect}
          style={{
            backgroundColor: reservationData.visitDate && colors.buttonColor,
          }}
        >
          선택
        </button>
      </div>
    </div>
  );
};

export default SelectDate;
