import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import { showToast } from "@/features/toast/toastSlice";
import { RootState } from "@/app/store";
import useCustomMutation from "@/hooks/queries/useCustomMutation";
import * as APIS from "@/services/apis";
import { createPurchaseInfo } from "./createPaymentInfo";
interface IError {
  response?: {
    data?: {
      message: string;
    };
  };
}

// 결제 정보 임시 저장
const useSavePreApproval = () => {
  const dispatch = useDispatch();

  const mutationFn = async (paymentInfo: any): Promise<any> => {
    dispatch(showLoading());
    try {
      const response = await APIS.putPreApproval(
        createPurchaseInfo(paymentInfo),
      );
      dispatch(hideLoading());
      if (response.data.success) {
        return response;
      } else {
        dispatch(showToast({ message: response.data.message, icon: "error" }));
      }
    } catch (error) {
      dispatch(hideLoading());
      const err = error as IError;
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          showToast({ message: err.response.data.message, icon: "error" }),
        );
      }
      throw error;
    }
  };

  const mutation = useCustomMutation(["pre-approval"], mutationFn);

  return mutation;
};

export const useTossPayment = () => {
  const dispatch = useDispatch();
  const clientKey = import.meta.env.VITE_TOSS_CLIENT_KEY;
  const user = useSelector((state: RootState) => state.user);
  const purchaseInfo = useSelector((state: RootState) => state.purchase);
  const savePreApproval = useSavePreApproval();

  /**
   *
   * @param {*} id 상품 goodsId
   * @param {*} txId 서버 임시 저장 정보 ID
   */
  const requestTossPayment = async (txId: string) => {
    try {
      const paymentInfo = {
        amount: purchaseInfo?.depositPrice,
        orderId: uuidv4(),
        orderName: purchaseInfo?.orderName,
        customerName: user?.name,
        successUrl: `${window.location.origin}/toss-success/${purchaseInfo?.goodsId}/${txId}`,
        failUrl: `${window.location.origin}/toss-fail/${purchaseInfo?.goodsId}/${txId}`,
        useEscrow: true, // 230912 에스크로 필수
      };

      await loadTossPayments(clientKey).then((tossPayments) => {
        tossPayments.requestPayment("계좌이체", paymentInfo);
      });
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  /**
   * @description 임시 저장 후, 토스 퀵계좌결제창 실행
   */
  const TossProcess = async (paymentInfo: any) => {
    try {
      const response = await savePreApproval.mutateAsync(paymentInfo);

      const { success, data } = response.data;
      if (success) {
        requestTossPayment(data);
      } else {
        dispatch(showToast({ message: response.data.message, icon: "error" }));
      }
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  return {
    requestTossPayment,
    TossProcess,
  };
};
