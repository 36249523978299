import React from "react";
import { BottomSheet } from "@components/index";
import { IBankInfo } from "../hooks/useBank";
import IconClose from "@assets/icons/icon_x.png";
import { useDispatch } from "react-redux";
import { closeBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";

interface SelectBankProps {
  bankList: IBankInfo[] | undefined;
  selectedBank: IBankInfo | null;
  onChange: (bank: IBankInfo) => void;
}

const SelectBank: React.FC<SelectBankProps> = ({
  bankList,
  selectedBank,
  onChange,
}) => {
  const dispatch = useDispatch();
  const handleClickClose = () => {
    dispatch(closeBottomSheet());
  };
  return (
    <BottomSheet>
      <div className="sub_bottom02 category_tab02">
        <div className="sub_bottom02_wrap">
          <div className="sub_top01">
            <h3 className="sub_bottom_tit">은행 선택</h3>
            <img
              src={IconClose}
              alt="close"
              className="close"
              onClick={handleClickClose}
            />
          </div>
          <ul className="category_list02">
            {bankList?.map((bank) => (
              <li
                key={bank.code}
                className={selectedBank?.code === bank.code ? "on" : ""}
                onClick={() => onChange(bank)}
              >
                <p>{bank.name}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </BottomSheet>
  );
};

export default SelectBank;
