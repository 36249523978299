import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "../ImageSlider.css";
import * as styles from "../styles.css";
import imgBg from "@assets/images/img_bg.png";
import { Autoplay, Pagination, Zoom } from "swiper/modules";

interface ImageSliderProps {
  images: string[];
  isSoldOut: boolean;
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images, isSoldOut }) => {
  const S3_BASE_URL = import.meta.env.VITE_S3_BASE_URL;
  return (
    <div>
      {images ? (
        <Swiper
          spaceBetween={0}
          zoom={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, Zoom]}
          className={`detail-styled-swiper ${isSoldOut ? "isSoldOut" : ""}`}
        >
          {images?.map((item, index) => (
            <SwiperSlide key={index} className="detail-swiper-slide">
              <div className="sub02_pd_img">
                <img
                  className={`${isSoldOut ? styles.soldOut.image : ""}`}
                  src={`${S3_BASE_URL}/${item}`}
                  alt="상품이미지"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <img src={imgBg} alt="빈 이미지" />
      )}
    </div>
  );
};

export default ImageSlider;
