import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectMarketPrice } from "../../features/market-price/marketPriceSlice";
import Header from "./components/Header";
import BottomNavigation from "./components/BottomNavigation";
import MarketPriceSection from "./components/MarketPriceSection";
import Calculator from "./components/Calculator";
import Appraisal from "./components/Appraisal";
import AssetsAvailableSale from "./components/AssetsAvailableSale";
import iconX from "../../assets/icons/icon_x.png";
import bannerImg from "../../assets/images/banner_img.png";
import { requestNativeLogin } from "../../utils/utils";
import { selectAuth } from "../../features/auth/authSlice";
import { InitialState } from "../../model/marketPrice";
import { RootState } from "../../app/store";
import * as APIS from "../../services/apis";
import { selectFeeRate } from "@/features/fee-rate/feeRateSlice";
import { useDispatch } from "react-redux";
import { initFeeRate } from "@/utils/initFeeRate";
import Banners from "@components/common/banners/Banners";
import { useRefreshMarketPrice } from "@/hooks/queries/useRefreshMarketPrice";
import useInterval from "@/hooks/useInterval";

const Sell = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [assetType, setAssetType] = useState<"GOLD" | "SILVER">("GOLD");
  const marketPriceData = useSelector(selectMarketPrice);
  const refreshMarketPrice = useRefreshMarketPrice();
  const marketType = assetType
    .replace("GOLD", "gold")
    .replace("SILVER", "silver");
  const marketPrice = marketPriceData[marketType as keyof InitialState];
  const currentPrice = (marketPrice as any)?.current; //시세가
  const closingPrice = (marketPrice as any)?.closing;
  const { feeRate } = useSelector(selectFeeRate); //수수료율
  const { name } = useSelector((state: RootState) => state.user);
  const {
    role: { LEVEL },
    isSigned,
  } = useSelector((state: RootState) => state.auth);
  // const isSigned = signed || LEVEL;
  const isSelfVerification = isSigned && LEVEL === 1;
  const [holdings, setHoldings] = useState({
    krw: 0,
    gold: 0,
    silver: 0,
    point: 0,
    acceptableKrw: 0,
    acceptableGold: 0,
    acceptableSilver: 0,
    acceptablePoint: 0,
  });

  useMemo(() => {
    if (isSigned) {
      APIS.getUserHoldingsInfo().then(({ data: { success, data = {} } }) => {
        success && setHoldings(data);
      });
    }
  }, [isSigned]);

  useEffect(() => {
    initFeeRate(dispatch);
  }, [dispatch]);

  useInterval(() => {
    refreshMarketPrice();
  }, 10000);

  return (
    <div>
      <Header />
      <div className="sub_wrap">
        <div className="sell_top">
          <h3>팔래요</h3>
          {isSigned ? (
            !isSelfVerification && (
              <p className="on_login">안녕하세요.{name}님!</p>
            )
          ) : (
            <p className="logout" onClick={() => requestNativeLogin(navigate)}>
              로그인을 해주세요.
            </p>
          )}
        </div>
        <Banners />
        <MarketPriceSection
          assetType={assetType}
          marketPriceData={marketPriceData}
          currentPrice={currentPrice}
          closingPrice={closingPrice}
          setAssetType={setAssetType}
        />
        <Calculator
          assetType={assetType}
          currentPrice={currentPrice}
          feeRate={feeRate}
        />
        <Appraisal />
        {!!isSigned && (
          <AssetsAvailableSale
            holdings={holdings}
            currentPrice={currentPrice}
            feeRate={feeRate}
            navigate={navigate}
            marketPriceData={marketPriceData}
          />
        )}
      </div>
      <BottomNavigation />
    </div>
  );
};

export default Sell;
