import logo from "@assets/images/logo.png";
import HamburgerMenu from "./HamburgerMenu";

const Header = () => {
  return (
    <header>
      <a href="#">
        <img src={logo} alt="금방금방" className="logo" />
      </a>
      <HamburgerMenu />
    </header>
  );
};

export default Header;
