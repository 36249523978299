const STORAGE_PREFIX = "kumbang.";

// 로컬 스토리지에 데이터를 저장하는 함수
export const setLocalStorageItem = (key: string, value: any) => {
  localStorage.setItem(STORAGE_PREFIX + key, JSON.stringify(value));
};

// 로컬 스토리지에서 데이터를 검색하는 함수
export const getLocalStorageItem = (key: string) => {
  const value = localStorage.getItem(STORAGE_PREFIX + key);
  if (value) {
    return JSON.parse(value);
  }
  return null;
};

// 세션 스토리지에 데이터를 저장하는 함수
export const setSessionStorageItem = (key: string, value: any) => {
  sessionStorage.setItem(STORAGE_PREFIX + key, JSON.stringify(value));
};

// 세션 스토리지에서 데이터를 검색하는 함수
export const getSessionStorageItem = (key: string) => {
  const value = sessionStorage.getItem(STORAGE_PREFIX + key);
  if (value) {
    return JSON.parse(value);
  }
  return null;
};

// 세션 스토리지에서 데이터를 삭제하는 함수
export const removeSessionStorageItem = (key: string) => {
  sessionStorage.removeItem(STORAGE_PREFIX + key);
};
