import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface TenSecondAppraisalState {
  tenSecondAppraisal: {
    assetType: string;
    item: string;
    purity: string;
    weightUnit: string;
    weight: number;
    appraisalWeight: number;
    weightRatePct: number;
    appraisalTime: string;
    estimatedSalesPrice: number;
    appraisalCompletedList: AppraisalItem[];
  };
}

interface AppraisalItem {
  assetType: string;
  item: string;
  purity: string;
  weight: number;
  weightUnit: string;
  appraisalWeight: number;
  weightRatePct: number;
  appraisalTime: string;
  estimatedSalesPrice: number;
}

const initialState: TenSecondAppraisalState = {
  tenSecondAppraisal: {
    assetType: "",
    item: "",
    purity: "",
    weightUnit: "",
    weight: 0,
    weightRatePct: 0,
    appraisalWeight: 0,
    appraisalTime: "", // 감정시간
    estimatedSalesPrice: 0, // 판매 예상가
    appraisalCompletedList: [],
  },
};

export const tenSecondAppraisalSlice = createSlice({
  name: "appraisalTenSecond",
  initialState,
  reducers: {
    setTenSecondAppraisal: (state, action) => {
      state.tenSecondAppraisal = {
        ...state.tenSecondAppraisal,
        ...action.payload,
      };
    },
    resetTenSecondAppraisal: (state) => {
      state.tenSecondAppraisal = initialState.tenSecondAppraisal;
    },
  },
});

export const { setTenSecondAppraisal, resetTenSecondAppraisal } =
  tenSecondAppraisalSlice.actions;
export const selectTenAppraisal = (state: RootState) =>
  state.tenSecondAppraisal;

export default tenSecondAppraisalSlice.reducer;
