import { routes } from "@/routes";
import { useNavigate } from "react-router-dom";
import type { GoodsListInfo } from "../hooks/useGoodsList";
import constants from "@/utils/constants";
import { numberWithCommas } from "@/utils/utils";
import ProductGrade from "@components/reviewWidget/ProductGrade";
import { soldOut } from "../styles.css";
import { setLocalStorageItem } from "@/services/storageService";

interface GoodsCardInfoProps {
  goodsListData: GoodsListInfo | undefined;
  tab?: number;
}
const GoodsCardInfo = (props: GoodsCardInfoProps) => {
  const { goodsListData, tab } = props;
  const navigate = useNavigate();
  const handleClickDetail = (goodsId: number) => {
    if (tab) {
      setLocalStorageItem("fromBuy", tab);
    }
    navigate(`${routes.buyGoodsDetail}/${goodsId}`);
  };

  if (!goodsListData) {
    return undefined;
  }

  return (
    <div className="buy_main01_cont" style={{ display: "flex" }}>
      <div className="buy_list02">
        {goodsListData.map((listItem) => {
          const { isSoldOut } = listItem;

          return (
            <div
              key={listItem.goodsId}
              className="list02_box"
              onClick={() => handleClickDetail(listItem.goodsId)}
            >
              <div className="list_img">
                <img
                  src={`${constants.s3BaseUrl}/${listItem.image}`}
                  className={`${isSoldOut ? soldOut.image : ""}`}
                  alt="상품이미지"
                />
              </div>
              <div className="list_cont">
                <h3>
                  {!isSoldOut && (
                    <>
                      <span className="per fc-rd01">
                        {listItem.discountRate ?? 0}%
                      </span>
                      {numberWithCommas(listItem.sellingPrice)}원
                    </>
                  )}
                </h3>
                <p className={`cost ${isSoldOut && soldOut.text}`}>
                  {numberWithCommas(listItem.standardPrice)}원
                </p>
                <p className={`pd_name ${isSoldOut && soldOut.text}`}>
                  {listItem.goodsName}{" "}
                </p>
                <p className={`pd_brand ${isSoldOut && soldOut.text}`}>
                  {Boolean(listItem.brand) && listItem.brand}
                </p>
                <div>
                  <ul>
                    {isSoldOut ? (
                      <div className={soldOut.korButton}>품절</div>
                    ) : (
                      <>
                        {/* <li className="score">
                      </li> */}
                        <ProductGrade id={listItem.goodsId} />
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GoodsCardInfo;
