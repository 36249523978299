import React, { useState, useEffect } from "react";
import { numberWithCommas } from "../../../utils/utils";

interface CalculatorProps {
  assetType: string;
  currentPrice: string;
  feeRate: FeeRate[];
}

interface FeeRate {
  configType: string;
  assetType: string;
  feeRate: number;
  priceLimit: number;
}

const Calculator: React.FC<CalculatorProps> = ({
  assetType,
  currentPrice,
  feeRate,
}) => {
  const [weightUnit, setWeightUnit] = useState<string>("g");
  const [weight, setWeight] = useState<number | undefined>(undefined);
  const [totalPrice, setTotalPrice] = useState(0);
  const [feeValue, setFeeValue] = useState(0);

  const handleTabClick = (value: string) => {
    setWeightUnit(value);
    if (weight !== undefined) {
      const fakeEvent = {
        target: { value: weight.toString() },
      } as React.ChangeEvent<HTMLInputElement>;
      handleChangeWeight(fakeEvent, value);
    } else {
      setTotalPrice(0);
    }
  };

  const handleChangeWeight = (
    e: React.ChangeEvent<HTMLInputElement>,
    unit: string,
  ) => {
    const value = parseFloat(e.target.value);
    let goldWeight = 0;
    let silverWeight = 0;
    if (!isNaN(value)) {
      if (unit === "g") {
        if (assetType === "GOLD") {
          goldWeight = Math.round(value * 1000) / 1000;
          setWeight(goldWeight);
        } else if (assetType === "SILVER") {
          silverWeight = Math.round(value * 10) / 10;
          setWeight(silverWeight);
        }
      } else if (unit === "don") {
        if (assetType === "GOLD") {
          goldWeight = Math.round(value * 10) / 10;
          setWeight(goldWeight);
        } else if (assetType === "SILVER") {
          silverWeight = Math.round(value);
          setWeight(silverWeight);
        }
      }
      const assetValue = assetType === "GOLD" ? goldWeight : silverWeight;
      const total =
        unit === "g"
          ? assetValue * parseInt(currentPrice) * feeValue
          : assetValue * parseInt(currentPrice) * 3.75 * feeValue;
      setTotalPrice(total <= 0 ? 0 : Math.floor(total));
    } else {
      setWeight(undefined);
      setTotalPrice(0);
    }
  };

  useEffect(() => {
    const selectFeeRate = feeRate.find(
      (item) => item.assetType === assetType && item.configType === "SELL",
    )?.feeRate;
    if (selectFeeRate) {
      const value = 1 - selectFeeRate;
      setFeeValue(value);
    }
  }, [assetType, currentPrice, feeRate]);

  useEffect(() => {
    setTotalPrice(0);
    setWeight(undefined);
  }, [assetType]);

  return (
    <div className="sell_main sell_main02 shadow88">
      <p>
        팔 때 가격 계산기{" "}
        <span>
          ({assetType === "GOLD" ? "순금" : "순은"} 기준/판매 수수료 제외 금액)
        </span>
      </p>
      <div className="calculator">
        <input
          type="number"
          placeholder="중량을 입력해 주세요."
          value={weight !== undefined ? weight : ""}
          onChange={(e) => handleChangeWeight(e, weightUnit)}
        />
        <ul className="selltab">
          <li
            className={weightUnit === "don" ? "on don" : "don"}
            onClick={() => handleTabClick("don")}
          >
            돈
          </li>
          <li
            className={weightUnit === "g" ? "on gram" : "gram"}
            onClick={() => handleTabClick("g")}
          >
            g
          </li>
        </ul>
      </div>
      <p className="total_price">
        <span className="spoqa">{numberWithCommas(totalPrice)}</span>원
      </p>
    </div>
  );
};

export default Calculator;
