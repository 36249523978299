import useCustomQuery from "@/hooks/queries/useCustomQuery";
import * as APIS from "@/services/apis";
import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { routes } from "@/routes";

export interface IBankInfo {
  code: string;
  name: string;
  path: string | null;
}

const useBankList = () => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getBankList();
      dispatch(hideLoading());
      return response.data.data as IBankInfo[];
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading } = useCustomQuery<IBankInfo[]>(
    [routes.myAccountVerification],
    fetchDetails,
  );

  return { BankList: data, isLoading, error };
};

export default useBankList;
