import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import IconLeft from "@assets/icons/icon_left.png";
import { resetPurchaseInfo } from "@/features/purchase/purchaseSlice";
import IngTradeHistory from "./components/IngTradeHistory";
import CompleteTradeHistory from "./components/CompleteTradeHistory";
import { BottomSheet } from "@components/index";
import { openBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";

const TradeHistory: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const initialStatusType = queryParams.get("statusType") || "ING";

  const [statusType, setStatusType] = useState<string>(initialStatusType);
  const [bottomSheetContent, setBottomSheetContent] =
    useState<React.ReactNode | null>(null);

  useEffect(() => {
    dispatch(resetPurchaseInfo());
  }, [dispatch]);

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleOpenBottomSheet = (content: React.ReactNode) => {
    setBottomSheetContent(content);
    dispatch(openBottomSheet());
  };

  const handleTabChange = (newStatusType: string) => {
    setStatusType(newStatusType);
    const params = new URLSearchParams(location.search);

    // ING일 때만 tradeType 유지
    if (newStatusType === "ING") {
      const currentTradeType = queryParams.get("tradeType") || "APPRAISAL";
      params.set("tradeType", currentTradeType);
    } else {
      params.delete("tradeType"); // COMPLETE일 때는 tradeType 제거
    }

    params.set("statusType", newStatusType);
    navigate({ search: params.toString() }, { replace: true });
  };

  return (
    <>
      <BottomSheet>{bottomSheetContent}</BottomSheet>
      <div style={{ background: "#F5F5F5", minHeight: "100%" }}>
        <div className="sub_top03">
          <div>
            <img
              src={IconLeft}
              alt=""
              className="icon_back"
              onClick={handleClickBack}
            />
            <p>내역</p>
          </div>
        </div>
        <div className="sub_wrap08">
          <div className="sub08_top">
            <div className="sub08_tap">
              <ul>
                <li
                  className={statusType === "ING" ? "on" : ""}
                  onClick={() => handleTabChange("ING")}
                >
                  <p>진행중</p>
                </li>
                <li className="bar"></li>
                <li
                  className={statusType === "COMPLETE" ? "on" : ""}
                  onClick={() => handleTabChange("COMPLETE")}
                >
                  <p>완료</p>
                </li>
              </ul>
            </div>
          </div>
          {statusType === "ING" ? (
            <IngTradeHistory openBottomSheet={handleOpenBottomSheet} />
          ) : (
            <CompleteTradeHistory openBottomSheet={handleOpenBottomSheet} />
          )}
        </div>
      </div>
    </>
  );
};

export default TradeHistory;
