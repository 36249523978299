import React, { useState, ReactNode, useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { BottomSheet } from "@components/index";

import BtnMinus from "@assets/images/btn_minus.png";
import BtnMinusBk from "@assets/images/btn_minus_bk.png";
import BtnPlus from "@assets/images/btn_plus.png";
import DownArrow from "@assets/icons/ico_24.png";
import ImgOptionClose from "@assets/images/img_option_close.png";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "@/routes";
import { RootState } from "@/app/store";
import { useDispatch, useSelector } from "react-redux";
import { setPurchaseInfo } from "@/features/purchase/purchaseSlice";
import { closeBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";
import usePreprice from "../hooks/usePreprice";
import { numberWithCommas } from "@/utils/utils";
import { showToast } from "@/features/toast/toastSlice";
import colors from "@assets/styles/colors.css";

type Inputs = {
  qty: number;
  optionValue: string;
  standardPrice: number;
  sellingPrice: number;
};

export interface GoodsOptionInfo {
  goodsOptionsInfoId: number;
  optionKind: string;
  optionName: string;
  optionValue: string;
  sortOrder: number;
  isVisible: string;
}

interface SelectOptionProps {
  standardPrice: number;
  goodsOptionsInfos: GoodsOptionInfo[];
}

const SelectOption: React.FC<SelectOptionProps> = ({
  standardPrice,
  goodsOptionsInfos,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const purchaseInfo = useSelector((state: RootState) => state.purchase);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      qty: purchaseInfo.qty || 1,
      optionValue: purchaseInfo.optionValue,
      standardPrice: purchaseInfo.standardPrice,
      sellingPrice: standardPrice || 0,
    },
  });
  const { Preprice, PrepriceRefetch } = usePreprice({
    qty: watch("qty"),
    goodsId: params.goodsId || "",
    useGram: 0,
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const { optionValue } = data;
    const isOptionAvailable = goodsOptionsInfos.length > 0;
    const purchasePath = `${routes.buyGoodsPurchase}/${params?.goodsId}`;

    const handlePurchase = () => {
      dispatch(setPurchaseInfo(data));
      navigate(purchasePath);
      dispatch(closeBottomSheet());
    };

    // 옵션 있을 때 validation 체크
    if (isOptionAvailable) {
      if (optionValue === "") {
        dispatch(
          showToast({ message: "옵션을 선택해주세요", icon: "caution" }),
        );
        return;
      }
    }

    handlePurchase();
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    purchaseInfo.optionValue || "선택",
  );

  useEffect(() => {
    reset({
      qty: 1,
      optionValue: "",
      standardPrice: standardPrice,
      sellingPrice: Preprice?.price || 0,
    });
    setSelectedOption("선택");
  }, []);

  useEffect(() => {
    setValue("standardPrice", standardPrice);
    setValue("sellingPrice", Preprice?.price || 0);
  }, [standardPrice, Preprice, setValue]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (optionValue: string) => {
    setSelectedOption(optionValue);
    setValue("optionValue", optionValue);
    setIsOpen(false);
  };

  const handleQtyChange = (change: number, currentQty: number) => {
    const newQty = Math.max(1, currentQty + change);
    setValue("qty", newQty);
    PrepriceRefetch();
  };

  return (
    <BottomSheet>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={`sub_bottom option_tab ${
            goodsOptionsInfos.length === 0
              ? "height-280"
              : isOpen
              ? "height-488"
              : "height-336"
          }`}
        >
          <img src={DownArrow} className="option_icon" />
          <div className="dropdown_option">
            {goodsOptionsInfos.length > 0 && (
              <>
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className={`dropdownButton ${isOpen ? "open" : ""} ${
                    purchaseInfo.optionValue === "" &&
                    watch("optionValue") === "" &&
                    "checked"
                  }`}
                >
                  {selectedOption}
                  <img src={ImgOptionClose} alt="" />
                </button>
                {isOpen && (
                  <ul className={`dropdownMenu ${isOpen ? "open" : ""}`}>
                    {goodsOptionsInfos.map((info) => (
                      <li
                        className="dropdownItem"
                        key={info.goodsOptionsInfoId}
                        onClick={() => handleOptionClick(info.optionValue)}
                      >
                        {info.optionValue}
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </div>
          <ul className="option_ul01">
            <li>수량 선택</li>
            <li>
              <div className="option_nb">
                <button
                  type="button"
                  className={`"minus_bt" + ${watch("qty") > 1 ? " on" : ""}`}
                  onClick={() =>
                    watch("qty") > 1 ? handleQtyChange(-1, watch("qty")) : null
                  }
                >
                  <img src={watch("qty") > 1 ? BtnMinusBk : BtnMinus} alt="-" />
                </button>
                <Controller
                  name="qty"
                  control={control}
                  defaultValue={1}
                  render={({ field }) => (
                    <input
                      type="number"
                      {...field}
                      value={field.value || 1}
                      onChange={(e) => {
                        const newQty = Number(e.target.value) || 1;
                        setValue("qty", newQty);
                        handleQtyChange(0, newQty);
                      }}
                    />
                  )}
                />

                <button
                  type="button"
                  className="plus_bt on"
                  onClick={() => handleQtyChange(1, watch("qty"))}
                >
                  <img src={BtnPlus} alt="+" />
                </button>
              </div>
            </li>
          </ul>
          <ul className="option_ul02">
            <li>
              <h3>총 금액</h3>
            </li>
            <li>
              <p>{numberWithCommas(Preprice?.price || 0)}원</p>
            </li>
          </ul>
          <button
            type="submit"
            className="sub_wrap02_btn on"
            style={{
              backgroundColor: colors.buttonColor,
            }}
          >
            주문하기
          </button>
        </div>
      </form>
    </BottomSheet>
  );
};

export default SelectOption;
