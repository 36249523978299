import imgShop from "@assets/images/img_shop.png";
import imgCalendar from "@assets/images/img_calendar.png";
import moment from "moment";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@/app/store";
import { MEMO_LIST, CUSTOM_AVAILABLE_MEMO } from "@/utils/constants";
import { getKoreanDayOfWeek, splitPhoneNumber } from "@/utils/utils";
import {
  DeliveryForm,
  DeliveryCard,
} from "@components/common/delivery-form/DeliveryForm";
import useAddressList from "@pages/myPage/hooks/useAddressList";
import { setPurchaseInfo } from "@/features/purchase/purchaseSlice";
import { subText } from "../styles.css";

interface HowToReceiveProps {
  isPost: boolean;
  handleOpenBottomSheet: (type: string) => void;
}

const HowToReceive: React.FC<HowToReceiveProps> = ({
  isPost,
  handleOpenBottomSheet,
}) => {
  const dispatch = useDispatch();
  const purchaseInfo = useSelector((state: RootState) => state.purchase);
  const { userAddress, isLoading } = useAddressList();

  const defaultAddress = !isLoading
    ? userAddress?.find((address) => address.isDefault)
    : null;

  useEffect(() => {
    if (defaultAddress && !purchaseInfo.receiver) {
      const addressMemo = defaultAddress?.memo || "";
      const memo = MEMO_LIST.includes(addressMemo)
        ? addressMemo
        : defaultAddress?.memo !== null
        ? CUSTOM_AVAILABLE_MEMO
        : "";
      const customMemo = memo === CUSTOM_AVAILABLE_MEMO ? addressMemo : "";

      dispatch(
        setPurchaseInfo({
          receiver: defaultAddress.receiver,
          addressName: defaultAddress.addressName,
          postCode: defaultAddress.postCode,
          address: defaultAddress.address,
          addressDetail: defaultAddress.addressDetail,
          phone: splitPhoneNumber(defaultAddress.phone),
          mobile: splitPhoneNumber(defaultAddress.mobile),
          memo,
          customMemo,
          isDefault: defaultAddress.isDefault,
        }),
      );
    }
  }, [defaultAddress, purchaseInfo.receiver, dispatch]);

  useEffect(() => {
    if (!isPost) {
      dispatch(setPurchaseInfo({ isDelivery: false }));
    }
  }, [isPost, dispatch]);

  const handleDeliveryChange = (isDelivery: boolean) => {
    dispatch(setPurchaseInfo({ isDelivery }));
  };

  return (
    <div>
      {isPost && (
        <div className="shadow88 mb16 shopping_box04">
          <h3 className="shadow88_tit fc-bk01">수령 방법 선택</h3>
          <div className="select_btn">
            <ul>
              <li>
                <input
                  type="radio"
                  name="select"
                  id="select01"
                  checked={purchaseInfo.isDelivery}
                  onChange={() => handleDeliveryChange(true)}
                />
                <label htmlFor="select01">
                  <p>택배 수령</p>
                </label>
              </li>
              <li>
                <input
                  type="radio"
                  name="select"
                  id="select02"
                  checked={!purchaseInfo.isDelivery}
                  onChange={() => handleDeliveryChange(false)}
                />
                <label htmlFor="select02">
                  <p>대리점 방문</p>
                </label>
              </li>
            </ul>
          </div>
        </div>
      )}
      {purchaseInfo.isDelivery ? (
        defaultAddress ? (
          <DeliveryCard deliveryInfo={purchaseInfo} />
        ) : (
          <DeliveryForm handleOpenBottomSheet={handleOpenBottomSheet} />
        )
      ) : (
        <div className="shadow88 sub02_03">
          <h3 className="shadow88_tit">
            수령 대리점
            <br />
            <span className={subText}>
              수령할 대리점 및 일자를 선택해 주세요.
            </span>
          </h3>
          <div className="bottom_box_wrap">
            {purchaseInfo?.shopName ? (
              <>
                <SelectedShopInfo
                  shopName={purchaseInfo.shopName}
                  shopDistance={purchaseInfo.shopDistance}
                  handleOpenBottomSheet={handleOpenBottomSheet}
                />
                {purchaseInfo?.visitDate ? (
                  <VisitDateInfo
                    visitDate={purchaseInfo.visitDate}
                    handleOpenBottomSheet={handleOpenBottomSheet}
                  />
                ) : (
                  <CalendarSelection
                    shopName={purchaseInfo.shopName}
                    handleOpenBottomSheet={handleOpenBottomSheet}
                  />
                )}
              </>
            ) : (
              <>
                <ShopSelection handleOpenBottomSheet={handleOpenBottomSheet} />
                <CalendarSelection
                  shopName={purchaseInfo.shopName}
                  handleOpenBottomSheet={handleOpenBottomSheet}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HowToReceive;

interface ShopSelectionProps {
  handleOpenBottomSheet: (type: string) => void;
}

export const ShopSelection: React.FC<ShopSelectionProps> = ({
  handleOpenBottomSheet,
}) => (
  <div
    className="shadow88 bottom_box checked mb08"
    onClick={() => handleOpenBottomSheet("shop")}
  >
    <div className="box_tit">
      <div className="box_img">
        <img src={imgShop} />
      </div>
      <h3>대리점 선택</h3>
    </div>
  </div>
);

interface SelectedShopInfoProps {
  shopName: string;
  shopDistance?: number;
  handleOpenBottomSheet: (type: string) => void;
}

export const SelectedShopInfo: React.FC<SelectedShopInfoProps> = ({
  shopName,
  shopDistance,
  handleOpenBottomSheet,
}) => (
  <div
    className="box_tit shadow88_02 mb08"
    onClick={() => handleOpenBottomSheet("shop")}
  >
    <div className="box_img">
      <img src={imgShop} />
    </div>
    <div className="box_cont">
      <h3>{shopName}</h3>
      {shopDistance && shopDistance > 0 ? <p>{shopDistance}km</p> : null}
      <span>다른 대리점 찾기</span>
    </div>
  </div>
);

interface CalendarSelectionProps {
  shopName?: string;
  handleOpenBottomSheet: (type: string) => void;
}

export const CalendarSelection: React.FC<CalendarSelectionProps> = ({
  shopName,
  handleOpenBottomSheet,
}) => (
  <div
    className={`shadow88 bottom_box checked ${shopName ? "" : "disabled"}`}
    onClick={() => shopName && handleOpenBottomSheet("calendar")}
  >
    <div className="box_tit">
      <div className="box_img">
        <img src={imgCalendar} />
      </div>
      <h3> 예약일 선택</h3>
    </div>
  </div>
);

interface VisitDateInfoProps {
  visitDate: string;
  handleOpenBottomSheet: (type: string) => void;
}

export const VisitDateInfo: React.FC<VisitDateInfoProps> = ({
  visitDate,
  handleOpenBottomSheet,
}) => (
  <div
    className="box_tit shadow88_02 mb08"
    onClick={() => handleOpenBottomSheet("calendar")}
  >
    <div className="box_img">
      <img src={imgCalendar} />
    </div>
    <div className="box_cont">
      <h3>예약일</h3>
      <p>
        {moment(visitDate).format("YYYY.MM.DD")}.{" "}
        {getKoreanDayOfWeek(visitDate)}
      </p>
      <span>다른 요일 선택하기</span>
    </div>
  </div>
);
