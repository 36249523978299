import useCustomQuery from "@/hooks/queries/useCustomQuery";
import * as APIS from "@/services/apis";
import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { routes } from "@/routes";

export interface IAcceptableAssets {
  name: string;
  krw: number;
  point: number;
  gold: number;
  silver: number;
  acceptableKrw: number;
  acceptablePoint: number;
  acceptableGold: number;
  acceptableSilver: number;
  withdrawKrw: number;
  storageFee: number;
}

const useAcceptableAssets = () => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getUserHoldingsInfo();
      dispatch(hideLoading());
      return response.data.data as IAcceptableAssets;
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, refetch } = useCustomQuery<IAcceptableAssets>(
    [routes.deposit],
    fetchDetails,
  );

  return {
    AcceptableAssets: data,
    isLoading,
    error,
    AcceptableAssetsRefetch: refetch,
  };
};

export default useAcceptableAssets;
