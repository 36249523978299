import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import useBanners, { type BannerItems } from "./hooks/useBanners";
import { useNavigate } from "react-router-dom";
import { postMessage } from "@/utils/utils";
import { routes } from "@/routes";

const Banners = () => {
  const navigate = useNavigate();
  const { data: banners } = useBanners();

  const handleClick = (banner: BannerItems) => {
    const { content, category } = banner;
    const isUrl = content.includes("https");

    if (isUrl) {
      return postMessage({
        command: "WINDOW_OPEN",
        param: {
          url: content,
        },
      });
    } else if (category === "EVENT" || category === "NOTICE") {
      const test = category.toLocaleLowerCase() as "event" | "notice";
      const matchedRoute = routes[test];
      return navigate(`${matchedRoute}/${content}`);
    } else {
      return;
    }
  };

  return (
    <Swiper
      modules={[Autoplay]}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      centeredSlides
      spaceBetween={9}
      slidesPerView={"auto"}
    >
      {banners?.map((banner, i) => (
        <SwiperSlide key={i.toString()}>
          <div className="sell_banner">
            <img
              src={banner.path}
              alt="배너"
              onClick={() => handleClick(banner)}
            />
            <p className="banner_nb">
              <span>{i + 1}</span>/{banners.length}
            </p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Banners;
