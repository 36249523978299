import React, { useState } from "react";
import moment from "moment";
import { formatTime } from "../../../utils/utils";
import iconArrowLeft from "../../../assets/icons/ico_arrow_left_c.png";
import iconArrowRight from "../../../assets/icons/ico_arrow_right_c.png";

/** Calendar.tsx를 기반으로 범위 지정 표시가 가능한 컴포넌트
 * 기존 Calendar.tsx에 추가하기 까다로워 보여서 분리.
 */

interface DateRangePickerProps {
  isStart: boolean; // 시작 범위인지 종료 범위인지 판별
  datePicked: string | null; // 현재 선택된 날짜
  oppositeDate: string | null;
  handleDatePicked: (date: string, isStart: boolean) => void; // 날짜 선택 시 호출되는 함수
}

const DAYS_OF_WEEK = ["일", "월", "화", "수", "목", "금", "토"];

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  isStart,
  datePicked,
  oppositeDate,
  handleDatePicked,
}) => {
  const [month, setMonth] = useState(new Date());

  const generate = () => {
    const today = moment(month);
    const startWeek = today.clone().startOf("month").week();
    const endWeek =
      today.clone().endOf("month").week() === 1
        ? 53
        : today.clone().endOf("month").week();
    let calendar = [];

    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        <tr className="date" key={week}>
          {Array(7)
            .fill(0)
            .map((_, i) => {
              let current = today
                .clone()
                .startOf("year")
                .week(week)
                .startOf("week")
                .add(i, "day");

              const dateString = formatTime(current.toDate());
              const isToday = current.isSame(new Date(), "day");
              const isSelected = datePicked === dateString;

              // 비활성화 로직 수정
              const isValidOppositeDate =
                oppositeDate &&
                moment(oppositeDate, "YYYY-MM-DD", true).isValid();
              const isDisabled =
                (isStart &&
                  isValidOppositeDate &&
                  moment(oppositeDate).isBefore(current)) ||
                (!isStart &&
                  isValidOppositeDate &&
                  moment(oppositeDate).isAfter(current));

              return (
                <td
                  key={i}
                  className={`${isSelected ? "selected" : ""} ${
                    isDisabled ? "disabled" : ""
                  }`}
                  onClick={() => {
                    !isDisabled && handleDatePicked(dateString, isStart);
                  }}
                >
                  <p>{current.format("D")}</p>
                  {/* {isToday && <span className="cal_today">오늘</span>} */}
                </td>
              );
            })}
        </tr>,
      );
    }
    return calendar;
  };

  return (
    <div className="sub_calendar">
      <div className="sub_calendar_top">
        <button
          onClick={() => setMonth(moment(month).subtract(1, "month").toDate())}
        >
          <img src={iconArrowLeft} alt="" />
        </button>
        <h3 className="spoqa">{moment(month).format("YYYY년 MM월")}</h3>
        <button
          onClick={() => setMonth(moment(month).add(1, "month").toDate())}
        >
          <img src={iconArrowRight} alt="" />
        </button>
      </div>
      <div className="sub_calendar_tb">
        <table>
          <thead>
            <tr>
              {DAYS_OF_WEEK.map((day, index) => (
                <td
                  className={`${
                    index === 0 ? "sun" : index === 6 ? "sat" : ""
                  }`}
                  key={index}
                >
                  <p>{day}</p>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>{generate()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default DateRangePicker;
