import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { InitialState } from "../../model/modal";

/**
 * NOTE: callback들은 content를 통해 다 넘겨주는 식으로 modal에서 콜백설정을 따로 해주지 않는다.
 *
 * TODO
 * [ ] 기존 content를 다 넘겨주는 방식말고, 에러 모달, 기본 모달 디자인 지정하여 message(내용), onPress(확인 버튼 누를 시, 동작되는 함수)만 설정해서 사용하는 식으로 수정 하기
 */

const initialState: InitialState = {
  isOpen: false,
  content: null,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<React.ReactElement>) => {
      state.isOpen = true;
      state.content = action.payload;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.content = null;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export const selectModal = (state: RootState) => state.modal;
export default modalSlice.reducer;
