import useCustomQuery from "@/hooks/queries/useCustomQuery";
import * as APIS from "@/services/apis";
import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import { useDispatch } from "react-redux";

interface IConfig {
  assetType: string;
  configKey: string;
  value: string;
}
const useConfig = (config: string) => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getEnvConfig(config);
      dispatch(hideLoading());
      return response.data.data as IConfig;
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, refetch } = useCustomQuery<IConfig>(
    [config],
    fetchDetails,
  );

  return { ConfigData: data, isLoading, error, ConfigDataRefetch: refetch };
};
export default useConfig;
