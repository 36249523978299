import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export type ToastIcon = "success" | "error" | "caution" | "info" | null;
interface ToastState {
  open: boolean;
  second: number;
  message: string;
  icon: ToastIcon;
}

const initialState: ToastState = {
  open: false,
  second: 1,
  message: "",
  icon: null,
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    hideToast: (state) => {
      return initialState;
    },
    showToast: (
      state,
      action: PayloadAction<{
        message: string;
        second?: number;
        icon?: ToastIcon;
      }>,
    ) => {
      const { message, second = 3, icon = null } = action.payload;
      state.open = true;
      state.message = message;
      state.second = second;
      state.icon = icon;
    },
  },
});

export const { hideToast, showToast } = toastSlice.actions;
export const selectToast = (state: RootState) => state.toast;
export default toastSlice.reducer;
