import { hideLoading, showLoading } from "@/features/loading/loadingSlice";
import useCustomQuery from "@/hooks/queries/useCustomQuery";
import { getPopups } from "@/services/apis";
import { useDispatch } from "react-redux";

export interface PopupItems {
  noticeId: number;
  subject: string;
  content: string;
  category: string;
  type: string;
  path: string;
  popupPeriod: 1 | 3 | 7 | 0;
}

const useGetPopups = () => {
  const dispatch = useDispatch();

  const fetchPopups = async () => {
    dispatch(showLoading());
    try {
      const response = await getPopups();
      dispatch(hideLoading());
      return response.data.data as PopupItems[];
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  return useCustomQuery<PopupItems[]>(["home", "popups"], fetchPopups);
};

export default useGetPopups;
