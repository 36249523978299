import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import useCustomQuery from "@/hooks/queries/useCustomQuery";
import { routes } from "@/routes";
import { useDispatch } from "react-redux";
import * as APIS from "@/services/apis";

export type EventItemType = {
  id: number;
  category: string;
  content: string;
  count: number;
  subject: string;
  startDate: string;
  endDate: string;
  status: string;
  serviceImages: [
    { id: number; imageType: string; path: string; tradeType: string },
  ];
};

export type EventListInfo = {
  closedEventList: Array<EventItemType>;
  openEventList: Array<EventItemType>;
};

const useEventList = () => {
  const dispatch = useDispatch();

  const fetchEventList = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getEvent();
      dispatch(hideLoading());
      return response.data.data as EventListInfo;
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading } = useCustomQuery(
    [routes.event, "list"],
    fetchEventList,
  );
  return { data, error, isLoading };
};

export default useEventList;
