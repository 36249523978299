import React, { useEffect, useState } from "react";
import iconLeft from "@/assets/icons/icon_left.png";
import imgSecurity2 from "@/assets/images/img_security2.png";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "@/routes";
import { hideLoading, showLoading } from "@/features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { showToast } from "@/features/toast/toastSlice";
import * as APIS from "@/services/apis";
import useInterval from "@/hooks/useInterval";
import { closeModal, openModal } from "@/features/modal/modalSlice";
import iconCau from "@/assets/icons/ico_56px_cau.png";
import { useSelector } from "react-redux";
import { selectAuth } from "@/features/auth/authSlice";
import { signOut } from "@/utils/sign";
import imgError from "@/assets/images/img_caution_error.png";
import * as styles from "./styles.css";
import colors from "@assets/styles/colors.css";
interface LeaveConfirmPopupProps {
  onCancel: () => void;
  onConfirm: () => void;
}

const LeaveSms = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { leaveReason } = location.state;
  const [timeLeft, setTimeLeft] = useState(-1);
  const [verifyCode, setVerifyCode] = useState("");
  const [stopInterval, setStopInterval] = useState(false);
  const [confirmPopupVisible, setConfirmPopupVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const auth = useSelector(selectAuth);
  const { isSigned } = auth;

  const handleClickPostSms = () => {
    if (timeLeft <= 0) {
      dispatch(showLoading());
      resetTimer();
      APIS.postLeaveVerifyCode()
        .then(({ data: { success } }) => {
          if (success) {
            dispatch(
              showToast({
                message: "인증번호가 발송되었습니다.",
                icon: "success",
              }),
            );
            setErrorMessage("");
            startTimer();
          }
        })
        .finally(() => dispatch(hideLoading()));
    }
  };

  useInterval(
    () => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    },
    stopInterval ? null : 1000,
  );

  useEffect(() => {
    if (timeLeft === 0) {
      setErrorMessage(
        "인증번호가 만료되었습니다.\n인증번호를 다시 전송해주세요.",
      );
      resetTimer();
    }
  }, [timeLeft]);

  const resetTimer = () => {
    setTimeLeft(-1);
    setVerifyCode("");
  };

  const startTimer = () => {
    setTimeLeft(180);
    setVerifyCode("");
  };

  const handleClickNext = () => {
    if (verifyCode.length === 6) {
      dispatch(showLoading());
      APIS.patchLeavePreviousVerify(verifyCode)
        .then(({ data: { success, message } }) => {
          if (success) {
            setStopInterval(true);
            setConfirmPopupVisible(true);
          } else {
            setErrorMessage(message);
          }
        })
        .finally(() => dispatch(hideLoading()));
    }
  };

  const handleClickLeave = () => {
    dispatch(showLoading());
    APIS.patchLeaveUser(leaveReason)
      .then(({ data: { success, message } }) => {
        if (success) {
          if (isSigned) {
            signOut();
          }
          dispatch(
            showToast({
              message: "회원탈퇴가 완료되었습니다.",
              icon: "success",
            }),
          );
          navigate(routes.main, { replace: true });
        } else {
          dispatch(showToast({ message, icon: "error" }));
          navigate(-1);
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  return (
    <div>
      <div className="sub_top">
        <div>
          <img
            src={iconLeft}
            alt=""
            className="icon_back"
            onClick={() => navigate(-1)}
          />
          <p>탈퇴하기</p>
        </div>
      </div>
      <div className="menu02_wrap">
        <h3 className="menu_wrap_tit">
          <img src={imgSecurity2} />
          <br />
          회원탈퇴를 진행하기 위해
          <br />
          <span className="fc-bl03">본인 인증</span>이 필요해요.
        </h3>
        <div className="shadow88 bg-gr01">
          <div className="menu_cont_box">
            <p className="num_bar">1</p>
            <div className="menu_cont_box02">
              <h3>내 휴대폰으로 인증번호 전송</h3>
              <button
                className={timeLeft > 0 ? "re-send" : ""}
                onClick={handleClickPostSms}
              >
                {timeLeft > 0 ? "인증번호 재전송하기" : "인증번호 전송하기"}
              </button>
            </div>
          </div>
        </div>
        <div className="shadow88 bg-gr01 gap">
          <div className="menu_cont_box">
            <p>2</p>
            <div
              className={`menu_cont_box02 ${
                errorMessage !== "" ? "error" : ""
              }`}
            >
              <h3>휴대폰으로 전송된 인증번호 입력</h3>
              <div
                className={styles.inputWrap}
                style={{
                  border:
                    errorMessage !== ""
                      ? "1px solid #B3261E"
                      : "1px solid #dbdbdb",
                }}
              >
                <input
                  type="text"
                  inputMode="decimal"
                  disabled={timeLeft <= 0}
                  placeholder="숫자 입력"
                  value={verifyCode}
                  onChange={({ target: { value, maxLength } }) => {
                    var regexp = /^[0-9]*$/;
                    if (!regexp.test(value)) {
                      return;
                    } else {
                      setVerifyCode(value.slice(0, maxLength));
                    }
                  }}
                  maxLength={6}
                />
                {errorMessage !== "" && timeLeft <= 0 ? (
                  <div className={styles.imgError}>
                    <img src={imgError} style={{ width: 32, height: 32 }} />
                  </div>
                ) : (
                  timeLeft > -1 && (
                    <div className={styles.timeWrap}>
                      {String(parseInt(String(timeLeft / 60))).padStart(2, "0")}{" "}
                      : {String(timeLeft % 60).padStart(2, "0")}
                    </div>
                  )
                )}
              </div>
              {errorMessage !== "" && (
                <span className="fc-rd02">{errorMessage}</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="sub_btn_wrap menu_btn02">
        <button
          className="bg_bk_btn"
          onClick={() => navigate(-1)}
          style={{
            backgroundColor: colors.buttonColor,
          }}
        >
          취소
        </button>
        <button
          className={`bg_gr_btn ${verifyCode.length === 6 ? "on" : ""}`}
          onClick={handleClickNext}
        >
          다음
        </button>
      </div>
      {confirmPopupVisible && (
        <LeaveConfirmPopup
          onCancel={() => {
            dispatch(closeModal());
            // navigate(routes.editProfile);
            navigate(-1);
          }}
          onConfirm={() => {
            setConfirmPopupVisible(false);
            dispatch(closeModal());
            handleClickLeave();
          }}
        />
      )}
    </div>
  );
};

const LeaveConfirmPopup: React.FC<LeaveConfirmPopupProps> = ({
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const dispatch = useDispatch();
  const [resignDays, setResignDays] = useState(30);

  useEffect(() => {
    dispatch(showLoading());
    APIS.getEnvConfig("USER_RESIGNUP_DAYS")
      .then(({ data: { success, data } }) => {
        success && setResignDays(data?.value);
      })
      .finally(() => dispatch(hideLoading()));
  }, []);

  dispatch(
    openModal(
      <div className="bg_gr_wrap">
        <div className="modal_wrap03">
          <img src={iconCau} alt="" />
          <h3>정말 탈퇴하시겠어요?</h3>
          <p className="fc-rd03">{`탈퇴 후 ${resignDays}일간 가입하실 수 없어요.`}</p>
          <div className="modal_btn">
            <button className="bg_rd_btn" onClick={onConfirm}>
              탈퇴할게요
            </button>
            <button className="bg_gr_btn" onClick={onCancel}>
              회원 유지할게요
            </button>
          </div>
        </div>
      </div>,
    ),
  );
  return <></>;
};

export default LeaveSms;
