import { ROLE } from "../../routes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface AuthState {
  isSigned: boolean;
  role: (typeof ROLE)[keyof typeof ROLE];
  name: string;
  sns: string;
  socialId: number;
  userId: number;
  version: string;
  roles: string[];
}

const initialState = {
  isSigned: false,
  role: ROLE.NONE,
  name: "",
  sns: "",
  socialId: 0,
  userId: 0,
  version: "",
  roles: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initAuth: () => initialState,
    updateAuth: (state, action: PayloadAction<Partial<AuthState>>) => {
      Object.assign(state, action.payload);
    },
  },
});
export const { initAuth, updateAuth } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
