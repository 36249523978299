import React, { useEffect } from "react";
import icon88X from "@assets/icons/icon_88_x.png";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "@/routes";
import { updatePurchaseInfo } from "../hooks/createPaymentInfo";
import { useDispatch } from "react-redux";
import colors from "@assets/styles/colors.css";

const TossFail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { goodsId, txId } = params;
  // console.log(goodsId, txId);
  const handleClickPurchase = () => {
    navigate(`${routes.buyGoodsPurchase}/${goodsId}`);
  };

  useEffect(() => {
    const init = async () => {
      if (txId && !sessionStorage.getItem(`processedTxId-${txId}`)) {
        // txId가 있으면 txId로 조회하고, 전역상태 업데이트하는 로직
        if (txId) {
          await updatePurchaseInfo(txId, dispatch);
        }
        // txId를 SessionStorage에 저장하여 중복 실행 방지
        sessionStorage.setItem(`processedTxId-${txId}`, "true");
      }
    };

    init();
  }, [txId, dispatch]);

  return (
    <div>
      <div className="sub02_wrap sub02_wrap03">
        <div className="shadow88">
          <img src={icon88X} className="icon_88" />
          <h3 className="sub02_wrap03_tit">
            <span className="fc-rd02">결제 실패</span> 되었습니다.
          </h3>
        </div>
        <div className="shadow88 gap">
          <div className="sub02_02 fail">
            <h3 className="shadow88_tit">결제 정보</h3>
            <ul className="sub02_02_ul02">
              <li>
                <p>이유</p>
              </li>
              <li>
                <h3 className="fc-rd02">결제 취소</h3>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub02_wrap03_btn">
          <button
            className="bk-btn01 tooltip01"
            onClick={handleClickPurchase}
            style={{
              backgroundColor: colors.buttonColor,
            }}
          >
            돌아가기
          </button>
        </div>
      </div>
    </div>
  );
};

export default TossFail;
