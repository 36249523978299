import { useSearch } from "../hooks/useSearch";
import { SEARCH } from "../Search.const";
import iconX from "@assets/icons/btn_x_10.png";

interface SearchReadyProps {
  recentKeywords: any;
  hanldeSearchInput: ReturnType<typeof useSearch>["hanldeSearchInput"];
  handleSearch: (searchInput: string) => () => void;
  deleteHistory: (type: "ALL" | "WORD", clickKeyword?: string) => void;
}
const SearchReady = (props: SearchReadyProps) => {
  const { recentKeywords, hanldeSearchInput, handleSearch, deleteHistory } =
    props;

  const handleClick = (clickedKeyword: string) => {
    hanldeSearchInput(clickedKeyword, "WORD");
    handleSearch(clickedKeyword)();
  };

  return (
    <>
      <div className="search_box01">
        <p className="search_box_t">최근 검색어</p>
        {recentKeywords?.length > 0 ? (
          <>
            <button className="clear" onClick={() => deleteHistory("ALL")}>
              모두 지우기
            </button>
            <ul>
              {recentKeywords?.map((item: any) => {
                return (
                  <li key={item} data-some={"here"}>
                    <p onClick={() => handleClick(item)}>
                      <span>{item}</span>
                      <img
                        src={iconX}
                        style={{
                          display: "block",
                          width: "12px",
                          height: "12px",
                          marginLeft: "4px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteHistory("WORD", item);
                        }}
                      />
                    </p>
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <p className="search_none">최근 검색어 내역이 없습니다.</p>
        )}
      </div>
      <div className="search_box02">
        <p className="search_box_t">추천 중량</p>
        <ul>
          {SEARCH.RECOMMEND_KEYWORDS.map((keyword) => {
            return (
              <li key={keyword} onClick={() => handleClick(keyword)}>
                <p>{keyword}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default SearchReady;
