import React, { ReactElement, ReactNode, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { routes, mapRouteToRole, ROLE } from "../routes";
import { requestNativeLogin } from "../utils/utils";
import { closeModal, openModal } from "../features/modal/modalSlice";
import { selectAuth } from "../features/auth/authSlice";
import { showToast } from "../features/toast/toastSlice";
import colors from "@assets/styles/colors.css";

interface SpecificComponentProps {
  navigate: (path: string, param: any) => void;
}

export default function withAuthentication(
  SpecificComponent: React.ComponentType<SpecificComponentProps>,
  path = "",
): ReactElement {
  const AuthenticateCheck: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const userLevel = useSelector(selectAuth).role.LEVEL;

    const { LEVEL: pageLevel } = mapRouteToRole[path] || {};

    const navigatePermission = (path: any, param: any) => {
      const { LEVEL: accessLevel } = mapRouteToRole[path] || {};
      if (userLevel < accessLevel) {
        const needLevel = userLevel + 1;
        switch (needLevel) {
          case ROLE.ROLE_TEMP.LEVEL:
            dispatch(
              openModal(
                <div className="bg_gr_wrap">
                  <div className="modal_wrap">
                    <h3>로그인 후 이용 가능합니다.</h3>
                    <div className="modal_btn">
                      <button
                        className="bg_gr_btn"
                        onClick={() => {
                          dispatch(closeModal());
                        }}
                      >
                        닫기
                      </button>
                      <button
                        className="bg_bk_btn"
                        style={{
                          backgroundColor: colors.buttonColor,
                        }}
                        onClick={() => requestNativeLogin(navigate)}
                      >
                        로그인
                      </button>
                    </div>
                  </div>
                </div>,
              ),
            );
            break;

          case ROLE.ROLE_SELF_VERIFICATION.LEVEL:
            navigate(routes.selfVerification, { state: { purpose: "SIGNUP" } });
            break;

          default:
            dispatch(
              showToast({
                message: "접근 할 수 없는 화면입니다.",
                icon: "error",
              }),
            );
        }
      } else {
        navigate(path, param);
      }
    };

    useEffect(() => {
      if (userLevel < pageLevel) {
        const needLevel = userLevel + 1;
        let param = location.state || {};

        switch (needLevel) {
          case ROLE.ROLE_SELF_VERIFICATION.LEVEL:
            navigate(routes.selfVerification, {
              state: { purpose: "SIGNUP" },
            });
            break;
          case ROLE.ROLE_BANKACCOUNT_VERIFICATION.LEVEL:
            navigate(routes.authBankAccount, {
              state: { nextPage: { path, param } },
            });
            break;
          default:
            dispatch(
              showToast({
                message: "접근 할 수 없는 화면입니다.",
                icon: "error",
              }),
            );
            navigate(-1);
        }
      }
    }, [userLevel]);

    if (userLevel < pageLevel) {
      return null;
    } else {
      return <SpecificComponent navigate={navigatePermission} />;
    }
  };
  // return AuthenticateCheck as unknown as ReactNode;
  return <AuthenticateCheck />;
}
