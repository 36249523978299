import { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationOptions,
  MutationKey,
} from "@tanstack/react-query";

export interface ResponseType<T> extends AxiosResponse {
  data: T;
}
interface ErrorType<T> extends AxiosResponse {
  response: {
    data: T;
  };
}

type NetworkError = ErrorType<{ message: string }>; // 존재하지 않는 경로 등
type ServerError<T = unknown> = ErrorType<T>; // 서버에서 에러 반환 등

export type APIErrorType<T = { message: string }> =
  | NetworkError
  | ServerError<T>;

type Params = { [key: string]: any };
type MutationFunction<Response, Params> = (params: Params) => Promise<Response>;

type MutationOptions<
  Response,
  Params,
  Error = APIErrorType,
> = UseMutationOptions<Response, Error, Params>;

/**
 * @param mutationKey 고유한 뮤테이션 키: [routes, API, id]
 * @param mutationFn API 호출 함수
 * @param options useMutation 옵션
 * @returns useMutation 결과
 */
function useCustomMutation<Response, Params = any>(
  mutationKey: MutationKey,
  mutationFn: MutationFunction<Response, Params>,
  options?: MutationOptions<Response, Params>,
) {
  const commonOptions = {
    retry: 0,
  };

  return useMutation<Response, APIErrorType, Params>({
    mutationKey,
    mutationFn,
    ...commonOptions,
    ...options,
  });
}

export default useCustomMutation;
