import React from "react";
import { ITradeIngResponse } from "../hooks/useTradeIngList";
import { ITradeCompleteResponse } from "../hooks/useTradeCompleteList";
import IconFind from "@assets/icons/ico_find_24.png";
import ImgArrow from "@assets/icons/arrow.png";
import IconShopping from "@assets/icons/ico_shoppingbag_24.png";
import IconWithdraw from "@assets/icons/ico_withdrawal_24_02.png";
import IconDeposit from "@assets/icons/ico_deposit_24_02.png";
import IconAssetBuy from "@assets/icons/ico_buying_16.png";
import IconAssetSell from "@assets/icons/ico_sell_16.png";
import IconCoupon from "@assets/icons/ico_coupon_24.png";
import IconEvent from "@assets/icons/ico_event_24_02.png";
import ico_safe_24 from "@/assets/icons/ico_safe_24_big.png";
import constants from "@/utils/constants";
import {
  formatDateString,
  formatDateTime,
  numberWithCommas,
  truncateToThreeDecimalPlaces,
} from "@/utils/utils";
import { useNavigate } from "react-router";
import { routes } from "@/routes";
import { useDispatch } from "react-redux";

export interface TradeCardProps {
  tradeInfo: ITradeIngResponse | ITradeCompleteResponse;
  statusType: string;
  onCancel?: (tradeInfo: any) => void;
}

function isTradeCompleteResponse(
  trade: ITradeIngResponse | ITradeCompleteResponse,
): trade is ITradeCompleteResponse {
  return (trade as ITradeCompleteResponse).tradeId !== undefined;
}

export interface TradeInfo {
  assetType: string;
  pureTradeKrw?: number;
  tradePoint?: number;
  tradeGram?: number;
}

// assetType에 맞게 값 + 단위 반환
export const AmountByAsset = (tradeInfo: TradeInfo): string => {
  switch (tradeInfo.assetType) {
    case "KRW":
      return numberWithCommas(tradeInfo.pureTradeKrw || 0) + "원";
    case "POINT":
      return numberWithCommas(tradeInfo.tradePoint || 0) + "pt";
    default:
      return numberWithCommas(tradeInfo.tradeGram || 0) + "g";
  }
};

export const AppraiseTradeCard: React.FC<TradeCardProps> = ({
  tradeInfo,
  statusType,
}) => {
  const navigate = useNavigate();
  const isDelivery = tradeInfo.addTradeType === "SIMPLE_DELIVERY";
  const requestAt = formatDateTime(tradeInfo.requestAt);
  const statusDate = isTradeCompleteResponse(tradeInfo)
    ? tradeInfo.completedAt
    : tradeInfo.statusDate;

  //진행중 이동 > requestId
  //완료 이동 > rawId
  return (
    <div
      className="scont_box"
      onClick={() =>
        navigate(routes.appraiseDetail, {
          state: {
            id:
              statusType === "COMPLETE" ? tradeInfo.rawId : tradeInfo.requestId,
            statusType,
          },
        })
      }
    >
      <span className="date">{requestAt}</span>
      <div className="scont_cbox">
        <div className="scont_left">
          <img src={IconFind} />
        </div>
        <div className="scont_right">
          <div className="scont_tit">
            <h3>{statusType === "COMPLETE" ? "교환완료" : tradeInfo.status}</h3>
            <span>|</span>
            <p>감정평가</p>
            <img src={ImgArrow} className="arrow" />
          </div>
          <div className="scont_shop">
            <img src={constants.s3BaseUrl + "/" + tradeInfo.imagePath} />
            <p>
              <span>{tradeInfo.associateName}</span>
              {tradeInfo.shopName}
            </p>
          </div>
          <div className="scont_cont">
            <ul className="scont_ul01">
              <li>
                <p>{isDelivery ? "접수일" : "방문일"}</p>
              </li>
              <li>
                <h3>{formatDateString(statusDate)}</h3>
              </li>
            </ul>
            <ul className="scont_ul02">
              <li>접수번호</li>
              <li>{tradeInfo.tradeNumber}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShoppingCard: React.FC<TradeCardProps> = ({
  tradeInfo,
  statusType,
}) => {
  const statusLabel =
    statusType === "COMPLETE" ? "인출 확정" : tradeInfo.status;
  const statusKrw =
    statusType === "COMPLETE" ? tradeInfo.pureTradeKrw : tradeInfo.requestKrw;
  const statusGram =
    statusType === "COMPLETE" ? tradeInfo.tradeGram : tradeInfo.requestGram;
  const navigate = useNavigate();
  return (
    <div className="shopping">
      <div
        className="scont_box"
        onClick={() =>
          statusType === "COMPLETE"
            ? navigate(`${routes.shoppingDetail}/${tradeInfo.rawId}`)
            : navigate(`${routes.shoppingDetail}/${tradeInfo.requestId}`)
        }
      >
        <span className="date">{formatDateTime(tradeInfo.requestAt)}</span>
        <div className="scont_cbox">
          <div className="scont_left">
            <img src={IconShopping} />
          </div>
          <div className="scont_right">
            <div className="scont_tit">
              <h3>{statusLabel}</h3>
              <span>|</span>
              <p>쇼핑</p>
              <img src={ImgArrow} className="arrow" />
            </div>
            <div className="scont_shop">
              <img src={constants.s3BaseUrl + "/" + tradeInfo.imagePath} />
              <p>
                <span>{tradeInfo.brandName}</span>
                {tradeInfo.productName}
              </p>
            </div>
            <div className="scont_cont">
              <ul className="scont_ul01">
                <li>
                  <p>결제가</p>
                </li>
                <li>
                  <h3>{numberWithCommas(statusKrw)}원</h3>
                </li>
              </ul>
              <ul className="scont_ul02">
                <li>선택</li>
                <li>
                  중량:{statusGram}g
                  {tradeInfo.option ? `/옵션:${tradeInfo.option}` : ""}
                  /수량:
                  {tradeInfo.totalCount}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getStatusLabel = (statusType: string, tradeType: string) => {
  if (statusType === "COMPLETE") {
    switch (tradeType) {
      case "BUY":
        return "구매완료";
      case "SELL":
        return "판매완료";
      default:
        return "완료";
    }
  } else {
    switch (tradeType) {
      case "BUY":
        return "구매 대기";
      case "SELL":
        return "판매 대기";
      default:
        return "대기";
    }
  }
};

export const AssetTradeCard: React.FC<TradeCardProps> = ({
  tradeInfo,
  statusType,
  onCancel,
}) => {
  const isComplete = statusType === "COMPLETE";
  const tradeType = tradeInfo.tradeType;

  const statusGram = isComplete ? tradeInfo.tradeGram : tradeInfo.requestGram;
  const statusKrw = isComplete
    ? tradeInfo.pureTradeKrw
    : tradeInfo.requestPrice;
  const statusLabel = getStatusLabel(statusType, tradeType);
  const tradeTypeLabel =
    tradeInfo.tradeType === "BUY" ? "자산 살래요" : "자산 팔래요";
  const tradeTypeIcon =
    tradeInfo.tradeType === "BUY" ? IconAssetBuy : IconAssetSell;

  const navigate = useNavigate();

  const handleOpenDeleteBottomSheet = () => {
    if (onCancel) onCancel(tradeInfo);
  };

  return (
    <div className="shopping ">
      <div
        className="scont_box"
        onClick={() =>
          isComplete &&
          navigate(routes.tradeCompleteDetail, { state: { tradeInfo } })
        }
      >
        <span className="date">{formatDateTime(tradeInfo.requestAt)}</span>
        <div className="scont_cbox">
          <div className="scont_left">
            <img src={tradeTypeIcon} />
          </div>
          <div className="scont_right">
            <div className="scont_tit">
              <h3>{statusLabel}</h3>
              <span>|</span>
              <p>{tradeTypeLabel}</p>
              {statusType === "ING" && (
                <button onClick={handleOpenDeleteBottomSheet}>취소</button>
              )}
            </div>
            <div className="scont_shop">
              <p>
                {tradeInfo.assetTypeKor} {statusGram}g
              </p>
            </div>
            <div className="scont_cont">
              <ul className="scont_ul01">
                <li>
                  <p>{isComplete ? "거래 금액" : "주문 가격"}</p>
                </li>
                <li>
                  <h3>{numberWithCommas(statusKrw)}원</h3>
                </li>
              </ul>
              {isComplete ? (
                <ul className="scont_ul02">
                  <li>수수료</li>
                  <li>{numberWithCommas(tradeInfo.userFee)}원</li>
                </ul>
              ) : (
                <>
                  <ul className="scont_ul02">
                    <li>체결 중량</li>
                    <li>{numberWithCommas(tradeInfo.contractGram)}g</li>
                  </ul>
                  <ul className="scont_ul02">
                    <li>미체결 중량</li>
                    <li>
                      {numberWithCommas(
                        truncateToThreeDecimalPlaces(
                          tradeInfo.requestGram - tradeInfo.contractGram,
                        ),
                      )}
                      g
                    </li>
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// 자산 보냄, 자산 받음
export const AssetSendReceiveCard: React.FC<TradeCardProps> = ({
  tradeInfo,
  statusType,
}) => {
  const tradeTypeIcon =
    tradeInfo.tradeType === "SEND_ASSET" ? IconAssetBuy : IconAssetSell;
  const navigate = useNavigate();

  return (
    <div className="shopping ">
      <div
        className="scont_box"
        onClick={() =>
          navigate(routes.tradeCompleteDetail, { state: { tradeInfo } })
        }
      >
        <span className="date">{formatDateTime(tradeInfo.requestAt)}</span>
        <div className="scont_cbox">
          <div className="scont_left">
            <img src={tradeTypeIcon} />
          </div>
          <div className="scont_right">
            <div className="scont_tit">
              <h3>거래 완료</h3>
              <span>|</span>
              <p>{tradeInfo.tradeTypeKor}(상속)</p>
              <img src={ImgArrow} className="arrow" />
            </div>
            <div className="scont_shop">
              <p>{tradeInfo.assetTypeKor}</p>
            </div>
            <div className="scont_cont">
              <ul className="scont_ul01">
                <li>
                  <p>{tradeInfo.assetTypeKor}</p>
                </li>
                <li>
                  <h3>{AmountByAsset(tradeInfo)}</h3>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WithdrawCard: React.FC<TradeCardProps> = ({
  tradeInfo,
  statusType,
}) => {
  const statusLabel = statusType === "COMPLETE" ? "출금완료" : "대기";
  const statusKrw =
    statusType === "COMPLETE" ? tradeInfo.pureTradeKrw : tradeInfo.requestKrw;
  const isComplete = statusType === "COMPLETE";
  const navigate = useNavigate();
  return (
    <div className="shopping">
      <div
        className="scont_box"
        onClick={() =>
          isComplete &&
          navigate(routes.tradeCompleteDetail, { state: { tradeInfo } })
        }
      >
        <span className="date">{formatDateTime(tradeInfo.requestAt)}</span>
        <div className="scont_cbox">
          <div className="scont_left">
            <img src={IconWithdraw} />
          </div>
          <div className="scont_right">
            <div className="scont_tit">
              <h3>{statusLabel}</h3>
              <span>|</span>
              <p>출금</p>
            </div>
            <div className="scont_shop">
              <p>KRW</p>
            </div>
            <div className="scont_cont">
              <ul className="scont_ul01">
                <li>
                  <p>출금</p>
                </li>
                <li>
                  <h3>{numberWithCommas(statusKrw)}원</h3>
                </li>
              </ul>
              <ul className="scont_ul02">
                <li>수수료</li>
                <li>{numberWithCommas(tradeInfo.userFee)}원</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// 입금은 완료만
export const DepositCard: React.FC<TradeCardProps> = ({
  tradeInfo,
  statusType,
}) => {
  const isComplete = statusType === "COMPLETE";
  const navigate = useNavigate();
  return (
    <div className="shopping">
      <div
        className="scont_box"
        onClick={() =>
          isComplete &&
          navigate(routes.tradeCompleteDetail, { state: { tradeInfo } })
        }
      >
        <span className="date">{formatDateTime(tradeInfo.requestAt)}</span>
        <div className="scont_cbox">
          <div className="scont_left">
            <img src={IconDeposit} />
          </div>
          <div className="scont_right">
            <div className="scont_tit">
              <h3>입금완료</h3>
              <span>|</span>
              <p>{tradeInfo.tradeTypeKor}</p>
            </div>
            <div className="scont_shop">
              <p>KRW</p>
            </div>
            <div className="scont_cont">
              <ul className="scont_ul01">
                <li>
                  <p>입금액</p>
                </li>
                <li>
                  <h3>{numberWithCommas(tradeInfo.pureTradeKrw)}원</h3>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// 쿠폰도 완료만
export const CouponCard: React.FC<TradeCardProps> = ({
  tradeInfo,
  statusType,
}) => {
  const navigate = useNavigate();
  return (
    <div className="shopping">
      <div
        className="scont_box"
        onClick={() =>
          navigate(routes.tradeCompleteDetail, { state: { tradeInfo } })
        }
      >
        <span className="date">{formatDateTime(tradeInfo.requestAt)}</span>
        <div className="scont_cbox">
          <div className="scont_left">
            <img src={IconCoupon} />
          </div>
          <div className="scont_right">
            <div className="scont_tit">
              <h3>거래 완료</h3>
              <span>|</span>
              <p>쿠폰({tradeInfo.tradeNumber})</p>
              <img src={ImgArrow} className="arrow" />
            </div>
            <div className="scont_shop">
              <p>자산 0.0g</p>
            </div>
            <div className="scont_cont">
              <ul className="scont_ul01">
                <li>
                  <p>{tradeInfo.assetTypeKor}</p>
                </li>
                <li>
                  <h3>{AmountByAsset(tradeInfo)}</h3>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// 이벤트도 완료만
export const EventCard: React.FC<TradeCardProps> = ({
  tradeInfo,
  statusType,
}) => {
  const navigate = useNavigate();
  return (
    <div className="shopping">
      <div
        className="scont_box"
        onClick={() =>
          navigate(routes.tradeCompleteDetail, { state: { tradeInfo } })
        }
      >
        <span className="date">{formatDateTime(tradeInfo.requestAt)}</span>
        <div className="scont_cbox">
          <div className="scont_left">
            <img src={IconEvent} />
          </div>
          <div className="scont_right">
            <div className="scont_tit">
              <h3>거래 완료</h3>
              <span>|</span>
              <p>{tradeInfo.tradeTypeKor}</p>
              <img src={ImgArrow} className="arrow" />
            </div>
            <div className="scont_shop">
              <p>{tradeInfo.assetTypeKor}</p>
            </div>
            <div className="scont_cont">
              <ul className="scont_ul01">
                <li>
                  <p>{tradeInfo.assetTypeKor}</p>
                </li>
                <li>
                  <h3>{AmountByAsset(tradeInfo)}</h3>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const StorageFeeCard: React.FC<TradeCardProps> = ({
  tradeInfo,
  statusType,
}) => {
  return (
    <div className="shopping">
      <div className="scont_box">
        <span className="date">{formatDateTime(tradeInfo.requestAt)}</span>
        <div className="scont_cbox">
          <div className="scont_left">
            <img src={ico_safe_24} />
          </div>
          <div className="scont_right">
            <div className="scont_tit">
              <h3>보관료 납부 완료</h3>
              <span>|</span>
              <p>출금</p>
            </div>
            <div className="scont_shop">
              <p>{tradeInfo.assetTypeKor}</p>
            </div>
            <div className="scont_cont">
              <ul className="scont_ul01">
                <li>
                  <p>출금액</p>
                </li>
                <li>
                  <h3>{numberWithCommas(tradeInfo.userFee)}원</h3>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
