import { initAuth, updateAuth } from "../features/auth/authSlice";
import { userRefresh } from "../features/user/userSlice";
import * as APIS from "../services/apis";
import { STORAGE_KEY } from "../utils/constants";
import { ROLE } from "../routes";
import { decodeJwt, postMessage } from "./utils";
import { store } from "@/app/store";
import { showToast } from "@/features/toast/toastSlice";

interface AccessToken {
  name: string;
  roles: string;
  sns: string;
  sub: string;
  version: string;
}

export const signIn = (token?: any) => {
  let accessToken = token || sessionStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
  if (accessToken) {
    sessionStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);

    // 권한 저장
    APIS.setAuthorization(accessToken);

    const {
      name,
      roles = "NONE",
      sns,
      sub = "",
      version = "",
    } = decodeJwt(accessToken) as AccessToken;

    const ids = sub.split(":");

    let role = ROLE["NONE"];

    const splitRoles = roles.split(",");
    for (let name of splitRoles) {
      if (role?.LEVEL < ROLE[name]?.LEVEL) {
        role = ROLE[name];
      }
    }
    store.dispatch(
      showToast({ message: "로그인 되었습니다.", icon: "success" }),
    );

    store.dispatch(
      updateAuth({
        isSigned: true,
        name,
        role,
        sns,
        socialId: +ids[0] || 0,
        userId: +ids[1] || 0,
        version,
        roles: splitRoles,
      }),
    );
    store.dispatch(userRefresh);

    /*
     * APP PUSH TOKEN 저장을 위한 로직입니다.
     * 본인인증을 한 유저만 APP PUSH TOKEN 발급 요청합니다.
     *
     * token 파라미터가 없을 경우는 이미 로그인한 회원의 자동로그인 기능이기 때문에
     * 이 떄는 중복 요청이기에 요청하지 않습니다.
     */
    if (token) {
      const param = {
        command: "SEND_TOKEN",
        data: {
          authToken: token,
          apiBaseUrl: import.meta.env.VITE_API_URL,
        },
      };
      postMessage(param);
    }

    if (token) {
      APIS.putNotificationClear();
    }
  }
};

export const signOut = () => {
  APIS.initAuthorization();
  sessionStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
  store.dispatch(updateAuth({ isSigned: false }));
  // store.dispatch(initAuth());
};

export const saveSignupStep = (step: string) => {
  APIS.postSignUpStep(step).then(({ data: { success, message, data } }) => {
    if (success) {
      postMessage({
        command: "CUSTOM_LOG",
        param: {
          log: `${step} SUCCESS`,
        },
      });
    }
  });
};
