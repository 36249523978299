import { useDispatch } from "react-redux";
import useCustomMutation from "@/hooks/queries/useCustomMutation";
import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import * as APIS from "@/services/apis";
import { routes } from "@/routes";
import { showToast } from "@/features/toast/toastSlice";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { validatePhone } from "@/utils/utils";
import { closeBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";

interface IError {
  response?: {
    data?: {
      message: string;
    };
  };
}

export interface IDelivery {
  receiver: string;
  phone: string;
  postCode: string;
  address: string;
  addressDetail: string;
}

export interface IShop {
  shopId: number;
  visitDate: string;
}

export interface IInfo {
  isDelivery: boolean;
  delivery?: IDelivery;
  shopId?: number;
  visitDate?: string;
}

interface IChangeParam {
  transId: string;
  info: IInfo;
}

const useShoppingChange = () => {
  const dispatch = useDispatch();

  const mutationFn = async ({ transId, info }: IChangeParam): Promise<any> => {
    dispatch(showLoading());
    try {
      const response = await APIS.putGoodsBuyDelivery(transId, info);
      dispatch(hideLoading());
      if (response.data.success) {
        dispatch(
          showToast({ message: "정보가 변경되었습니다.", icon: "success" }),
        );
        dispatch(closeBottomSheet());
        return response.data.data;
      } else {
        dispatch(showToast({ message: response.data.message, icon: "error" }));
      }
    } catch (error) {
      dispatch(hideLoading());
      const err = error as IError;
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          showToast({ message: err.response.data.message, icon: "error" }),
        );
      }
      throw error;
    }
  };

  const mutation = useCustomMutation(
    [routes.shoppingDetail, "/change"],
    mutationFn,
  );

  return mutation;
};

export default useShoppingChange;

export const validateInfo = (isDelivery: boolean, info: any) => {
  const checks = isDelivery
    ? [
        { condition: !info?.receiver, message: "수령인을 입력해주세요" },
        { condition: !info?.phone, message: "연락처1을 입력해주세요" },
        {
          condition: !validatePhone(info.phone),
          message: "연락처1을 정확히 입력해주세요",
        },
        {
          condition: !info?.postCode,
          message: "우편번호를 입력해주세요",
        },
        { condition: !info?.address, message: "주소를 입력해주세요" },
        {
          condition: !info?.addressDetail,
          message: "상세 주소를 입력해주세요",
        },
      ]
    : [
        {
          condition: info?.shopId === 0,
          message: "방문할 대리점을 선택해주세요",
        },
        {
          condition: info?.visitDate === null || info?.visitDate === "",
          message: "희망 방문 일자를 선택해주세요",
        },
      ];

  for (let i = 0; i < checks.length; i++) {
    if (checks[i].condition) {
      return { isValid: false, message: checks[i].message };
    }
  }
  return { isValid: true, message: "" };
};
