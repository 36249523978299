import useCustomQuery from "@/hooks/queries/useCustomQuery";
import * as APIS from "@/services/apis";
import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { routes } from "@/routes";

export interface IShopDetail {
  shopName: string;
  phone: string;
  address: string;
  imagePath: string;
}

const useShopDetail = (shopId: string) => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getShopDetail(shopId);
      dispatch(hideLoading());
      return response.data.data as IShopDetail;
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, refetch } = useCustomQuery<IShopDetail>(
    [routes.shopDetail, shopId],
    fetchDetails,
  );

  return {
    ShopDetail: data,
    isLoading,
    error,
    ShopDetailRefetch: refetch,
  };
};
export default useShopDetail;
