import iconLeft from "@assets/icons/icon_left.png";
import iconX from "@assets/icons/icon_x.png";
import icon88Ok from "@assets/icons/icon_88_ok.png";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import * as styles from "../styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "@/routes";
import useShoppingDetail from "@pages/trade-history/hooks/useShoppingDetail";
import { formatDate, numberWithCommas } from "@/utils/utils";
import constants from "@/utils/constants";
import colors from "@assets/styles/colors.css";

const PurchaseSuccess = () => {
  const navigate = useNavigate();
  const param = useParams();
  const transId = param.transId || "";
  const { ShoppingDetail } = useShoppingDetail(transId);

  const purchaseInfo = useSelector((state: RootState) => state.purchase);
  const assetTypeKor =
    ShoppingDetail?.goodsTrans?.assetType === "GOLD" ? "금" : "은";

  const handleClickBuyList = () => {
    navigate(routes.buy);
  };
  const handleClickTradeHistory = () => {
    navigate(`${routes.tradeHistory}/?tradeType=GOODS&statusType=ING`);
  };

  return (
    <div>
      <div className="sub_top02">
        <div className={styles.headerTitle}>주문/결제</div>
        <img src={iconX} className="close" onClick={handleClickBuyList} />
      </div>
      <div className="sub02_wrap sub02_wrap03">
        <div className="sub02_wrap03_top">
          <img src={icon88Ok} className="icon_88" />
          <h3 className="sub02_wrap03_tit">
            <span className="fc-bl03">주문 완료</span> 되었습니다.
          </h3>
        </div>
        <div className="shadow88">
          <div className="sub02_02">
            <h3 className="shadow88_tit">주문 상품</h3>
            <ul>
              <li className="sub02_02_img">
                <img
                  src={
                    constants.s3BaseUrl +
                    "/" +
                    ShoppingDetail?.goodsTrans.goodsImage
                  }
                />
              </li>
              <li>
                <p className="pd_brand">{ShoppingDetail?.goodsTrans?.brand}</p>
                <p className="pd_name">
                  {ShoppingDetail?.goodsTrans?.goodsName}
                </p>
                <p className="pd_num">
                  수량 {ShoppingDetail?.goodsTrans?.qty}개
                </p>
                <h3 className="pd_price">
                  {numberWithCommas(ShoppingDetail?.payment?.sellingPrice)}원
                </h3>
              </li>
            </ul>
          </div>
          {ShoppingDetail?.shopInfo ? (
            <div className="sub02_02">
              <h3 className="shadow88_tit">방문 대리점</h3>
              <ul className="sub02_02_ul02">
                <li>
                  <p>대리점</p>
                </li>
                <li>
                  <h3>{ShoppingDetail?.shopInfo?.shopName}</h3>
                </li>
              </ul>
              <ul className="sub02_02_ul02">
                <li>
                  <p>방문일</p>
                </li>
                <li>
                  <h3>
                    {formatDate(
                      ShoppingDetail?.shopInfo?.visitDate,
                      "YYYY.MM.DD",
                    )}
                  </h3>
                </li>
              </ul>
            </div>
          ) : (
            <div className="sub02_02">
              <h3 className="shadow88_tit">배송지</h3>
              <ul className="sub02_02_ul02">
                <li>
                  <p>수취인</p>
                </li>
                <li>
                  <h3>{ShoppingDetail?.delivery?.receiver}</h3>{" "}
                </li>
              </ul>
              <ul className="sub02_02_ul02">
                <li>
                  <p>연락처</p>
                </li>
                <li>
                  <h3>{ShoppingDetail?.delivery?.phone}</h3>
                </li>
              </ul>
              <div className={styles.deliveryList}>
                <li className={styles.deliveryLabel}>
                  <p>배송지</p>
                </li>
                <li className={styles.deliveryValue}>
                  {`(${ShoppingDetail?.delivery.postCode}) ${ShoppingDetail?.delivery.address} ${ShoppingDetail?.delivery.addressDetail}`}
                </li>
              </div>
              <ul className="sub02_02_ul02">
                <li>
                  <p>수령메모</p>
                </li>
                <li>
                  <h3>{ShoppingDetail?.delivery?.memo}</h3>
                </li>
              </ul>
            </div>
          )}
          <div className="sub02_02 pb08">
            <h3 className="shadow88_tit">결제 정보</h3>
            <ul className="sub02_02_ul02">
              <li>
                <p>결제일시</p>
              </li>
              <li>
                <h3>
                  {formatDate(
                    ShoppingDetail?.goodsTrans.createdAt,
                    "YYYY.MM.DD HH:mm",
                  )}
                </h3>
              </li>
            </ul>
            <ul className="sub02_02_ul02">
              <li>
                <p>주문 금액</p>
              </li>
              <li>
                <h3>
                  {numberWithCommas(ShoppingDetail?.payment?.sellingPrice)}원
                </h3>
              </li>
            </ul>
            <ul className="sub02_02_ul02">
              <li>
                <p>포인트 사용</p>
              </li>
              <li>
                <h3>-{numberWithCommas(ShoppingDetail?.payment.point)}pt</h3>
              </li>
            </ul>
            <ul className="sub02_02_ul02">
              <li>
                <p>보유현금 차감</p>
              </li>
              <li>
                <h3>-{numberWithCommas(ShoppingDetail?.payment.krw)}원</h3>
              </li>
            </ul>
            <ul className="sub02_02_ul02">
              <li>
                <p>보유자산 {assetTypeKor}</p>
              </li>
              <li>
                <h3>-{numberWithCommas(ShoppingDetail?.payment.gram)}g</h3>
              </li>
            </ul>
          </div>
          <div className="sub02_02_total">
            <ul>
              <li>
                <p>최종 결제 금액</p>
              </li>
              <li>
                <h3>
                  {numberWithCommas(ShoppingDetail?.payment?.depositPrice)}원
                </h3>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub02_wrap03_btn ok">
          <button className="gr-btn01" onClick={handleClickTradeHistory}>
            주문내역 확인하기
          </button>
          <button
            className="bk-btn01"
            onClick={handleClickBuyList}
            style={{
              backgroundColor: colors.buttonColor,
            }}
          >
            계속 쇼핑하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseSuccess;
