import React, { useState } from "react";
import IconX from "@assets/icons/icon_x.png";
import { useDispatch } from "react-redux";
import { updateSignUp } from "@/features/auth/signUpSlice";
import { RootState } from "@/app/store";
import { useSelector } from "react-redux";
import { closeBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";

interface CityListProps {
  id: number;
  name: string;
}

interface RegionProps {
  cityList: Array<CityListProps>;
  getCity: Function;
  initCity: Boolean;
}

const SelectRegion: React.FC<RegionProps> = ({
  cityList,
  getCity,
  initCity,
}) => {
  const dispatch = useDispatch();
  const { shopCity, shopDistrictFirst, shopDistrictSecond } = useSelector(
    (state: RootState) => state.signUp,
  );
  const [selectShop, setSelectShop] = useState<CityListProps>({
    id: 0,
    name: "",
  });

  const handleClickCity = (shop: CityListProps) => {
    setSelectShop(shop);
  };

  const handleClickPrev = () => {
    if (shopDistrictFirst.id !== null) {
      getCity(shopCity.id);
      dispatch(
        updateSignUp({
          shopDistrictFirst: { id: null, name: "" },
          shopDistrictSecond: { id: null, name: "" },
          recommendShop: { id: null, name: "" },
        }),
      );
    } else {
      getCity(0);
      dispatch(updateSignUp({ shopCity: { id: 0, name: "" } }));
    }
  };

  const handleClickNext = () => {
    if (shopCity.id === 0) {
      dispatch(updateSignUp({ shopCity: selectShop }));
    } else if (shopCity.id !== 0 && shopDistrictFirst.id === null) {
      dispatch(updateSignUp({ shopDistrictFirst: selectShop }));
    } else if (
      shopDistrictFirst.id !== null &&
      shopDistrictSecond.id === null
    ) {
      dispatch(updateSignUp({ shopDistrictSecond: selectShop }));
    }
    getCity(selectShop.id);
  };
  const handleCloseSheet = () => {
    dispatch(closeBottomSheet());
  };
  return (
    <div className="sub_bottom02 category_tab03">
      <div className="sub_bottom02_wrap" style={{ height: 350 }}>
        <div className="sub_top01">
          <h3 className="sub_bottom_tit">지역 선택</h3>
          <img
            src={IconX}
            alt=""
            className="close"
            onClick={handleCloseSheet}
          />
        </div>
        <ul
          className="category_list02"
          style={{ overflow: "scroll", height: "86%" }}
        >
          {cityList.map((item: CityListProps, index) => {
            let isActive = false;
            if (
              shopDistrictSecond.id !== null &&
              item.id === shopDistrictSecond.id
            ) {
              isActive = true;
            } else if (
              shopDistrictFirst.id !== null &&
              item.id === shopDistrictFirst.id
            ) {
              isActive = true;
            } else if (shopCity.id !== null && item.id === shopCity.id) {
              isActive = true;
            } else if (selectShop.id !== 0 && item.id === selectShop.id) {
              isActive = true;
            }
            return (
              <li
                className={`${isActive ? "on" : ""}`}
                key={index}
                onClick={() => {
                  handleClickCity(item);
                }}
              >
                <p>{item.name}</p>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="sub_bottom_btn">
        {initCity === false && (
          <button className="bf_btn" onClick={handleClickPrev}>
            이전
          </button>
        )}
        <button
          className={`nt_btn ${selectShop.id !== 0 ? "on" : ""}`}
          onClick={handleClickNext}
        >
          다음
        </button>
      </div>
    </div>
  );
};

export default SelectRegion;
