import React, { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import {
  hideToast,
  selectToast,
  type ToastIcon,
} from "@/features/toast/toastSlice";
import IconCheck from "@assets/images/img_check.png";
import IconError from "@assets/images/img_error.png";
import IconCaution from "@assets/images/img_caution02.png";
import IconInfo from "@assets/images/img_info02.png";
import {
  snackbarWrap,
  snackbarMessage,
  snackbarIcon,
  messageContent,
} from "./styles.css.ts";

const Toast: React.FC = () => {
  const dispatch = useDispatch();
  const { open, message, second, icon } = useSelector(selectToast);

  useEffect(() => {
    if (open) {
      const tm = setTimeout(
        () => {
          dispatch(hideToast());
        },
        second ? second * 1000 : 3000,
      );
      return () => {
        clearTimeout(tm);
      };
    }
  }, [open, second, dispatch]);

  if (!open) {
    return null;
  }

  const handleClose = () => {
    dispatch(hideToast());
  };

  const getIcon = (icon: ToastIcon): string | undefined => {
    switch (icon) {
      case "success":
        return IconCheck;
      case "error":
        return IconError;
      case "caution":
        return IconCaution;
      case "info":
        return IconInfo;
      default:
        return undefined;
    }
  };

  const iconSrc = getIcon(icon);

  const formattedMessage = message.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <div className={snackbarWrap}>
      <Snackbar
        key={message ? message : undefined}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={second ? second * 1000 : 3000}
        onClose={handleClose}
        message={
          <div className={snackbarMessage}>
            {iconSrc && (
              <img src={iconSrc} alt="icon" className={snackbarIcon} />
            )}
            <span className={messageContent}>{formattedMessage}</span>
          </div>
        }
        sx={{
          bottom: "80px",
          width: "fit-content",
          margin: "0 auto",
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#222",
            color: "#fff",
            borderRadius: "12px",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
            fontSize: "14px",
            fontFamily: "Pretendard Variable",
            lineHeight: "20px",
            padding: "8px 16px",
            display: "flex",
            alignItems: "center",
          },
          "@media (min-width: 600px)": {
            bottom: "80px",
          },
        }}
      />
    </div>
  );
};

export default Toast;
