import useCustomQuery from "@/hooks/queries/useCustomQuery";
import * as APIS from "@/services/apis";
import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { routes } from "@/routes";

export interface INotificationInfo {
  pushCategory: string;
  pushType: string;
  title: string;
  body: string;
  pushedAt: string;
}

const useGetNotification = () => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getNotifications();
      dispatch(hideLoading());
      return response.data.data as INotificationInfo[];
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, refetch } = useCustomQuery<
    INotificationInfo[]
  >([routes.notification], fetchDetails);

  return {
    notificationInfo: data,
    isLoading,
    error,
    AccountInfoRefetch: refetch,
  };
};

export default useGetNotification;
