import React, { useEffect, useMemo, useState } from "react";
import iconLeft from "@/assets/icons/icon_left.png";
import iconSearch from "@/assets/icons/ico_search.png";
import iconPhone from "@/assets/icons/ico_phone.png";
import imgCaution from "@/assets/images/img_caution_gr2.png";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "@/features/loading/loadingSlice";
import * as APIS from "@/services/apis";
import {
  ch2pattern,
  getCurrentMyPosition,
  getDistanceFromLatLonInKm,
  numberWithCommas,
} from "@/utils/utils";
import { escapeRegExp } from "lodash";
import * as styles from "../style.css";
import { showToast } from "@/features/toast/toastSlice";

interface ReservationDataProps {
  shopId?: number | null;
  visitDate?: any;
}
interface SelectAgencyProps {
  goHome: () => void;
  reservationData: ReservationDataProps;
  setReservationData: React.Dispatch<React.SetStateAction<object>>;
}
interface DataItem {
  latitude: number;
  longitude: number;
}

interface Shop {
  id: number;
  name: string;
  distance: number;
  address: string;
}

const SelectAgency: React.FC<SelectAgencyProps> = ({
  goHome,
  reservationData,
  setReservationData,
}) => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");
  const [shops, setShops] = useState<Shop[]>([]);

  const init = async () => {
    dispatch(showLoading());
    // 현재 위치 불러오기
    const position = await getCurrentMyPosition()
      .then((position: any) => {
        return position;
      })
      .catch((position) => {
        return position;
      });
    const { lat, lng } = position;

    let func = APIS.getAppraisalShop;
    func()
      .then(({ data: { success, data } }) => {
        if (success) {
          let sortedData = data;
          if (lat && lng) {
            // 위치 허용인 경우
            sortedData = data.map((item: DataItem) => {
              const { latitude, longitude } = item;
              let distance = 0;
              if (latitude && longitude) {
                distance = Number(
                  getDistanceFromLatLonInKm(
                    lat, //유저
                    lng, //유저
                    latitude, //데이터
                    longitude, //데이터
                  ).toFixed(2),
                );
              }
              return { ...item, distance };
            });
            // distance에 따라 정렬
            sortedData = sortedData.sort(
              (a: any, b: any) => a.distance - b.distance,
            );
          }
          // 위치 허용 X: 서버에서 오는 순서대로 적용
          setShops(sortedData);
        }
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };
  const handleClickPhone = (phoneNumber: string) => {
    if (phoneNumber) {
      window.location.href = "tel:" + phoneNumber;
    } else {
      dispatch(
        showToast({
          message: "매장 전화번호가 등록되지 않았습니다.",
          icon: "caution",
        }),
      );
    }
  };
  const createFuzzyMatcher = (input: any) => {
    const pattern = input.split("").map(ch2pattern).join(".*?");
    return new RegExp(pattern);
  };
  const mapDataToComponent = (shops: any) => {
    return (
      <div className="sub_search_c s_res">
        {shops.map((shop: any, index: any) => {
          return (
            <div className="shadow88" key={shop.id}>
              <ul>
                <ul
                  style={{ flex: 1 }}
                  onClick={() => {
                    setReservationData({
                      ...reservationData,
                      shopId: shop.id,
                      shopName: shop.name,
                      distance: shop.distance.toFixed(2),
                      visitDate: "",
                    });
                    goHome();
                  }}
                >
                  <li className="box_num spoqa">
                    <h3>{index + 1}</h3>
                  </li>
                  <li className="box_txt">
                    <h3>
                      {/* <span>모두의금</span> 00점 */}
                      {shop.name}
                    </h3>
                    <p>
                      {!!shop.distance && (
                        <>
                          <span>{numberWithCommas(shop.distance)}</span>
                          km
                        </>
                      )}
                    </p>
                    <span>{shop.address}</span>
                  </li>
                </ul>
                <ul>
                  <button
                    className={styles.iconButton}
                    onClick={() => handleClickPhone(shop.phoneNumber)}
                  >
                    <img src={iconPhone} />
                  </button>
                </ul>
              </ul>
            </div>
          );
        })}
      </div>
    );
  };

  const ShopList = useMemo(() => {
    const matchShops = shops.filter(({ name }) => {
      return createFuzzyMatcher(keyword).test(name);
    });
    if (matchShops.length === 0) {
      return (
        <div className="sub_search_c s_none">
          <img src={imgCaution} />
          <h3>검색 결과가 없습니다.</h3>
          {/* <p>ex. 0000 또는 00으로 다시 검색해 보세요!</p> */}
        </div>
      );
    } else {
      return mapDataToComponent(matchShops);
    }
  }, [shops, keyword]);

  useEffect(() => {
    init();
  }, []);
  return (
    <div className="sub_bottom search_tab">
      <div className="sub_bottom_wrap">
        <div className="sub_top_fix">
          <div className="sub_top01">
            <h3 className="sub_bottom_tit">
              <img
                src={iconLeft}
                alt=""
                className="icon_back"
                onClick={goHome}
              />
              대리점 선택
            </h3>
          </div>
          <div className="sub_search">
            <input
              type="text"
              id="search-1"
              placeholder="대리점을 검색하세요. (예: 종로, 대전)"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <label htmlFor="search-1">
              <img src={iconSearch} alt="" className="search_icon" />
            </label>
          </div>
        </div>
        {ShopList}
      </div>
    </div>
  );
};

export default SelectAgency;
