import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "@/routes";
import { postMessage } from "./utils";
import { closeBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";
import { store } from "@/app/store";

const CustomGoBack: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const matchPath = (pattern: string, path: string) =>
    new RegExp(`^${pattern.replace(/:\w+/g, "\\w+")}$`).test(path);

  const customRoutes: Record<string, () => void> = {
    [routes.buy]: () => navigate(routes.main),
    [routes.sell]: () => navigate(routes.main),
    [`${routes.purchaseSuccess}/:transId`]: () => navigate(routes.buy),
  };

  const sendGoBackMessage = (type: "CUSTOM" | "DEFAULT") => {
    postMessage({
      command: "SET_HEADER",
      param: { goBack: type },
    });
  };

  useEffect(() => {
    const isCustomPath = Object.keys(customRoutes).some((pattern) =>
      matchPath(pattern, location.pathname),
    );

    // Vreview 위젯 상태 확인 및 메시지 전송
    const checkVreviewWidget = () => {
      const modalElement = document.querySelector(".vreview-overlay-modal");
      // Vreview 위젯 열렸을 때
      if (modalElement?.classList.contains("open")) {
        sendGoBackMessage("DEFAULT");
        // console.log("열림");
        return true;
      }
      {
        // console.log("닫힘");
        return false;
      }
    };

    // BottomSheet 상태 확인 및 메시지 전송
    const checkBottomSheet = () => {
      const drawerElement = document.querySelector(
        ".MuiDrawer-root",
      ) as HTMLElement;

      if (drawerElement) {
        // 바텀시트가 닫혀 있을 때의 상태 확인
        const isDrawerClosed =
          drawerElement.classList.contains("MuiModal-hidden") ||
          drawerElement.style.visibility === "hidden" ||
          drawerElement.style.pointerEvents === "none" ||
          drawerElement.style.transform.includes("translateY");

        // console.log(
        //   "바텀시트 상태:",
        //   isDrawerClosed ? "닫혀 있음" : "열려 있음",
        // );

        if (!isDrawerClosed) {
          sendGoBackMessage("CUSTOM");
          return true;
        }
      }

      sendGoBackMessage("DEFAULT");
      return false;
    };

    // 전체 상태 감시를 위한 MutationObserver
    const observer = new MutationObserver(() => {
      if (checkVreviewWidget()) {
        return;
      }
      if (checkBottomSheet()) {
        return;
      }

      sendGoBackMessage(isCustomPath ? "CUSTOM" : "DEFAULT");
    });

    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    // 메시지 리스너 정의

    const listener = (event: MessageEvent) => {
      if (window["GoldMarketApp"]) {
        try {
          const message = JSON.parse(event.data) || {};
          const { command } = message;

          if (command === "CUSTOM_GO_BACK") {
            const matchedRoute = Object.keys(customRoutes).find((pattern) =>
              matchPath(pattern, location.pathname),
            );
            if (matchedRoute) {
              // console.log(matchedRoute);
              customRoutes[matchedRoute]();
            } else if (checkBottomSheet()) {
              store.dispatch(closeBottomSheet());
            }
          }
        } catch (error) {
          console.error("Failed to parse JSON:", error);
        }
      }
    };

    // 메시지 리스너 등록
    window.addEventListener("message", listener);

    // 컴포넌트 언마운트 시 observer와 메시지 리스너 해제
    return () => {
      observer.disconnect();
      window.removeEventListener("message", listener);
    };
  }, [location.pathname, navigate]);

  return null;
};

export default CustomGoBack;
