import React, { useEffect, useState } from "react";
import IconLeft from "@assets/icons/icon_left.png";
import ImgWithdrawl from "@assets/images/img_withdrawal.webp";
import ImgCaution from "@assets/images/img_caution.png";
import ImgMyWon2 from "@assets/images/img_my_won_24px02.png";
import * as APIS from "@/services/apis";
import * as styles from "./styles.css";
import { useDispatch } from "react-redux";
import useAcceptableAssets from "./hooks/useAcceptableAssets";
import useAccountInfo from "./hooks/useAccountInfo";
import useWithdrawEnvConfig from "./hooks/useWithdrawEnvConfig";
import { numberWithCommas } from "@/utils/utils";
import { showToast } from "@/features/toast/toastSlice";
import { useNavigate } from "react-router-dom";

import {
  closeBottomSheet,
  openBottomSheet,
} from "@/features/bottom-sheet/bottomSheetSlice";
import PriceBottomSheet from "./components/PriceBottomSheet";
import { routes } from "@/routes";
import { closeModal, openModal } from "@/features/modal/modalSlice";
import colors from "@assets/styles/colors.css";

interface AssetProps {
  acceptableGold: number;
  acceptableKrw: number;
  acceptablePoint: number;
  acceptableSilver: number;
  gold: number;
  krw: number;
  name: string;
  point: number;
  silver: number;
  storageFee: number;
  withdrawKrw: number;
}

const initialAsset: AssetProps = {
  acceptableGold: 0,
  acceptableKrw: 0,
  acceptablePoint: 0,
  acceptableSilver: 0,
  gold: 0,
  krw: 0,
  name: "",
  point: 0,
  silver: 0,
  storageFee: 0,
  withdrawKrw: 0,
};

const Withdraw: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { AcceptableAssets } = useAcceptableAssets();
  const { AccountInfo } = useAccountInfo("WITHDRAW");
  const { config: WithdrawConfig } = useWithdrawEnvConfig();
  const [withdrawPrice, setWithdrawPrice] = useState<string>("");
  const [asset, setAsset] = useState<AssetProps>(initialAsset);

  // 출금 가능 금액 = 사용 가능 원화 - 수수료
  const availableWithdrawAmount = Math.max(
    0,
    Number(AcceptableAssets?.acceptableKrw) - Number(WithdrawConfig?.fee),
  );

  useEffect(() => {
    APIS.getUserHoldingsInfo().then(({ data: { success, data } }) => {
      if (success) {
        setAsset(data);
      }
    });
  }, []);

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleClickEnter = (value: string) => {
    setWithdrawPrice(value);
    dispatch(closeBottomSheet());
  };

  const handleClickAll = () => {
    setWithdrawPrice(availableWithdrawAmount.toString());
  };

  const handleClickWithdrawlRequests = () => {
    if (asset?.storageFee > 0) {
      dispatch(
        openModal(
          <div className="bg_gr_wrap">
            <div className="modal_wrap04 bd_bl">
              <h3>
                출금 하시려면
                <br />
                <span className="fc-bl03">
                  보관료 {numberWithCommas(asset?.storageFee)}KRW
                </span>
                를
                <br /> 납부해주세요.
              </h3>
              <p>보유하신 KRW에서 자동 납부됩니다. </p>
              <div className="modal_btn">
                <button className="bg_gr_btn" onClick={handleCloseModal}>
                  취소
                </button>
                <button
                  className="bg_bk_btn"
                  onClick={handleClickPay}
                  style={{
                    backgroundColor: colors.buttonColor,
                  }}
                >
                  납부하기
                </button>
              </div>
            </div>
          </div>,
        ),
      );
    } else {
      if (isNaN(Number(withdrawPrice)) || +withdrawPrice <= 0) {
        dispatch(
          showToast({ message: "출금 금액을 입력하세요.", icon: "caution" }),
        );
        return;
      }
      const check = /^[0-9]+$/;
      if (!check.test(withdrawPrice)) {
        dispatch(
          showToast({ message: "숫자만 입력 가능합니다.", icon: "caution" }),
        );
        return;
      }

      if (Number(withdrawPrice) > availableWithdrawAmount) {
        dispatch(
          showToast({
            message: "출금 가능 금액을 초과하였습니다.",
            icon: "caution",
          }),
        );
        return;
      }

      navigate(routes.commonSecureKeypad, {
        state: {
          type: "WITHDRAW",
          payload: {
            requestAmount: withdrawPrice,
          },
        },
      });
    }
  };

  const handleClickPay = () => {
    dispatch(closeModal());
    navigate(routes.commonSecureKeypad, {
      state: {
        type: "STORAGE_FEE",
        payload: {
          totalFeeKrw: `${asset?.storageFee}`,
        },
      },
    });
  };

  return (
    <div>
      <PriceBottomSheet onSubmit={handleClickEnter} />
      <div className="sub_top">
        <div>
          <img
            src={IconLeft}
            alt=""
            className="icon_back"
            onClick={handleClickBack}
          />
          <p>출금하기</p>
        </div>
      </div>
      <div className="bank01_top">
        <h3>출금하실 금액을 입력해 주세요.</h3>
        <img src={ImgWithdrawl} alt="" className="bank_mimg" />
      </div>
      <div className="bank_wrap01">
        <div className="bank_box03 mb08 arrow">
          <p>출금 요청 금액</p>
          <button onClick={handleClickAll}>전액</button>
          <input
            type="text"
            placeholder="출금하실 금액을 입력해주세요."
            value={
              withdrawPrice.length > 0
                ? numberWithCommas(withdrawPrice) + "원"
                : ""
            }
            onClick={() => dispatch(openBottomSheet())}
            readOnly
          />
        </div>
        <div className="bank_box01 mb08">
          <p>출금 계좌</p>
          <ul>
            <li>
              <p>{AccountInfo?.bankName}</p>
            </li>
            <li>
              <h3>{AccountInfo?.bankAccountNumber}</h3>
            </li>
          </ul>
        </div>
        <div className="bank_ul">
          <ul>
            <li>
              <p>출금 가능 금액</p>
            </li>
            <li>
              <span>{numberWithCommas(availableWithdrawAmount)}원</span>
            </li>
          </ul>
          <div className={styles.subDesc}>
            *{WithdrawConfig?.withdrawTimeLimit}시간 이내 입금 금액 제외
          </div>
          <ul>
            <li>
              <p>사용 가능 원화</p>
            </li>
            <li>
              <span>{numberWithCommas(AcceptableAssets?.acceptableKrw)}원</span>
            </li>
          </ul>
          <ul>
            <li>
              <p>수수료</p>
            </li>
            <li>
              <span>{WithdrawConfig?.fee}원</span>
            </li>
          </ul>
        </div>
        <div className="bank_noti bank_noti01 mb24">
          <h3>
            <img src={ImgCaution} />
            등록 계좌로 출금 시 유의사항
          </h3>
          <ul>
            <li>
              <p>
                <span className={styles.bold}>
                  출금 수수료는 {WithdrawConfig?.fee}
                </span>
                원입니다.
              </p>
            </li>
            <li>
              <p>
                입금한 금액은 거래여부와 관계없이{" "}
                <span className={styles.bold}>
                  {WithdrawConfig?.withdrawTimeLimit}시간 후 출금 가능
                </span>
                합니다.
              </p>
            </li>
            <li>
              <p>
                거래 안전을 위해{" "}
                <span className={styles.bold}>
                  일일 자동 출금 횟수는{" "}
                  {numberWithCommas(WithdrawConfig?.maxCount)}회로 제한
                </span>
                됩니다. 10회 초과 시 본사 승인 후 출금됩니다.
              </p>
            </li>
            <li>
              <p>
                <span className={styles.bold}>
                  18시 이후 {numberWithCommas(WithdrawConfig?.autoLimit)}원 초과
                </span>
                또는{" "}
                <span className={styles.bold}>
                  {numberWithCommas(WithdrawConfig?.maxCount)}회 초과 출금 요청
                  건
                </span>
                은 영 업일 기준{" "}
                <span className={styles.bold}>
                  익일 9시부터 확인 후 순차적으로 출금 처리
                </span>
                됩니다.
              </p>
            </li>
            <li>
              <p>
                확인 업무시간
                <br /> : 평일 09:00~18:00 (공휴일 제외)
              </p>
            </li>
          </ul>
        </div>
        <div className="bank_noti bank_noti02 fc-bl">
          <h3>
            <img src={ImgMyWon2} />
            출금 한도
          </h3>
          <div className="bank_tb">
            <ul className="bank_tb1">
              <li className="bank_tb_top">
                <h3>자동 출금</h3>
              </li>
              <li className="bank_tb_cont">
                <span>1회 최대</span>
                <p>
                  <span>{numberWithCommas(WithdrawConfig?.autoLimit)}원</span>
                </p>
                <p>
                  <span>
                    {numberWithCommas(WithdrawConfig?.maxCount)}회까지
                  </span>
                </p>
              </li>
            </ul>
            <ul className="bank_tb2">
              <li className="bank_tb_top">
                <h3>승인 출금</h3>
              </li>
              <li className="bank_tb_cont">
                <span>1일 최대</span>
                <p>
                  <span>{numberWithCommas(WithdrawConfig?.maxKrw)}원</span>
                </p>
                <p>
                  <span style={{ color: "#F5F5F5" }}>.</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="sub_btn_wrap">
        <button
          className="sub_wrap02_btn bk01 on"
          onClick={handleClickWithdrawlRequests}
          style={{
            backgroundColor: colors.buttonColor,
          }}
        >
          출금 요청
        </button>
      </div>
    </div>
  );
};

export default Withdraw;
