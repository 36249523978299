import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import modalReducer from "@/features/modal/modalSlice";
import bottomSheetReducer from "@/features/bottom-sheet/bottomSheetSlice";
import loadingReducer from "@/features/loading/loadingSlice";
import marketPriceReducer from "@/features/market-price/marketPriceSlice";
import authReducer from "@/features/auth/authSlice";
import signUpReducer from "@/features/auth/signUpSlice";
import toastReducer from "@/features/toast/toastSlice";
import userReducer from "@/features/user/userSlice";
import feeRateReducer from "@/features/fee-rate/feeRateSlice";
import tenSecondAppraisalReducer from "@/features/appraisal-tensecond/appraisalTenSecondSlice";
import storageSession from "redux-persist/lib/storage/session";
import { encryptTransform } from "redux-persist-transform-encrypt";
import purchaseReducer from "@/features/purchase/purchaseSlice";
import drawerSlice from "@/features/drawer/drawerSlice";

const encryptor = encryptTransform({
  secretKey: import.meta.env.VITE_PERSIST_SECRET_KEY,
  onError: function (error: any) {
    console.log(error);
  },
});

const createPersistConfig = (key: string) => ({
  key,
  storage: storageSession,
  transforms: [encryptor],
});

const persistConfigAppraisal = createPersistConfig("appraisal");
const persistConfigFeeRate = createPersistConfig("feeRate");
const persistConfigPurchase = createPersistConfig("purchase");
const persistConfigMarketPrice = createPersistConfig("purchase");

const appReducer = combineReducers({
  modal: modalReducer,
  bottomSheet: bottomSheetReducer,
  drawer: drawerSlice,
  loading: loadingReducer,
  marketPrice: persistReducer(persistConfigMarketPrice, marketPriceReducer),
  auth: authReducer,
  signUp: signUpReducer,
  user: userReducer,
  toast: toastReducer,
  tenSecondAppraisal: persistReducer(
    persistConfigAppraisal,
    tenSecondAppraisalReducer,
  ),
  feeRate: persistReducer(persistConfigFeeRate, feeRateReducer),
  purchase: persistReducer(persistConfigPurchase, purchaseReducer),
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "clear") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
