import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import ic_close from "@assets/icons/icon_x.png";
import ic_won from "@assets/icons/ico_won_24.png";
import ic_stock from "@assets/images/img_stock.png";
import ic_tooltip from "@assets/images/tooltip.png";
import ic_gold from "@assets/images/img_my_gold_36.png";
import ic_silver from "@assets/images/img_my_silver_36.png";
import TradeModal, { ModalType, orderPriceType } from "./components/TradeModal";
import {
  closeBottomSheet,
  openBottomSheet,
} from "@/features/bottom-sheet/bottomSheetSlice";
import { ButtonBase } from "@mui/material";
import {
  formatTime,
  numberWithCommas,
  requestNativeLogin,
} from "@/utils/utils";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { ROLE, routes } from "@/routes";
import {
  getAvailableAmount,
  getRecommendPrice,
  postExpectTrade,
} from "@/services/apis";
import useInterval from "@/hooks/useInterval";
import { showToast } from "@/features/toast/toastSlice";
import { orderConst } from "@/utils/constants";
import { initFeeRate } from "@/utils/initFeeRate";
import { allWeightAccentBtn } from "./styles.css";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "@/services/storageService";

type Props = {};

const getNoVisibleNoti = () => {
  return !getLocalStorageItem("noVisibleNoti");
};

function TradeAsset({}: Props) {
  const navigate = useNavigate();
  const localAssetType = getLocalStorageItem("tradingAssetType");
  const { state } = useLocation();
  const dispatch = useDispatch();
  const {
    role: { LEVEL: userLevel },
    isSigned,
  } = useSelector((state: RootState) => state.auth);

  const { feeRate } = useSelector((state: RootState) => state.feeRate);
  const { id } = useSelector((state: RootState) => state.user);
  const buySell = state?.tradeType || "SELL";
  const [assetType, setAssetType] = useState(localAssetType || "GOLD");
  const [orderPriceType, setOrderPriceType] =
    useState<orderPriceType>("RECOMMEND");
  const [onFocusAmount, setOnFocusAmount] = useState("");
  const [modalType, setModalType] = useState<ModalType>("");
  const [inputs, setInputs] = useState({
    price: "",
    amount: "",
  });
  const [tempInputs, setTempInputs] = useState({ price: "", amount: "" });
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(getNoVisibleNoti());
  const [posibleprice, setposibleprice] = useState(0);
  // 하한가
  const [minPrice, setMinPrice] = useState<number>(0);
  // 상한가
  const [maxPrice, setMaxPrice] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalFee, setTotalFee] = useState(0);

  const onClickModal = (type: any) => {
    setModalType(type);
    dispatch(openBottomSheet());
  };

  const resetInputs = () => {
    setInputs({
      amount: "",
      price: "",
    });
  };
  const resetTempInputs = () => {
    setTempInputs({
      amount: "",
      price: "",
    });
  };

  const init = () => {
    if (userLevel >= ROLE.ROLE_SELF_VERIFICATION.LEVEL) {
      getAvailableAmount().then(({ data: { data } }) => {
        const { gold = 0, silver = 0, krw = 0, point = 0 } = data || {};
        if (buySell === "SELL") {
          if (assetType === "GOLD") {
            setposibleprice(gold);
          } else {
            setposibleprice(silver);
          }
        } else {
          setposibleprice(krw + point);
        }
      });
    }
  };

  const fetchRecommendPrice = () => {
    getRecommendPrice(assetType, buySell).then(
      ({ data: { data, success } }) => {
        if (success) {
          orderPriceType === "RECOMMEND" &&
            setInputs({
              ...inputs,
              price: Math.trunc(data).toString(),
            });
        }
      },
    );
  };

  const handleClickConfirm = () => {
    if (!isSigned) {
      requestNativeLogin(navigate);
    } else {
      if (buySell === "SELL") {
        if (posibleprice < parseFloat(inputs["amount"])) {
          dispatch(
            showToast({
              message: "주문가능 금액을 초과하였습니다.",
              icon: "caution",
            }),
          );
          return;
        }
      } else {
        if (posibleprice < +totalPrice + (totalFee || 0)) {
          dispatch(
            showToast({
              message: "주문가능 금액을 초과하였습니다.",
              icon: "caution",
            }),
          );
          return;
        }
      }
      onClickModal("orderCheck");
    }
  };

  const TotalPrice = React.memo(() => {
    const isSell = buySell === "SELL";
    const isMarketPrice = orderConst[orderPriceType] === "시장가";

    return (
      <>
        <div className="sell_sub_check">
          <ul>
            <li>
              최종 {isSell ? "판매" : "구매"} {isMarketPrice && "예상"} 금액
            </li>
            <li className={isSell ? "price" : `price fc-rd01`}>
              {numberWithCommas(Math.trunc(totalPrice + totalFee))}원
            </li>
          </ul>
        </div>
        <div className="sell_sub_content">
          <ul>
            <li>
              {isSell ? "판매" : "구매"} {isMarketPrice && "예상"} 금액
            </li>
            <li className="price">
              <span>{numberWithCommas(totalPrice)}</span>원
            </li>
          </ul>
          <ul>
            <li>{isMarketPrice && "예상"} 수수료</li>
            <li className="price">
              <span>{numberWithCommas(totalFee)}</span>원
            </li>
          </ul>
        </div>
      </>
    );
  }, [orderPriceType]);

  useInterval(
    () => {
      fetchRecommendPrice();
    },
    orderPriceType === "RECOMMEND" ? 5000 : null,
  );

  useEffect(() => {
    if (orderPriceType !== "MARKET") {
      let tradeFeeRate = 0;
      let total =
        inputs["price"] !== "" && inputs["amount"] !== ""
          ? parseFloat(inputs.amount) * parseFloat(inputs.price)
          : 0;

      if (total !== 0) {
        tradeFeeRate =
          feeRate.find(
            (item) =>
              item.configType === buySell && item.assetType === assetType,
          )?.feeRate || 0;
        tradeFeeRate = buySell === "SELL" ? -tradeFeeRate : tradeFeeRate;
        tradeFeeRate = total * tradeFeeRate;
      }
      setTotalPrice(Math.trunc(total));
      setTotalFee(Math.trunc(tradeFeeRate));
    }
  }, [orderPriceType, inputs, assetType, buySell, feeRate]);

  useEffect(() => {
    init();
    fetchRecommendPrice();
    let buyPrice = feeRate.find(
      (item) => item.configType === "BUY" && item.assetType === assetType,
    )?.priceLimit;
    let sellPrice = feeRate.find(
      (item) => item.configType === "SELL" && item.assetType === assetType,
    )?.priceLimit;

    if (buyPrice) setMinPrice(buyPrice);
    if (sellPrice) setMaxPrice(sellPrice);
  }, [assetType, isSigned]);

  const title = buySell === "SELL" ? "팔래요" : "살래요";

  useEffect(() => {
    initFeeRate(dispatch);
  }, [dispatch]);

  const noVisible = () => {
    setLocalStorageItem(
      "noVisibleNoti",
      formatTime(new Date(), "YYYYMMDDHHmmss"),
    );
    setIsVisibleTooltip(false);
  };
  return (
    <>
      <TradeModal
        modalType={modalType}
        // inputs={inputs}
        inputs={tempInputs}
        resultInputs={inputs}
        assetType={assetType}
        posibleprice={posibleprice}
        selectOrderType={orderPriceType}
        minPrice={minPrice}
        maxPrice={maxPrice}
        feeValue={totalFee}
        tradeType={buySell}
        totalPrice={totalPrice}
        onClose={() => {
          setModalType("");
          dispatch(closeBottomSheet());
        }}
        onChangeOrderType={(type: orderPriceType) => {
          if (type !== "RECOMMEND") stop();
          if (type === orderPriceType) {
            dispatch(closeBottomSheet());
          } else {
            setOrderPriceType(type);
            resetInputs();
            setTotalFee(0);
            setTotalPrice(0);
          }
        }}
        onChangeInputs={(value: any, name: any) => {
          setTempInputs({
            ...tempInputs,
            [name]: value,
          });
          // setInputs({
          //   ...inputs,
          //   [name]: value,
          // });
        }}
        onChangeTemptoResult={(value: any, name: any) => {
          setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value,
          }));
        }}
        onChangeTotalPrice={(total: number, fee: number) => {
          setTotalPrice(total);
          setTotalFee(fee);
        }}
        onClickConfirm={() => {
          const isMarketOrder = orderPriceType === "MARKET";
          const isAmountValid = inputs.amount !== "";
          const isPriceValid = inputs.price !== "";

          if (isMarketOrder ? isAmountValid : isAmountValid && isPriceValid) {
            navigate(routes.commonSecureKeypad, {
              state: {
                type: "TRADE",
                payload: {
                  tradeType: buySell,
                  assetType: assetType,
                  orderPrice: inputs.price,
                  orderType: isMarketOrder
                    ? "MARKET"
                    : orderPriceType === "RECOMMEND"
                    ? "SUGGEST"
                    : "LIMITS",
                  requestGram: inputs.amount,
                  requestTotal: Math.floor(totalPrice),
                  userId: id,
                },
              },
            });
          }
        }}
      />
      <div className="sub_top sub_top2">
        <div>
          <p>자산 {title}</p>
        </div>
        <ButtonBase
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={ic_close} alt="" className="close" />
        </ButtonBase>
      </div>
      <div className="sub_wrap03">
        <div className="sell_wrap06 sell_wrap00">
          <div className="sell_tab_wrap">
            <div
              className={`tab ${assetType === "GOLD" ? "check_option" : ""}`}
            >
              <ButtonBase
                onClick={() => {
                  setAssetType("GOLD");
                  setLocalStorageItem("tradingAssetType", "GOLD");
                  resetInputs();
                  setTotalPrice(0);
                  setTotalFee(0);
                }}
              >
                <h3>금</h3>
              </ButtonBase>
            </div>
            <div
              className={`tab ${assetType === "SILVER" ? "check_option" : ""}`}
            >
              <ButtonBase
                onClick={() => {
                  setAssetType("SILVER");
                  setLocalStorageItem("tradingAssetType", "SILVER");
                  resetInputs();
                  setTotalPrice(0);
                  setTotalFee(0);
                }}
              >
                <h3>은</h3>
              </ButtonBase>
            </div>
            <ButtonBase
              className="trading_btn"
              onClick={() => {
                noVisible();
                navigate(routes.trading, {
                  state: {
                    tradeType: buySell,
                    assetType: assetType,
                  },
                });
              }}
            >
              <img src={ic_stock} alt="stock_graph_icon" />
              <span>호가창</span>
            </ButtonBase>
            {isVisibleTooltip && (
              <img
                src={ic_tooltip}
                alt="tooltip_icon"
                className="tooltip"
                style={{ display: isVisibleTooltip ? "" : "none" }}
                onClick={() => {
                  noVisible();
                }}
              />
            )}
          </div>
          <div className="sell_sub_wrap01">
            <ul>
              {/* 주문방법 */}
              <li
                className="op_sel"
                onClick={() => {
                  onClickModal("priceType");
                }}
              >
                <p>주문 방법 선택</p>
                <h3>{orderConst[orderPriceType]}</h3>
              </li>
              {/* 가격입력 */}
              <li
                className={`price01 ${
                  orderPriceType !== "LIMITS" ? "disabled" : ""
                } ${onFocusAmount === "price" ? "checked" : ""}`}
              >
                <input
                  readOnly
                  type="text"
                  placeholder={
                    orderPriceType === "MARKET"
                      ? "즉시 체결 가격"
                      : orderPriceType === "RECOMMEND"
                      ? `${numberWithCommas(inputs["price"])}원`
                      : "가격을 입력하세요."
                  }
                  id="num"
                  onChange={() => {}}
                  onFocus={() => {
                    setOnFocusAmount("price");
                  }}
                  onBlur={() => {
                    setOnFocusAmount("");
                  }}
                  disabled={orderPriceType !== "LIMITS"}
                  onClick={() => {
                    resetTempInputs();
                    if (orderPriceType === "LIMITS") onClickModal("price");
                  }}
                  value={
                    orderPriceType !== "LIMITS"
                      ? ""
                      : numberWithCommas(inputs["price"])
                  }
                />
                <label
                  htmlFor="num"
                  style={{
                    width: "100%",
                  }}
                >
                  <p>1g 가격</p>
                </label>
              </li>
              {/* 중량 입력 */}
              <li
                className={`price02 ${
                  onFocusAmount === "amount" ? "checked" : ""
                }`}
              >
                <input
                  readOnly
                  type="text"
                  placeholder={`최소 중량 ${
                    assetType === "GOLD" ? "0.005g" : "0.5g"
                  }`}
                  id="num"
                  onChange={() => {}}
                  onFocus={() => {
                    setOnFocusAmount("amount");
                  }}
                  onBlur={() => {
                    setOnFocusAmount("");
                  }}
                  onClick={() => {
                    resetTempInputs();
                    onClickModal("amount");
                  }}
                  value={numberWithCommas(inputs["amount"])}
                />
                <label
                  htmlFor="num"
                  style={{
                    width: "100%",
                  }}
                >
                  <p>중량 입력</p>
                </label>
              </li>
            </ul>
            {buySell === "SELL" ? (
              <div className="sell_sub_wrap02">
                <img
                  src={assetType === "GOLD" ? ic_gold : ic_silver}
                  alt={`${assetType === "GOLD" ? "gold" : "silver"}_icon`}
                />
                <p>
                  보유 {assetType === "GOLD" ? "금" : "은"}{" "}
                  {/* <span className="spoqa">{numberWithCommas(posibleprice)}</span>g */}
                  <span className="spoqa">
                    {numberWithCommas(posibleprice)}
                  </span>
                  g
                </p>
                <ButtonBase
                  className={allWeightAccentBtn}
                  onClick={() => {
                    setInputs({
                      ...inputs,
                      ["amount"]: String(posibleprice),
                    });
                    if (orderPriceType === "MARKET") {
                      const payload = {
                        tradeType: buySell,
                        assetType: assetType,
                        orderType: orderPriceType,
                        requestGram: String(posibleprice),
                      };
                      postExpectTrade(payload).then(
                        ({ data: { success, data, message } }) => {
                          if (success) {
                            const fee =
                              buySell === "BUY"
                                ? data.tradeFee
                                : -data.tradeFee;
                            setTotalPrice(data?.tradeKrw);
                            setTotalFee(fee);
                          } else {
                            dispatch(
                              showToast({ message: message, icon: "error" }),
                            );
                          }
                        },
                      );
                    }
                  }}
                >
                  + 전량
                </ButtonBase>
              </div>
            ) : (
              <div className="sell_sub_wrap02">
                <img
                  src={ic_won}
                  alt={"won"}
                  style={{ width: 24, height: 24 }}
                />
                <p>
                  구매 가능
                  <span className="spoqa">
                    {` ${numberWithCommas(posibleprice)} `}
                  </span>
                  KRW
                </p>
              </div>
            )}
          </div>
          <div>
            <div className="sell_sub_accordion sell_sub_vertical">
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="checkbox-1"
                    name="checkbox-accordion"
                  />
                  <label htmlFor="checkbox-1">
                    <div className="btn_cx_down"></div>
                    <div className="btn_cx_up"></div>
                  </label>
                  <TotalPrice />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="sub_btn_wrap">
        <ButtonBase
          className={`sub_wrap03_btn ${buySell === "BUY" ? "buy" : ""} ${
            totalPrice !== 0 ? "on" : ""
          }`}
          disabled={totalPrice === 0}
          onClick={() => {
            handleClickConfirm();
          }}
        >
          {buySell === "BUY" ? "살래요" : "팔래요"}
        </ButtonBase>
      </div>
    </>
  );
}

export default TradeAsset;
