import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
} from "@tanstack/react-query";
import { Provider } from "react-redux";
import { store, persistor } from "../src/app/store"; // redux store
import { PersistGate } from "redux-persist/integration/react";
import { STORAGE_KEY } from "./utils/constants";
import axios from "axios";
import { expireAuth, postMessage } from "@/utils/utils";

// 응답 인터셉터 추가
axios.interceptors.response.use(
  function (response) {
    // 응답 데이터를 가공
    // ...
    return response;
  },
  (error) => {
    const status = error?.response?.status;
    if (status === 401 || status === 403) {
      postMessage({
        command: "CUSTOM_LOG",
        param: {
          log: `${error?.response}`,
        },
      });
      expireAuth();
    }

    console.log("api error", error);
    return Promise.reject(error);
  },
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      placeholderData: keepPreviousData,
    },
  },
});

if (!sessionStorage.getItem(STORAGE_KEY.USER_AGENT)) {
  sessionStorage.setItem(STORAGE_KEY.USER_AGENT, navigator.userAgent);
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
