import React from "react";
import { useSelector } from "react-redux";
import { selectLoading } from "../../../features/loading/loadingSlice";
import * as styles from "./style.css";

const Loading: React.FC = () => {
  const loading = useSelector(selectLoading);

  if (!loading.visible) return null;
  return (
    <div className={styles.dimmed}>
      <div className={styles.container}>
        <div className={styles.loadingSpinner}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
          >
            <path
              d="M58.5641 49.8423C55.484 54.4281 51.2692 58.1384 46.3298 60.6122C41.3905 63.0859 35.8949 64.2389 30.3778 63.9589C24.8608 63.6788 19.5101 61.9753 14.8467 59.0141C10.1832 56.0529 6.36575 51.9349 3.76579 47.0609C1.16583 42.1868 -0.128077 36.7226 0.00999713 31.2002C0.148071 25.6777 1.71342 20.2851 4.55371 15.547C7.39399 10.8089 11.4125 6.88685 16.2181 4.16241C21.0237 1.43797 26.4528 0.00398841 31.9769 8.30567e-06L31.9827 7.96787C27.834 7.97085 23.7568 9.04778 20.1477 11.0938C16.5387 13.1399 13.5208 16.0854 11.3877 19.6437C9.25464 23.202 8.07906 27.2519 7.97537 31.3993C7.87167 35.5467 8.8434 39.6503 10.796 43.3108C12.7486 46.9712 15.6155 50.0639 19.1178 52.2877C22.6201 54.5116 26.6384 55.7909 30.7817 56.0012C34.9251 56.2116 39.0523 55.3457 42.7618 53.4879C46.4712 51.6301 49.6366 48.8436 51.9498 45.3997L58.5641 49.8423Z"
              fill="#00CCFF"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Loading;
