import React, { useState, useEffect } from "react";
import IconCopy from "@assets/icons/ico_copy_16.png";
import BtnCall from "@assets/images/btn_call_24ps.png";
import IconLeft from "@assets/icons/icon_left.png";
import { useDispatch, useSelector } from "react-redux";
import {
  closeBottomSheet,
  openBottomSheet,
} from "@/features/bottom-sheet/bottomSheetSlice";
import { BottomSheet } from "@components/index";
import {
  formatPhoneNumberForPurchase,
  splitPhoneNumber,
  validatePhone,
} from "@/utils/utils";
import FindShop from "@components/common/find-shop/FindShop";
import Calendar from "@components/common/calendar/Calendar";
import useShoppingChange from "../hooks/useShoppingChange";
import { validateInfo } from "../hooks/useShoppingChange";
import { showToast } from "@/features/toast/toastSlice";
import {
  DeliveryCard,
  DeliveryForm,
} from "@components/common/delivery-form/DeliveryForm";
import { RootState } from "@/app/store";
import {
  resetPurchaseInfo,
  setPurchaseInfo,
} from "@/features/purchase/purchaseSlice";
import {
  SelectedShopInfo,
  ShopSelection,
  VisitDateInfo,
  CalendarSelection,
} from "@pages/buy/components/HowToReceive";
import useAddressList from "@pages/myPage/hooks/useAddressList";
import { CUSTOM_AVAILABLE_MEMO, MEMO_LIST } from "@/utils/constants";
import colors from "@assets/styles/colors.css";
import * as styles from "../styles.css";
import DaumPostcodeEmbed from "react-daum-postcode";

const ShoppingHowReceive: React.FC<any> = ({
  goodsTrans,
  delivery,
  shopInfo,
  earliestVisitDate,
  ShoppingLoading,
  ShoppingDetailRefetch,
}) => {
  const dispatch = useDispatch();
  const { canModifyDelivery, isDelivery } = goodsTrans;
  const [activeBottomSheet, setActiveBottomSheet] = useState<string>("shop");
  const [editMode, setEditMode] = useState<boolean>(false);

  const shoppingChange = useShoppingChange();
  const { userAddress, isLoading } = useAddressList();
  const purchaseInfo = useSelector((state: RootState) => state.purchase);

  const defaultAddress = !isLoading
    ? userAddress?.find((address) => address.isDefault)
    : null;

  const initialInfo = {
    // 택배 배송지 정보
    postCode: "", // 우편번호
    address: "", // 주소
    addressDetail: "", // 상세주소
    addressName: "", // 배송지명
    receiver: "", // 수령인
    phone: { prefix: "", part1: "", part2: "" }, // 연락처1
    mobile: { prefix: "", part1: "", part2: "" }, // 연락처2
    memo: "", // 수령메모
    customMemo: "", // 메모 직접 입력
    isDefault: false, // 기본 배송지로 추가
    editId: 0, // 배송지 ID

    //대리점 정보
    shopId: 0,
    visitDate: "",
    shopName: "",
    shopDistance: 0,
    initialDelivery: null,
    isEdit: false,
  };

  useEffect(() => {
    // 초기 렌더링 시, purchaseInfo.initialDelivery 설정
    if (purchaseInfo.initialDelivery === null && !ShoppingLoading) {
      dispatch(
        setPurchaseInfo({
          isDelivery: isDelivery,
          initialDelivery: isDelivery,
        }),
      );
    }
  }, [isDelivery]);

  useEffect(() => {
    setEditMode(goodsTrans.isDelivery !== purchaseInfo.isDelivery);
  }, [goodsTrans.isDelivery, purchaseInfo.isDelivery]);

  useEffect(() => {
    if (defaultAddress && !purchaseInfo.receiver) {
      const addressMemo = defaultAddress?.memo || "";
      const memo = MEMO_LIST.includes(addressMemo)
        ? addressMemo
        : defaultAddress?.memo !== null
        ? CUSTOM_AVAILABLE_MEMO
        : "";
      const customMemo = memo === CUSTOM_AVAILABLE_MEMO ? addressMemo : "";

      dispatch(
        setPurchaseInfo({
          receiver: defaultAddress.receiver,
          addressName: defaultAddress.addressName,
          postCode: defaultAddress.postCode,
          address: defaultAddress.address,
          addressDetail: defaultAddress.addressDetail,
          phone: splitPhoneNumber(defaultAddress.phone),
          mobile: defaultAddress.mobile
            ? splitPhoneNumber(defaultAddress.mobile)
            : { prefix: "", part1: "", part2: "" },
          memo,
          customMemo,
          isDefault: defaultAddress.isDefault,
        }),
      );
    }
  }, [defaultAddress, purchaseInfo.receiver, dispatch]);

  const handleDeliveryChange = (isDelivery: boolean) => {
    if (!canModifyDelivery) return;
    dispatch(setPurchaseInfo({ isDelivery }));
  };

  const handleOpenBottomSheet = (type: string) => {
    if (!canModifyDelivery) return;
    setActiveBottomSheet(type);
    dispatch(openBottomSheet());
  };

  const handleSelectShop = (result: any) => {
    dispatch(
      setPurchaseInfo({
        shopId: result.id,
        visitDate: "",
        shopName: result.name,
        shopDistance: result.distance,
      }),
    );
    setActiveBottomSheet("calendar");
  };

  const handleSelectDate = (result: any) => {
    dispatch(setPurchaseInfo({ visitDate: result.visitDate }));
  };

  // 주소 검색
  const handleCompleteSearch = (result: any) => {
    dispatch(
      setPurchaseInfo({
        postCode: result.zonecode,
        address: result.address,
      }),
    );
    dispatch(closeBottomSheet());
  };

  const handleClickInfoChange = () => {
    if (!canModifyDelivery) {
      dispatch(
        showToast({
          icon: "caution",
          message: "수령 방법 변경이 불가능합니다.",
        }),
      );
      return;
    }

    const {
      receiver,
      addressName,
      postCode,
      address,
      addressDetail,
      phone,
      mobile,
      visitDate,
      shopId,
      memo,
      customMemo,
    } = purchaseInfo;
    const formatMemo = memo === "" ? "" : customMemo ? customMemo : memo;

    // mobile 지역번호만 입력하거나 유효성 틀릴 경우, 저장 X
    const formatMobile = validatePhone(mobile)
      ? mobile
      : { prefix: "", part1: "", part2: "" };

    const deliveryInfo = purchaseInfo.isDelivery
      ? {
          delivery: {
            receiver,
            addressName,
            postCode,
            address,
            addressDetail,
            phone: formatPhoneNumberForPurchase(phone),
            mobile: formatPhoneNumberForPurchase(formatMobile),
            memo: formatMemo,
          },
        }
      : {
          visitDate,
          shopId,
        };
    // console.log(deliveryInfo);
    const { isValid, message } = validateInfo(
      purchaseInfo.isDelivery,
      purchaseInfo,
    );
    if (!isValid) {
      dispatch(showToast({ icon: "caution", message }));
    } else {
      shoppingChange.mutate(
        {
          transId: goodsTrans.goodsTransId,
          info: { ...deliveryInfo, isDelivery: purchaseInfo.isDelivery },
        },
        {
          onSettled: () => {
            dispatch(setPurchaseInfo({ ...initialInfo }));
            ShoppingDetailRefetch();
          },
        },
      );
      dispatch(closeBottomSheet());
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {goodsTrans.isPost && (
        <div className="shadow88 mb16 shopping_box04">
          <h3 className="shadow88_tit fc-bk01">수령 방법 선택</h3>
          <div className={`select_btn ${!canModifyDelivery ? "selected" : ""}`}>
            <ul>
              <li>
                <input
                  type="radio"
                  name="select"
                  id="select01"
                  checked={purchaseInfo.isDelivery}
                  onChange={() => handleDeliveryChange(true)}
                  disabled={!canModifyDelivery && !purchaseInfo.isDelivery}
                />
                <label htmlFor="select01">
                  <p>택배 수령</p>
                </label>
              </li>
              <li>
                <input
                  type="radio"
                  name="select"
                  id="select02"
                  checked={!purchaseInfo.isDelivery}
                  onChange={() => handleDeliveryChange(false)}
                  disabled={!canModifyDelivery && purchaseInfo.isDelivery}
                />
                <label htmlFor="select02">
                  <p>대리점 방문</p>
                </label>
              </li>
            </ul>
          </div>
          <span
            className={canModifyDelivery ? "shopping_noti" : "shopping_noti02"}
          >
            {canModifyDelivery
              ? "상품 출고 전까지 수령 방법을 변경할 수 있습니다."
              : "상품이 출고되어 수령 방법을 변경할 수 없습니다."}
          </span>
        </div>
      )}
      {editMode ? (
        <>
          {purchaseInfo.isDelivery ? (
            defaultAddress ? (
              <DeliveryCard deliveryInfo={purchaseInfo} />
            ) : (
              <DeliveryForm handleOpenBottomSheet={handleOpenBottomSheet} />
            )
          ) : (
            <div className={`shadow88 mb16 shopping_box08`}>
              <h3 className={`shadow88_tit fc-bk01`}>
                수령 대리점
                <br />
                <span className={styles.subText}>
                  수령할 대리점 및 일자를 선택해 주세요.
                </span>
              </h3>
              <div>
                {purchaseInfo.shopId !== 0 ? (
                  <div className="box08_sel sub02_03">
                    <SelectedShopInfo
                      shopName={purchaseInfo.shopName}
                      shopDistance={purchaseInfo.shopDistance}
                      handleOpenBottomSheet={handleOpenBottomSheet}
                    />
                    {purchaseInfo.visitDate ? (
                      <VisitDateInfo
                        visitDate={purchaseInfo.visitDate}
                        handleOpenBottomSheet={handleOpenBottomSheet}
                      />
                    ) : (
                      <CalendarSelection
                        shopName={purchaseInfo.shopName}
                        handleOpenBottomSheet={handleOpenBottomSheet}
                      />
                    )}
                  </div>
                ) : (
                  <div className="box08_sel">
                    <ShopSelection
                      handleOpenBottomSheet={handleOpenBottomSheet}
                    />
                    <CalendarSelection
                      shopName={purchaseInfo.shopName}
                      handleOpenBottomSheet={handleOpenBottomSheet}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <button className="shopping_bt mb16" onClick={handleClickInfoChange}>
            수령방법 변경하기
          </button>
        </>
      ) : isDelivery ? (
        <>
          {purchaseInfo.isEdit ? (
            <>
              <DeliveryCard
                deliveryInfo={{
                  ...purchaseInfo,
                }}
                canModifyDelivery={goodsTrans.canModifyDelivery}
              />
              <button
                className="shopping_bt mb16"
                onClick={handleClickInfoChange}
              >
                수령방법 변경하기
              </button>
            </>
          ) : (
            <DeliveryCard
              deliveryInfo={{
                ...delivery,
                phone: splitPhoneNumber(delivery.phone),
              }}
              canModifyDelivery={goodsTrans.canModifyDelivery}
            />
          )}
        </>
      ) : (
        <ShopInfo
          dispatch={dispatch}
          shopName={shopInfo.shopName}
          address={shopInfo.address}
          addressDetail={shopInfo.addressDetail}
          visitDate={shopInfo.visitDate}
          shopId={shopInfo.shopId}
          phoneNumber={shopInfo.phoneNumber}
          canModifyDelivery={canModifyDelivery}
          handleOpenBottomSheet={handleOpenBottomSheet}
        />
      )}

      <BottomSheet>
        {activeBottomSheet === "shop" && (
          <FindShop
            goodsId={goodsTrans.goodsId}
            service="buy"
            onSelect={handleSelectShop}
          />
        )}
        {activeBottomSheet === "calendar" && (
          <div className="sub_bottom cal_tab">
            <div className="sub_bottom_wrap">
              <div className="sub_top01">
                <h3 className="sub_bottom_tit">
                  <img
                    src={IconLeft}
                    alt=""
                    className="icon_back"
                    onClick={() => dispatch(closeBottomSheet())}
                  />
                  예약일 선택
                </h3>
              </div>
              <Calendar
                reservationData={{
                  shopId: purchaseInfo.shopId,
                  visitDate: purchaseInfo.visitDate,
                }}
                setReservationData={handleSelectDate}
                earliestVisitDate={earliestVisitDate}
              />
            </div>
            <div className={styles.subBtnWrap}>
              <button
                className={`${styles.subWrap02Btn} ${
                  purchaseInfo.visitDate !== ""
                    ? ""
                    : styles.subWrap02BtnDisabled
                }`}
                onClick={() =>
                  editMode
                    ? dispatch(closeBottomSheet())
                    : handleClickInfoChange()
                }
              >
                선택
              </button>
            </div>
          </div>
        )}
        {activeBottomSheet === "address" && (
          <div className={styles.addressWrap}>
            <DaumPostcodeEmbed
              onComplete={handleCompleteSearch}
              style={{ height: 500 }}
              autoClose={false}
            />
          </div>
        )}
      </BottomSheet>
    </div>
  );
};
export default ShoppingHowReceive;

export interface IShopInfoProps {
  dispatch: any;
  shopName: string;
  address: string;
  addressDetail: string;
  visitDate: string;
  phoneNumber: string;
  shopId: number;
  canModifyDelivery?: boolean;
  handleOpenBottomSheet: (type: string) => void;
}

// 대리점 정보 컴포넌트
export const ShopInfo: React.FC<IShopInfoProps> = ({
  dispatch,
  shopName,
  address,
  addressDetail,
  visitDate,
  phoneNumber,
  shopId,
  canModifyDelivery,
  handleOpenBottomSheet,
}) => {
  const handleClickAddressCopy = () => {
    const fullAddress = `${address} ${addressDetail}`;
    navigator.clipboard
      .writeText(fullAddress)
      .then(() => {
        dispatch(
          showToast({
            message: "주소가 클립보드에 복사되었습니다.",
            icon: "success",
          }),
        );
      })
      .catch(() => {
        dispatch(
          showToast({ message: "주소 복사에 실패했습니다.", icon: "error" }),
        );
      });
  };

  const handleChangeVisitDate = () => {
    dispatch(setPurchaseInfo({ shopId: shopId }));
    handleOpenBottomSheet("calendar");
  };

  const handleClickPhone = (phoneNumber: string) => {
    if (phoneNumber) {
      window.location.href = "tel:" + phoneNumber;
    } else {
      dispatch(
        showToast({
          message: "대리점 전화번호가 등록되지 않았습니다.",
          icon: "caution",
        }),
      );
    }
  };

  return (
    <div className="shadow88 mb16 shopping_box05">
      <h3 className="shadow88_tit fc-bk01">방문 수령</h3>
      <div className="shopping_ul shopping_ul01">
        <ul>
          <li className="shopping_li01">
            <h3>
              방문 대리점
              <img src={IconCopy} alt="복사" onClick={handleClickAddressCopy} />
            </h3>
          </li>
          {canModifyDelivery && (
            <li className="shopping_li02">
              <button onClick={() => handleOpenBottomSheet("shop")}>
                <p>방문 대리점 변경</p>
              </button>
            </li>
          )}
        </ul>
        <ul>
          <li className="shopping_li01">
            <p>대리점</p>
          </li>
          <li className="shopping_li02">
            <h3>{shopName}</h3>
          </li>
        </ul>
        <ul>
          <li className="shopping_li01">
            <p>주소</p>
          </li>
          <li className="shopping_li02">
            <p>{address + addressDetail}</p>
          </li>
        </ul>
      </div>
      <div className="shopping_ul shopping_ul02">
        <ul>
          <li className="shopping_li01">
            <h3>방문일</h3>
          </li>
          {canModifyDelivery && (
            <li className="shopping_li02">
              <button onClick={handleChangeVisitDate}>
                <p>방문일 변경</p>
              </button>
            </li>
          )}
        </ul>
        <ul>
          <li className="shopping_li01">
            <p>방문 일자</p>
          </li>
          <li className="shopping_li02">
            <h3>{visitDate}</h3>
          </li>
        </ul>
        <ul>
          <li className="shopping_li01">
            <p>영업시간</p>
          </li>
          <li className="shopping_li02">
            <button onClick={() => handleClickPhone(phoneNumber)}>
              <p>대리점 문의</p>
              <img src={BtnCall} />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};
