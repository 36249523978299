import React, { useEffect, useState } from "react";
import closeIcon from "@assets/icons/btn_close_48_2.png";
import { useDispatch } from "react-redux";
import { closeBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";
import * as APIS from "@/services/apis";
import colors from "@assets/styles/colors.css";
interface AcceptTermsDetailProps {
  selectedType: string | null;
  title: string | null;
  required: boolean;
  onConfirm: () => void;
}

const AcceptTermsDetail: React.FC<AcceptTermsDetailProps> = ({
  selectedType,
  title,
  required,
  onConfirm,
}) => {
  const dispatch = useDispatch();
  const [detailContent, setDetailContent] = useState("");

  useEffect(() => {
    if (selectedType) {
      APIS.getTermsDetail(selectedType).then(
        ({
          data: {
            data: { content },
          },
        }) => {
          setDetailContent(content);
        },
      );
    }
  }, [selectedType]);

  const handleClickClose = () => {
    dispatch(closeBottomSheet());
  };

  const handleClickCheck = () => {
    onConfirm();
  };

  return (
    <div className="sub_bottom03">
      <div className="sub_bottom03_wrap">
        <div className="sub_top01_2">
          <h3 className="sub_bottom_tit">
            <span className={`${required ? "fc-bl03" : ""}`}>
              {required ? "[필수]" : "[선택]"}
            </span>
            {title}
          </h3>
          <img
            src={closeIcon}
            alt=""
            className="close"
            onClick={() => {
              handleClickClose();
            }}
          />
        </div>
        <div className="terms_wrap">
          <div dangerouslySetInnerHTML={{ __html: detailContent }} />
        </div>
      </div>
      <div className="sub03b_btn02">
        <button
          className="on"
          onClick={handleClickCheck}
          style={{
            backgroundColor: colors.buttonColor,
          }}
        >
          확인
        </button>
      </div>
    </div>
  );
};

export default AcceptTermsDetail;
