let BaseURL;
if (process.env.NODE_ENV === "development") {
  BaseURL = "http://localhost:8181";
} else {
  BaseURL = "http://52.78.242.118:8181";
}
BaseURL = import.meta.env.VITE_API_URL;

export default {
  token: "gumtoken",
  drawerMinWidth: 250,
  accessToken: "goldmarket_accessToken",
  apiBaseUrl: BaseURL,
  s3BaseUrl: import.meta.env.VITE_S3_BASE_URL,
};

export const CONSTANT = {
  GOLD: {
    TITLE: "금",
    TYPE: "gold",
  },
  SILVER: {
    TITLE: "은",
    TYPE: "silver",
  },
} as const;

export const orderConst: any = {
  RECOMMEND: "추천가",
  MARKET: "시장가",
  LIMITS: "직접 입력",
};

export const SERVICE_TYPE = import.meta.env.VITE_SERVICE_TYPE;

const STORAGE_KEY_PREFIX = "kumbang.";
export const STORAGE_KEY = {
  ACCESS_TOKEN: STORAGE_KEY_PREFIX + "accessToken",
  FIRST_LOAD: STORAGE_KEY_PREFIX + "firstLoad",
  READ_BOTTOM_POPUPS: STORAGE_KEY_PREFIX + "readBottomPopups",
  POPUP_EXPIRES: STORAGE_KEY_PREFIX + "popupExpires",
  USER_AGENT: STORAGE_KEY_PREFIX + "userAgent",
};

export const APP_VERSION = import.meta.env.VITE_VERSION;

export const KAKAO_APP_KEY = import.meta.env.VITE_KAKAO_APP_KEY;
export const KAKAO_CHANNEL_PUBLIC_ID = import.meta.env
  .VITE_KAKAO_CHANNEL_PUBLIC_ID;

export const PHONE_CODE = [
  "010",
  "011",
  "016",
  "017",
  "018",
  "019",
  "02",
  "031",
  "032",
  "033",
  "041",
  "042",
  "043",
  "044",
  "051",
  "052",
  "053",
  "054",
  "055",
  "061",
  "062",
  "063",
  "064",
];

export const TRADE_TYPES = [
  { type: "BUY", label: "살래요" },
  { type: "SELL", label: "팔래요" },
  { type: "GOODS", label: "쇼핑" },
  { type: "APPRAISAL", label: "감정평가" },
  { type: "DEPOSIT", label: "입금" },
  { type: "WITHDRAW", label: "출금" },
  { type: "COUPON", label: "포인트/쿠폰" },
  { type: "EVENT", label: "이벤트" },
  { type: "SEND_ASSET", label: "자산 보냄" },
  { type: "RECEIVE_ASSET", label: "자산 받음" },
  { type: "STORAGE_FEE", label: "보관료" },
];

export const ASSET_TYPES = [
  { type: "KRW", label: "원화" },
  { type: "POINT", label: "포인트" },
  { type: "GOLD", label: "금" },
  { type: "SILVER", label: "은" },
];

export const PERIODS = [
  { label: "오늘", value: "TODAY" },
  { label: "1주일", value: "WEEK" },
  { label: "1개월", value: "MONTH" },
  { label: "3개월", value: "3MONTH" },
  { label: "직접 입력", value: "CUSTOM" },
] as const;

export const SORT_TYPES = [
  { label: "최신순", value: "DESC" },
  { label: "과거순", value: "ASC" },
];

export const TRANS_HISTORIES = {
  DELIVERY: [
    { status: "PAYMENT_COMPLETED", description: "결제완료" },
    { status: "PREPARING", description: "상품준비중" },
    { status: "DELIVERY", description: "배송중" },
    { status: "RECEIVED", description: "상품수령" },
    { status: "CONFIRMED", description: "구매확정" },
  ],
  VISIT: [
    { status: "PAYMENT_COMPLETED", description: "결제완료" },
    { status: "PREPARING", description: "상품준비중" },
    { status: "ARRIVED", description: "대리점 도착" },
    { status: "RECEIVED", description: "상품수령" },
    { status: "CONFIRMED", description: "구매확정" },
  ],
};

export const MEMO_LIST = [
  "배송 메모를 직접 입력할게요.",
  "문 앞에 놓아주세요.",
  "택배함에 넣어주세요.",
  "경비실에 맡겨주세요.",
  "부재 시 연락주세요.",
  "직접 받을게요.",
];

export const CUSTOM_AVAILABLE_MEMO = "배송 메모를 직접 입력할게요.";

export const ADDRESS_NAME_LIST = ["집", "회사", "가족", "친구"];
