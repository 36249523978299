import React, { useState, useEffect } from "react";
import iconSell from "@assets/images/icon_sell.png";
import iconBuy from "@assets/images/icon_buy.png";
import { routes } from "../../../routes";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerHeight <= 568);
    };

    checkScreenSize(); // 초기 검사
    window.addEventListener("resize", checkScreenSize); // 크기 변경 시 검사

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return (
    <div className={`home_menu ${isSmallScreen ? "home_menu02" : ""}`}>
      <div className="home_buy shadow88" onClick={() => navigate(routes.buy)}>
        <h3>살래요</h3>
        <p>인출하기</p>
        <img src={iconBuy} alt="buy" />
      </div>
      <div className="home_sell shadow88" onClick={() => navigate(routes.sell)}>
        <h3>팔래요</h3>
        <p>감정하기</p>
        <img src={iconSell} alt="sell" />
      </div>
    </div>
  );
};

export default Menu;
