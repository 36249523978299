import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import useCustomQuery from "@/hooks/queries/useCustomQuery";
import { routes } from "@/routes";
import { useDispatch } from "react-redux";
import * as APIS from "@/services/apis";

export type GoodsGroupInfo = Array<{
  goodsGroupId: number;
  goodsGroupName: string;
  type: string;
  goodsGroupImage?: string;
  goodsIdList: number[];
}>;

const useGoodsGroup = () => {
  const dispatch = useDispatch();

  const fetchCategory = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getGoodsGroup();
      dispatch(hideLoading());
      return response.data.data as GoodsGroupInfo;
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading } = useCustomQuery(
    [routes.buy, "group"],
    fetchCategory,
  );
  return { data, error, isLoading };
};

export default useGoodsGroup;
