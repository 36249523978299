import React, { useState } from "react";
import { BottomSheet } from "@components/index";
import {
  openBottomSheet,
  closeBottomSheet,
} from "@/features/bottom-sheet/bottomSheetSlice";
import CommonKeypad from "@components/common/CommonKeypad";
import IconLeft from "@assets/icons/icon_left.png";
import IconClose from "@assets/icons/login-close.png";
import { useDispatch } from "react-redux";
import { numberWithCommas } from "@/utils/utils";
import colors from "@assets/styles/colors.css";

const PriceBottomSheet = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const [withdrawPrice, setWithdrawPrice] = useState("");

  const handleClickKeypad = (item: any) => {
    if ((withdrawPrice === "" || parseFloat(withdrawPrice) === 0) && item === 0)
      return;
    if (typeof item === "number") {
      setWithdrawPrice(withdrawPrice.concat(`${item}`));
    } else if (item === "delete") {
      setWithdrawPrice(withdrawPrice.slice(0, -1));
    } else if (item === "deleteAll") {
      setWithdrawPrice("");
    } else if (item === "decimal") {
      if (!withdrawPrice.includes(".")) {
        setWithdrawPrice(withdrawPrice.concat(`.`));
      }
      return;
    }
  };

  const handleClickEnter = () => {
    onSubmit(withdrawPrice);
  };

  return (
    <BottomSheet>
      <div className="">
        <div className="sub_bottom rv_tab keypad_tab03">
          <div className="sub_bottom_wrap">
            <div className="sub_top_fix">
              <div className="sub_top01">
                <h3 className="sub_bottom_tit">출금 금액 입력</h3>
                <img src="./img/icon_x.png" alt="" className="close" />
              </div>
            </div>
            <div className="bottom_box_wrap">
              <input
                className="keypad_input"
                type="text"
                value={
                  withdrawPrice.length > 0
                    ? numberWithCommas(withdrawPrice) + "원"
                    : ""
                }
                placeholder="출금 금액 입력"
                readOnly
              />
              <CommonKeypad
                onClickKeypad={handleClickKeypad}
                modalType="price"
              />
            </div>
            <button
              className="sub_wrap02_btn bk01 on"
              onClick={handleClickEnter}
              style={{
                backgroundColor: colors.buttonColor,
              }}
            >
              입력하기
            </button>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

export default PriceBottomSheet;
