import { useEffect, useState } from "react";
import * as style from "./BottomPopup.css";
import useGetPopups from "../hooks/useGetPopups";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "@/services/storageService";
import { useNavigate } from "react-router-dom";
import { routes } from "@/routes";
import { postMessage } from "@/utils/utils";
import moment from "moment-timezone";

type StorageHomeVisited = { popupPeriod: number; noticeId: number } | null;

const getPoupPeriodText = (popupPeriod: 1 | 3 | 7 | 0) => {
  let periodText = "";
  switch (popupPeriod) {
    case 1: {
      periodText = "오늘 하루";
      break;
    }
    case 3: {
      periodText = "3일간";
      break;
    }
    case 7: {
      periodText = "7일간";
      break;
    }
    default: {
      periodText = "다시";
    }
  }

  return `${periodText} 보지 않기`;
};

const BottomPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const { data = [], isFetching } = useGetPopups();

  const S3_BASE_URL = import.meta.env.VITE_S3_BASE_URL;

  const handlePeriodClose = (popupPeriod: number, noticeId: number) => () => {
    const toSaved = {
      popupPeriod,
      noticeId,
    };
    setLocalStorageItem("homeVisited", toSaved);
    setIsOpen(false);
  };

  const handleClickPopup = (content: string, category: string) => () => {
    if (category === "EVENT") {
      postMessage({
        command: "WINDOW_OPEN",
        param: {
          url: content,
        },
      });
    } else {
      navigate(`${routes.notice}/${content}`);
    }
  };

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const today = new Date();
    const HOME_VISITED = getLocalStorageItem(
      "homeVisited",
    ) as StorageHomeVisited;
    const handleMainPop = () => {
      if (HOME_VISITED === null) {
        return setIsOpen(true);
      }

      if (!isFetching && data.length !== 0 && HOME_VISITED) {
        const { popupPeriod, noticeId } = HOME_VISITED;
        const fetchedNoticeId = data[0]?.noticeId;
        // 저장한 noticeId가 조회한 fetchedNoticeId와 다르다면 무조건 오픈
        if (noticeId !== fetchedNoticeId) {
          return setIsOpen(true);
        }

        /** 이제부터는 noticeId와 fetchedNoticeId가 같은 케이스 */
        // 다시보지 않기를 했다면
        if (popupPeriod === 0) {
          return;
        }

        const DATE_TYPE_HOME_VISITED = new Date(
          moment().add(popupPeriod, "days").format("YYYY-MM-DD"),
        );

        // 현재 date가 localStorage의 시간보다 크면 return
        if (DATE_TYPE_HOME_VISITED > today) {
          return;
        }
        // 저장된 date가 없거나 today보다 작다면 popup 노출
        if (DATE_TYPE_HOME_VISITED < today) {
          return setIsOpen(true);
        }
      }
    };
    window.setTimeout(handleMainPop, 500);
  }, [setIsOpen, isFetching, data]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.position = "static";
      document.body.style.width = "inherit";
    }
  }, [isOpen]);

  if (!isOpen || data.length === 0) {
    return null;
  }

  const { noticeId, popupPeriod, path, content, category } = data[0];

  return (
    <div className={style.backDimmed}>
      <div className={style.sheetFixer}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            position: "relative",
          }}
        >
          <img
            src={`${S3_BASE_URL}${path}`}
            onClick={handleClickPopup(content, category)}
          />
        </div>
        <div className={style.buttonSection}>
          <div
            style={{
              display: "flex",
              fontSize: "14px",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "50%" }}>
              <span onClick={handlePeriodClose(popupPeriod, noticeId)}>
                {getPoupPeriodText(popupPeriod)}
              </span>
            </div>
            <div style={{ width: "50%", textAlign: "end" }}>
              <span onClick={handleClosePopup}>닫기</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomPopup;
