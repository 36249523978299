// src/utils/initFeeRate.ts
import { getBuySellPolicy } from "@/services/apis";
import { updateFeeRate } from "@/features/fee-rate/feeRateSlice";
import { Dispatch } from "redux";

interface FeeRate {
  configType: string;
  assetType: string;
  feeRate: number;
  priceLimit: number;
}

export const initFeeRate = async (dispatch: Dispatch) => {
  const feeRate: FeeRate[] = [
    { configType: "BUY", assetType: "GOLD", feeRate: 0.02, priceLimit: 0 },
    { configType: "SELL", assetType: "GOLD", feeRate: 0.015, priceLimit: 0 },
    { configType: "BUY", assetType: "SILVER", feeRate: 0.025, priceLimit: 0 },
    { configType: "SELL", assetType: "SILVER", feeRate: 0.025, priceLimit: 0 },
  ];

  const assetTypes = ["GOLD", "SILVER"];
  const configTypes = ["BUY", "SELL"];

  for (let configType of configTypes) {
    for (let assetType of assetTypes) {
      await getBuySellPolicy(configType, assetType).then(
        ({ data: { success, data } }) => {
          if (success) {
            let obj = feeRate.find(
              ({
                configType: defaultConfigType,
                assetType: defaultAssetType,
              }) =>
                defaultConfigType === configType &&
                defaultAssetType === assetType,
            );
            if (obj) {
              obj.feeRate = data.userFeeRate / 100;
              obj.priceLimit = data.priceLimit;
            }
          }
        },
      );
    }
  }
  dispatch(updateFeeRate(feeRate));
};
