import React, { useEffect, useRef } from "react";
import iconLeft from "../../assets/icons/icon_left.png";
import imgSub081 from "../../assets/images/img_sub08_1.png";
import imgSub082 from "../../assets/images/img_sub08_2.png";
import imgSub083 from "../../assets/images/img_sub08_3.png";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { routes, ROLE, mapRouteToRole } from "../../routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { requestNativeLogin } from "../../utils/utils";
import {
  closeBottomSheet,
  openBottomSheet,
} from "@/features/bottom-sheet/bottomSheetSlice";
import ReservationBottomSheet from "./components/ReservationBottomSheet";
import colors from "@assets/styles/colors.css";

const AppraisalAgency = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const location = useLocation();
  // const isInitialMount = useRef(true);
  const {
    role: { LEVEL: userLevel },
  } = useSelector((state: RootState) => state.auth);

  const { LEVEL: pageLevel } = mapRouteToRole[routes.appraisalAgency] || {};

  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //     return;
  //   }

  //   const handlePopState = () => {
  //     if (shouldPreventBackNavigation(location)) {
  //       // 특정 조건을 체크하는 함수
  //       event?.preventDefault();
  //       navigate(routes.sell, {
  //         replace: true,
  //       });
  //     }
  //   };
  //   window.addEventListener("popstate", () => handlePopState());

  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [navigate, location]);

  // const shouldPreventBackNavigation = (location: any) => {
  //   return location.pathname !== routes.sell;
  // };

  const handleClickBookAppraisal =
    (newOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event === undefined ||
        (event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift"))
      ) {
        dispatch(closeBottomSheet());
        return;
      }
      if (userLevel < pageLevel) {
        const needLevel = userLevel + 1;
        switch (needLevel) {
          //로그인 안한 유저
          case ROLE.ROLE_TEMP.LEVEL:
            requestNativeLogin(navigate);
            break;
          default:
            dispatch(openBottomSheet());
        }
      } else {
        dispatch(newOpen ? openBottomSheet() : closeBottomSheet());
      }
    };

  return (
    <div>
      <div className="sub_wrap03">
        <div className="sub_top">
          <div>
            <img
              src={iconLeft}
              alt=""
              className="icon_back"
              onClick={() => navigate(routes.sell, { replace: true })}
            />
            <p>대리점에서 감정받기</p>
          </div>
        </div>
        <div className="agency_top">
          <p>믿을 수 있는 대리점 감정</p>
          <h3>정확한 감정을 받아보세요!</h3>
        </div>
        <div className="shadow88 agency_box">
          <h3 className="box_tit">고객 중심의 판매 방식</h3>
          <p>
            직접 가격을 설정할 수 있는 방법을
            <br />
            제공합니다.
          </p>
          <img src={imgSub083} alt="" />
        </div>
        <div className="shadow88 agency_box">
          <h3 className="box_tit">기준이 정확한 감정</h3>
          <p>
            신뢰할 수 있는 데이터를 기반으로 <br />
            금, 은에 대해 정확한 감정 기준을 가지고 <br />
            감정을 진행합니다.
          </p>
          {/* <span>10초 감정받기</span> */}
          <img src={imgSub082} alt="" />
        </div>
        <div className="shadow88 agency_box">
          <h3 className="box_tit">전국 대리점 네트워크</h3>
          <p>
            전국에 다수의 대리점을 보유하고 있어 <br />
            고객 가장 가까운 곳에서 감정평가를 <br />
            진행할 수 있습니다.
          </p>
          <img src={imgSub081} alt="" />
        </div>
        <div className="sub_btn_wrap">
          <button
            className="sub_wrap02_btn on"
            onClick={handleClickBookAppraisal(true)}
            style={{
              backgroundColor: colors.buttonColor,
            }}
          >
            대리점 예약하기
          </button>
        </div>
        <ReservationBottomSheet />
      </div>
    </div>
  );
};

export default AppraisalAgency;
