import imgShop from "@/assets/images/img_shop.png";
import imgCalendar from "@/assets/images/img_calendar.png";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../app/store";
import * as APIS from "../../../services/apis";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import { formatTime } from "../../../utils/utils";
import { showToast } from "@/features/toast/toastSlice";
import { resetTenSecondAppraisal } from "@/features/appraisal-tensecond/appraisalTenSecondSlice";
import colors from "@assets/styles/colors.css";
import * as style from "../style.css";
/**
 * [화면 종류]
 * 홈
 * 대리점 선택
 * 예약일 선택
 * 본인인증
 * 예약 완료
 */
interface ReservationProps {
  shopId?: number | null;
  shopName?: string | null | undefined;
  distance?: number;
  visitDate?: any;
  acceptTerms?: boolean | null;
}
interface AppraisalHomeProps {
  setProgressScreen: React.Dispatch<
    React.SetStateAction<"home" | "agency" | "date" | "auth" | "completed">
  >;
  reservationData: ReservationProps;
  setReservationData: React.Dispatch<React.SetStateAction<object>>;
  lastPage?: string | null;
}

const AppraisalHome: React.FC<AppraisalHomeProps> = ({
  setProgressScreen,
  reservationData,
  setReservationData,
  lastPage,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const isSocialUser = auth?.isSigned && auth?.role?.LEVEL === 1;

  const handleClickAgencySelect = () => {
    setProgressScreen("agency");
  };
  const handleClickReservationDate = () => {
    if (reservationData?.shopId) {
      setProgressScreen("date");
    }
  };

  const handleClickAgree = (data: boolean) => {
    setReservationData({
      ...reservationData,
      acceptTerms: !data,
    });
  };
  const requestDirectAgency = () => {
    if (!reservationData?.shopId) {
      dispatch(
        showToast({
          message: "의뢰하실 매장을 선택해주세요.",
          icon: "caution",
        }),
      );
    } else if (!reservationData.visitDate) {
      dispatch(
        showToast({ message: "예약일을 선택해주세요.", icon: "caution" }),
      );
    } else if (reservationData?.acceptTerms === false) {
      dispatch(
        showToast({
          message: "서비스 이용 동의 체크 선택해주세요.",
          icon: "caution",
        }),
      );
    } else {
      if (isSocialUser) {
        //본인인증 화면
        setProgressScreen("auth");
      } else {
        APIS.postAppraiseRequest({
          acceptTermsYn: reservationData?.acceptTerms === true ? "Y" : "N",
          method: "DIRECT_VISIT",
          shopId: reservationData?.shopId,
          userId: userData.id,
          visitDate: reservationData?.visitDate,
        }).then(({ data: { success, data, message } }) => {
          if (!success) {
            dispatch(showToast({ message, icon: "error" }));
          } else {
            if (lastPage !== undefined) {
              dispatch(resetTenSecondAppraisal());
            }
            navigate(routes.appraisalReservationComplete);
          }
        });
      }
    }
  };
  return (
    <div className="sub_bottom rv_tab">
      <div className="sub_bottom_wrap">
        <div className="sub_top_fix">
          <div className="sub_top01">
            <h3 className="sub_bottom_tit">대리점 예약하기</h3>
          </div>
        </div>
        <div className="bottom_box_wrap">
          <div
            className={`${
              reservationData.shopId === null
                ? "shadow88 bottom_box checked"
                : "shadow88 bottom_box"
            }`}
            onClick={handleClickAgencySelect}
          >
            <div className="box_tit">
              <div className="box_img">
                <img src={imgShop} />
              </div>
              {reservationData.shopId === null ? (
                <h3> 대리점 선택</h3>
              ) : (
                <div className="box_cont">
                  <h3>{reservationData.shopName}</h3>
                  <p>
                    <span className="spoqa">{reservationData.distance}</span>km
                  </p>
                  <span>다른 대리점 찾기</span>
                </div>
              )}
            </div>
          </div>
          <div
            className={`shadow88 bottom_box ${
              reservationData.shopId === null ? "disabled" : "checked"
            } `}
            onClick={handleClickReservationDate}
          >
            <div className="box_tit">
              <div className="box_img">
                <img src={imgCalendar} />
              </div>
              {reservationData.visitDate === null ? (
                <h3> 예약일 선택</h3>
              ) : (
                <div className="box_cont">
                  <h3>예약일 선택</h3>
                  {reservationData.visitDate ? (
                    <p className="date">
                      <span className="spoqa">
                        {formatTime(reservationData.visitDate, "YYYY.MM.DD")}
                      </span>
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="agency_accordion agency_vertical">
          <ul>
            <li>
              <input
                type="checkbox"
                id="checkbox-2"
                name="checkbox-accordion"
              />
              <label htmlFor="checkbox-2">
                <div className="agency_check">
                  <input
                    type="checkbox"
                    id="check2"
                    checked={!!reservationData?.acceptTerms}
                    onChange={() =>
                      handleClickAgree(!!reservationData?.acceptTerms)
                    }
                  />
                  <label htmlFor="check2">
                    <h3>
                      <span>[필수]</span> 감정평가 서비스 이용 동의
                    </h3>
                  </label>
                </div>
                <div className="btn_cx_down"></div>
                <div className="btn_cx_up"></div>
              </label>
              <div className="agency_content">
                <ul>
                  <li>서비스 이용 약관에 동의합니다.</li>
                  <li>
                    제품에 포함된 각종 보석류 및 세팅 등은 가치를 산정하지
                    않습니다.
                  </li>
                  <li>
                    상세 감정 과정의 특성상 제품에 흠집 또는 손상이 갈 수
                    있습니다.
                  </li>
                  <li>
                    상세 감정 과정의 특성상 제품에 흠집 또는 손상이 갈 수
                    있습니다.
                  </li>
                  <li>상세 감정 결과는 감정 결과와 다를 수 있습니다.</li>
                  <li>
                    상세 감정 결과에 따라 금/은 제품을 보유 금/은(g) 자산으로
                    교환할 수 있습니다.
                  </li>
                  <li>교환이 완료된 경우 거래의 취소가 불가합니다.</li>
                  <li>
                    교환이 완료된 제품은 원재료 형태로 정련되어 안전하게
                    보관됩니다.
                  </li>
                  <li>
                    교환이 완료된 제품은 실물 인출 형태로 반환 신청을 할 수
                    있습니다.
                  </li>
                  <li>
                    회원의 서비스 이용 내용은 서비스 고도화를 위한 데이터로
                    활용될 수 있습니다.
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div className={`sub_btn_wrap ${style.absolute}`}>
          <button
            className={`sub_wrap02_btn ${
              reservationData.acceptTerms === true &&
              reservationData.shopId &&
              reservationData.visitDate &&
              "on"
            } `}
            onClick={() => requestDirectAgency()}
            style={{
              backgroundColor:
                reservationData.acceptTerms === true &&
                reservationData.shopId &&
                reservationData.visitDate &&
                colors.buttonColor,
            }}
          >
            감정평가 예약하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppraisalHome;
