import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "@/services/storageService";
import { useEffect, useLayoutEffect, useState } from "react";

export interface SearchObject {
  searchInput: string;
  step: "READY" | "SUCCESS" | "FAILED";
  lastSearched: string;
  searchHistory: string[];
}
type InputHanlderTypeParameter = "WORD" | "ALL";

export const useSearch = () => {
  const [searchObject, setSearchObject] = useState<SearchObject>({
    searchInput: "",
    lastSearched: "",
    step: "READY",
    searchHistory: [],
  });

  /** 첫 로드시 검색 내역 장착 */
  useLayoutEffect(() => {
    const recentKeywords = getLocalStorageItem("keyword") || [];
    setSearchObject((prev) => ({ ...prev, searchHistory: recentKeywords }));
  }, [setSearchObject]);

  useEffect(() => {
    if (searchObject.step !== "READY") {
      const newHistory = searchObject.searchHistory;
      if (newHistory.length !== 0) {
        setLocalStorageItem("keyword", newHistory);
      }
    }
  }, [searchObject.searchHistory, searchObject.step]);

  const hanldeSearchInput = (
    incomeInput: string,
    type: InputHanlderTypeParameter,
  ) => {
    if (type === "ALL") {
      return setSearchObject((prev) => ({
        ...prev,
        searchInput: "",
        step: "READY",
      }));
    }
    if (incomeInput.length === 0) {
      setSearchObject((prev) => ({
        ...prev,
        searchInput: incomeInput,
        step: "READY",
      }));
    } else {
      setSearchObject((prev) => ({ ...prev, searchInput: incomeInput }));
    }
  };

  const deleteHistory = (
    type: InputHanlderTypeParameter,
    clickKeyword?: string,
  ) => {
    if (type === "ALL") {
      setLocalStorageItem("keyword", []);
      setSearchObject((prev) => ({ ...prev, searchHistory: [] }));
    } else {
      setSearchObject((prev) => {
        const filteredHistory = prev.searchHistory.filter(
          (historyItem) => historyItem !== clickKeyword,
        );
        setLocalStorageItem("keyword", filteredHistory);
        return { ...prev, searchHistory: filteredHistory };
      });
    }
  };

  return {
    searchObject,
    setSearchObject,
    hanldeSearchInput,
    deleteHistory,
  } as const;
};
