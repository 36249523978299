import { useEffect } from "react";
import IconLeft from "@assets/icons/icon_left.png";
import ImgSecurity from "@assets/images/img_security2.png";
import { useLocation, useNavigate } from "react-router-dom";
import * as APIS from "@/services/apis";
import { saveSignupStep } from "@/utils/sign";
import { routes } from "@/routes";
import { postMessage } from "@/utils/utils";
import colors from "@assets/styles/colors.css";
const isDev = import.meta.env.VITE_SERVICE_TYPE === "DEVELOPMENT";
var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const SelfVerification = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { purpose = "SIGNUP" } = state || {};

  useEffect(() => {
    if (purpose === "SIGNUP") saveSignupStep("SELF_VERIFICATION");
  }, []);

  const handleClickVerification = () => {
    APIS.getSelfVerificationRequestId(purpose).then(
      ({ data: { requestId } }) => {
        if (isDev && !isMobile) {
          window.location.href = `${
            import.meta.env.VITE_API_URL
          }/api/v2/user/verification/self/start?requestId=${requestId}`;
          postMessage({
            command: "WINDOW_OPEN",
            param: {
              visible: true,
              title: "본인인증",
              url: `${
                import.meta.env.VITE_API_URL
              }/api/v2/user/verification/self/start?requestId=${requestId}`,
            },
          });
        } else {
          postMessage({
            command: "TAB_OPEN",
            param: {
              url: `${
                import.meta.env.VITE_API_URL
              }/api/v2/user/verification/self/start?requestId=${requestId}`,
              gateway: routes.authGateway,
            },
          });
          navigate(-1);
        }
      },
    );
  };

  const getHeaderText = () => {
    switch (purpose) {
      case "UPDATE_SECURE_PASSWORD":
        return "결제 비밀번호 변경";
      case "UPDATE_PHONENUMBER":
        return "연락처 변경";
      default:
        return "";
    }
  };

  const renderContent = () => {
    switch (purpose) {
      case "UPDATE_SECURE_PASSWORD":
        return (
          <div className="sub03_wrap">
            <div className="passbook_top">
              <img src={ImgSecurity} alt="security" />
              <h3>
                결제 비밀번호 변경을 위해
                <br />
                <span className="fc-bl03">본인 인증</span>이 필요해요.
              </h3>
            </div>
          </div>
        );
      case "UPDATE_PHONENUMBER":
        return (
          <div className="sub03_wrap">
            <div className="passbook_top">
              <img src={ImgSecurity} alt="security" />
              <h3>
                휴대폰 번호 변경을 위해
                <br />
                <span className="fc-bl03">본인 인증</span>이 필요해요.
              </h3>
            </div>
          </div>
        );
      default:
        return (
          <div className="sub03_wrap">
            <div className="passbook_top">
              <img src={ImgSecurity} alt="security" />
              <h3>
                안전한 거래를 위해
                <br />
                <span className="fc-bl03">본인 인증</span>이 필요해요.
              </h3>
            </div>
          </div>
        );
    }
  };
  return (
    <div>
      <div className="sub_top">
        <div>
          <img
            src={IconLeft}
            alt=""
            className="icon_back"
            onClick={() => navigate(-1)}
          />
          <p>{getHeaderText()}</p>
        </div>
      </div>
      {renderContent()}
      <div className="sub_btn_wrap">
        <button
          className="sub_wrap02_btn on"
          onClick={handleClickVerification}
          style={{
            backgroundColor: colors.buttonColor,
          }}
        >
          본인 인증 하기
        </button>
      </div>
    </div>
  );
};

export default SelfVerification;
