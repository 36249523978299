import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "@/routes";
import IconLeft from "@assets/icons/icon_left.png";
import { BottomSheet } from "@components/index";
import { useDispatch } from "react-redux";
import { openBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";
import SelectRegion from "./components/SelectRegion";
import SelectAgency from "./components/SelectAgency";
import * as APIS from "@/services/apis";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { updateSignUp } from "@/features/auth/signUpSlice";

const AuthRegisterShop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sheetType, setSheetType] = useState("");
  const [cityList, setCityList] = useState([]);
  const { shopCity } = useSelector((state: RootState) => state.signUp);

  useEffect(() => {
    getCity(0); // 최초 city list 조회의 id 값 0
  }, []);

  useEffect(() => {
    if (sheetType === "NONE") {
      dispatch(updateSignUp({ recommendShop: null }));
      navigate(routes.authRegisterPassword, { replace: true });
    }
  }, [sheetType]);

  const getCity = (cityId: number) => {
    APIS.getShopCity(cityId).then(({ data: { success, data = "" } }) => {
      if (success) {
        setSheetType(data?.cityShopType);
        setCityList(data?.idNameList);
      }
    });
  };

  const handleOpenBottomSheet = () => {
    dispatch(openBottomSheet());
  };

  return (
    <div>
      <div className="sub_top03">
        <div>
          <img
            src={IconLeft}
            alt=""
            className="icon_back"
            onClick={() => {
              navigate(routes.authRegisterEmail, { replace: true });
            }}
          />
          <p>추천 대리점 선택</p>
        </div>
      </div>
      <div className="sub_wrap08_2">
        <h3 className="sub_wrap08_2_tt">
          <span className="fc-bl03">이용할 대리점의 지역</span>을 선택해주세요.
        </h3>
        <div className="sell_sub_wrap01 change" onClick={handleOpenBottomSheet}>
          <ul>
            <li className="op_sel checked">
              <p>시/도</p>
              <h3>선택해주세요.</h3>
            </li>
          </ul>
        </div>
      </div>
      <BottomSheet>
        {sheetType === "CITY" ? (
          <SelectRegion
            cityList={cityList}
            getCity={getCity}
            initCity={shopCity.id === 0}
          />
        ) : sheetType === "SHOP" ? (
          <SelectAgency cityList={cityList} getCity={getCity} />
        ) : (
          <></>
        )}
      </BottomSheet>
    </div>
  );
};

export default AuthRegisterShop;
