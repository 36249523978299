import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WaitList from "./components/WaitList";
import useInterval from "../../hooks/useInterval";
import * as APIS from "../../services/apis";
import Header from "./components/Header";
import OrderList from "./components/OrderList";
import OrderInput from "./components/OrderInput";
import { selectFeeRate } from "../../features/fee-rate/feeRateSlice";
import { RootState } from "../../app/store";
import { ROLE, routes } from "../../routes";
import { initFeeRate } from "@/utils/initFeeRate";
import { useLocation, useNavigate } from "react-router";
import { requestNativeLogin } from "@/utils/utils";
import { getLocalStorageItem } from "@/services/storageService";
interface MarketData {
  price: number;
  sumPrice: number;
  sumper: string;
  updown: string;
  color: string;
}

const TradingWindow: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    role: { LEVEL: userLevel },
  } = useSelector((state: RootState) => state.auth);
  const localAssetType = getLocalStorageItem("tradingAssetType");
  const contentRef = useRef(null);
  const isInitialRender = useRef(true); // 첫 번째 렌더링 여부를 확인하는 useRef
  const [contentHeight, setContentHeight] = useState(0);
  const [tradeType, setTradeType] = useState(state?.tradeType || "SELL");
  const [assetType, setAssetType] = useState(localAssetType || "GOLD");
  const [orderType, setOrderType] = useState("LIMITS");
  const [marketPrice, setMarketPrice] = useState<MarketData | null>(null); //현재가
  const [minPrice, setMinPrice] = useState(0); // 하한가
  const [maxPrice, setMaxPrice] = useState(0); // 상한가
  const [lastPrice, setLastPrice] = useState(0); // 전일 종가
  const { feeRate } = useSelector(selectFeeRate);
  const [trade, settrade] = useState({ orderPrice: 0, remainGram: 0 }); // 현재가
  const [tradeBuy, settradeBuy] = useState<Array<any>>([]); //매수매도 리스트
  const [appState, setAppState] = useState("active");
  const [posibleprice, setposibleprice] = useState(0);
  const [inputs, setInputs] = useState({
    price: "",
    amount: "",
  });

  const handleClickTradeType = (type: string) => {
    setTradeType(type);
  };

  const init = () => {
    if (userLevel >= ROLE.ROLE_SELF_VERIFICATION.LEVEL) {
      APIS.getAvailableAmount().then(({ data: { data } }) => {
        const { gold = 0, silver = 0, krw = 0, point = 0 } = data || {};
        if (tradeType === "SELL") {
          if (assetType === "GOLD") {
            setposibleprice(gold);
          } else {
            setposibleprice(silver);
          }
        } else {
          setposibleprice(krw + point);
        }
      });
    }
    getMinMax();
  };

  const getMinMax = async () => {
    // 하한가(매수), 상한가(매도) 설정
    let buyPrice = feeRate.find(
      (item) => item.configType === "BUY" && item.assetType === assetType,
    )?.priceLimit;
    let sellPrice = feeRate.find(
      (item) => item.configType === "SELL" && item.assetType === assetType,
    )?.priceLimit;

    setMinPrice(buyPrice || 0);
    setMaxPrice(sellPrice || 0);
  };

  const fetchPendingSummary = () => {
    APIS.getPendingSummary(assetType).then(({ data: res }) => {
      //res.data[0]은 무조건 tradeType이 null인 현재가가 온다.
      const sortedTradeBuy = [...res.data].sort(
        (a, b) => b.orderPrice - a.orderPrice,
      );
      settradeBuy(sortedTradeBuy);
      settrade(res.data[0]); // 현재가
      setLastPrice(res.data[0]["remainGram"]);
    });
  };

  const authBlock = () => {
    // 로그아웃 상태 -> 로그인 필요 팝업
    // SNS 로그인 상태 -> 본인인증 페이지 이동
    if (userLevel < ROLE.ROLE_TEMP.LEVEL) {
      // 로그아웃 상태
      requestNativeLogin(navigate);
      return false;
    } else if (userLevel < ROLE.ROLE_SELF_VERIFICATION.LEVEL) {
      // SNS 로그인 상태
      navigate(routes.selfVerification, {
        state: {
          purpose: "SIGNUP",
        },
      });
      return false;
    }
    return true;
  };

  const listener = (event: any) => {
    if (typeof event.data === "string") {
      const { command, data } = JSON.parse(event.data || "{}");
      if (command === "APP_STATE") {
        setAppState(data);
      }
    }
  };

  const resetInputs = () => {
    setInputs({
      amount: "",
      price: "",
    });
  };

  useInterval(fetchPendingSummary, appState === "active" ? 2000 : null);

  useEffect(() => {
    init();
  }, [userLevel, assetType, tradeType]);

  useEffect(() => {
    fetchPendingSummary();
    resetInputs();
    isInitialRender.current = true;
  }, [assetType]);

  useEffect(() => {
    const { orderPrice = 0, remainGram = 0 } = trade || {};
    if (isInitialRender.current && orderPrice !== 0) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        price: `${orderPrice}`, // trade.orderPrice를 문자열로 변환하여 설정
      }));
      isInitialRender.current = false; // 첫 번째 렌더링 이후에 false로 설정
    }
    let sumPrice = orderPrice * 1 - remainGram * 1;
    let sumper = (sumPrice / (orderPrice * 1)) * 100;
    if (sumper < 0) {
      sumper = sumper * -1;
    }
    let updown = "";
    let color = "";
    let marketData: MarketData | null = null;

    if (sumPrice > 0) {
      updown = "up";
      color = "#eb0c0c";
      marketData = {
        price: orderPrice * 1,
        sumPrice: updown === "up" ? sumPrice : sumPrice * -1,
        sumper: sumper.toFixed(2),
        updown: updown,
        color: color,
      };
      setMarketPrice(marketData);
    } else if (sumPrice < 0) {
      updown = "down";
      color = "#2476c2";
      marketData = {
        price: orderPrice,
        sumPrice: updown === "up" ? sumPrice : sumPrice * -1,
        sumper: sumper.toFixed(2),
        updown: updown,
        color: color,
      };
      setMarketPrice(marketData);
    } else {
      updown = "same";
      color = "#2476c2";
      marketData = {
        price: orderPrice,
        sumPrice: updown === "up" ? sumPrice : sumPrice * -1,
        sumper: sumper.toFixed(2),
        updown: updown,
        color: color,
      };
      setMarketPrice(marketData);
    }
  }, [trade]);

  useEffect(() => {
    initFeeRate(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const updateContentHeight = () => {
      if (contentRef.current) {
        setContentHeight((contentRef.current as HTMLElement).offsetHeight);
      }
    };
    updateContentHeight();

    // 앱 백그라운드 상태에서 API 호출 중지를 위한 리스너
    document.addEventListener("message", listener);
    window.addEventListener("message", listener);
    return () => {
      document.removeEventListener("message", listener);
      window.removeEventListener("message", listener);
    };
  }, []);

  return (
    <div>
      <div className="sub_top3">
        <Header
          assetType={assetType}
          setAssetType={setAssetType}
          marketPrice={marketPrice}
          tradeType={tradeType}
          isFromMenu={state?.isFromMenu}
        />
        <div className="sell_wrap07_top">
          <div className="sell_wrap07_toptb">
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>가격(원)</p>
                  </td>
                  <td>
                    <p>중량</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <ul className="sell_wrap07_topul">
            <li
              className={`tab01 ${tradeType === "SELL" && "on"}`}
              onClick={() => handleClickTradeType("SELL")}
            >
              <p>팔래요</p>
            </li>
            <li
              className={`tab02 ${tradeType === "BUY" && "on"}`}
              onClick={() => handleClickTradeType("BUY")}
            >
              <p>살래요</p>
            </li>
            <li
              className={`tab03 ${tradeType === "WAIT" && "on"}`}
              onClick={() => {
                if (authBlock()) {
                  handleClickTradeType("WAIT");
                }
              }}
            >
              <p>대기</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="sub_wrap04">
        <div className="sell_wrap07 sell_wrap00" ref={contentRef}>
          {!!contentHeight && (
            <OrderList
              marketPrice={marketPrice}
              minPrice={minPrice}
              maxPrice={maxPrice}
              height={contentHeight}
              tradeBuy={tradeBuy}
              lastPrice={lastPrice}
              inputs={inputs}
              setInputs={setInputs}
            />
          )}
          {tradeType !== "WAIT" ? (
            <OrderInput
              marketPrice={marketPrice}
              minPrice={minPrice}
              maxPrice={maxPrice}
              assetType={assetType}
              tradeType={tradeType}
              posibleprice={posibleprice}
              inputs={inputs}
              setInputs={setInputs}
              orderType={orderType}
              setOrderType={setOrderType}
              authBlock={authBlock}
            />
          ) : (
            <WaitList assetType={assetType} orderType={orderType} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TradingWindow;
