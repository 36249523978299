import React from "react";
import styled from "@emotion/styled";

type Props = {};

function ProductGrade(props: any) {
  return (
    <Rating {...props}>
      <div
        vreview-product-id={props?.id}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onTouchStart={(e) => {
          e.stopPropagation();
        }}
      ></div>
    </Rating>
  );
}

const Rating = styled.div`
  .vreview-review-summary {
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #eaeaea;
    background: rgba(255, 255, 255, 0);
    padding: 2px 8px;
    /* width: fit-content; */
    /* height: 20px; */
    /* margin-right: 4px; */
    /* line-height: 20px; */
    color: #777;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-right: 4px;
  }

  .vreview-board-popup {
    margin-top: unset !important;
  }
  .vreview-rating {
    margin-top: 0px !important;
    width: 12px;
    height: 12px;
  }
`;

export default ProductGrade;
