import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { InitialState, GoldPrice, SilverPrice } from "../../model/marketPrice";

const initialState: InitialState = {
  updatedAt: "",
  gold: { current: 0, closing: 0 },
  silver: { current: 0, closing: 0 },
};

export const marketPriceSlice = createSlice({
  name: "marketPrice",
  initialState,
  reducers: {
    updateMarketPrice: (
      state,
      action: PayloadAction<{
        updatedAt: Date;
        gold: GoldPrice;
        silver: SilverPrice;
      }>,
    ) => {
      state.updatedAt = action.payload.updatedAt.toISOString();
      state.gold = { ...action.payload.gold };
      state.silver = { ...action.payload.silver };
    },
  },
});

export const { updateMarketPrice } = marketPriceSlice.actions;
export const selectMarketPrice = (state: RootState) => state.marketPrice;

export default marketPriceSlice.reducer;
