import React from "react";
import iconSell0301 from "@assets/icons/icon_sell03_01.png";
import iconSell0302 from "@assets/icons/icon_sell03_02.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";

const Appraisal = () => {
  const navigate = useNavigate();
  const {
    role: { LEVEL: userLevel },
    isSigned,
  } = useSelector((state: RootState) => state.auth);
  const isSelfVerification = isSigned && userLevel === 1;

  return (
    <div className="sell_main sell_main03 shadow88">
      <div className="sell_main03_top">
        <div className="sell_main03_txt">
          <h3>감정평가</h3>
          <p>내 자산(금/은) 얼마일까요?</p>
        </div>
        {isSigned && !isSelfVerification && (
          <span
            className="sell_main03_btn"
            onClick={() => {
              navigate(
                `${routes.tradeHistory}/?tradeType=APPRAISAL&statusType=ING`,
              );
            }}
          >
            예약 내역
          </span>
        )}
      </div>
      <div className="sell_main03_list">
        <ul>
          <li onClick={() => navigate(routes.appraisalAgency)}>
            <img src={iconSell0301} alt="" className="icon_sell_main03" />
            대리점에서 감정받기
          </li>
          <li onClick={() => navigate(routes.appraisalSelectAsset)}>
            <img src={iconSell0302} alt="" className="icon_sell_main03" />
            10초 감정하기
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Appraisal;
