import HamburgerMenu from "@pages/home/components/HamburgerMenu";

const Header = () => {
  return (
    <header className="sub_header">
      <HamburgerMenu />
    </header>
  );
};

export default Header;
