import { MouseEventHandler } from "react";

import listNoneEventImage from "@/assets/images/list_event_none.png";
import constants from "@/utils/constants";
import { formatTime } from "@/utils/utils";
import { EventItemType } from "./hooks/useEventList";

type Props = {
  key: number;
  item: EventItemType;
  type: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export default function EventItem({ key, item, type, onClick }: Props) {
  const { serviceImages = [] } = item;
  const imageUrl = serviceImages.find((item) => item.imageType === "THUMBNAIL");

  return (
    <div
      className="ft_wrap_box"
      key={`${type} ${key}`}
      onClick={onClick ? onClick : () => {}}
    >
      <div className="ft_wrap_img">
        <img
          src={
            serviceImages.length > 0 && imageUrl
              ? `${constants.s3BaseUrl}${imageUrl.path}`
              : listNoneEventImage
          }
          alt="이벤트이미지"
          className="ft_event_img"
          style={{ borderRadius: 6, maxHeight: "120px" }}
        />
        {type === "closed" ? (
          <>
            <div className="ft_event_img_bg"></div>
            <div className="ft_into_tit">종료</div>
          </>
        ) : null}
      </div>
      <div className="ft_box_text">
        <div className="ft_body lg">
          {item.subject}
          <div className="ft_spo date">
            {formatTime(new Date(item?.startDate))} ~{" "}
            {item?.endDate
              ? formatTime(new Date(item?.endDate))
              : "별도 공지 시까지"}
          </div>
        </div>
      </div>
    </div>
  );
}
