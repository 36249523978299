import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { InitialState } from "../../model/bottomSheet";

const initialState: InitialState = {
  isOpen: false,
};

export const BottomSheetSlice = createSlice({
  name: "bottomSheet",
  initialState,
  reducers: {
    // 바텀시트 열기
    // openBottomSheet: (state, action: PayloadAction<{ data?: any }>) => {
    openBottomSheet: (state) => {
      state.isOpen = true;
      // state.data = action.payload?.data;
    },
    // 바텀시트 닫기
    closeBottomSheet: (state) => {
      state.isOpen = false;
    },
  },
});

export const { openBottomSheet, closeBottomSheet } = BottomSheetSlice.actions;
export const selectBottomSheet = (state: RootState) => state.bottomSheet;

export default BottomSheetSlice.reducer;
