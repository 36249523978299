import { useNavigate } from "react-router-dom";
import DetailHeader from "@components/layouts/DetailHeader";

function ReviewWidget() {
  const navigate = useNavigate();
  return (
    <>
      <DetailHeader title="상품 리뷰" backCallback={() => navigate(-1)} />
      <div className="mypage">
        <div className="vreview-myreview-widget"></div>
      </div>
      <div style={{ height: "30px" }} />
    </>
  );
}

export default ReviewWidget;
