import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PhoneNumber {
  prefix: string;
  part1: string;
  part2: string;
}

export interface DeliveryInfo {
  postCode: string;
  address: string;
  addressDetail: string;
  addressName: string;
  receiver: string;
  phone: PhoneNumber;
  mobile: PhoneNumber;
  memo: string;
  isDefault: boolean;
  editId: number;
}

export interface IPurchaseInfo {
  useGram: number;
  useKrw: number;
  usePoint: number;
  optionValue: string;
  qty: number;
  visitDate: string;
  shopId: number;
  buyPrice: number;
  sellingPrice: number;
  standardPrice: number;
  depositPrice: number;
  depositMethod: string;
  finPassword: string;
  tossPaymentKey: string;
  tossOrderId: string;
  goodsDisplayMode: string;
  isDelivery: boolean;

  // UI에 사용됨.
  shopName: string;
  shopDistance: number;
  isUseKrw: boolean;
  orderName: string;
  goodsId: string;
  postCode: string;
  address: string;
  addressDetail: string;
  addressName: string;
  receiver: string;
  phone: PhoneNumber;
  mobile: PhoneNumber;
  memo: string;
  customMemo: string;
  isDefault: boolean;
  editId: number;
  initialDelivery: boolean | null;
  isEdit: boolean;
}

export const initialDeliveryInfo = {
  // 택배 배송지 정보
  postCode: "", // 우편번호
  address: "", // 주소
  addressDetail: "", // 상세주소
  addressName: "", // 배송지명
  receiver: "", // 수령인
  phone: { prefix: "", part1: "", part2: "" }, // 연락처1
  mobile: { prefix: "", part1: "", part2: "" }, // 연락처2
  memo: "", // 수령메모
  customMemo: "", // 메모 직접 입력
  isDefault: false, // 기본 배송지로 추가
  editId: 0, // 배송지 ID
};

const initialState: IPurchaseInfo = {
  // 실제 결제 정보
  useGram: 0, // 사용할 자산 중량
  useKrw: 0, // 사용할 현금
  usePoint: 0, // 사용할 포인트
  optionValue: "", // 선택한 옵션
  qty: 1, // 수량
  visitDate: "", // 방문 날짜
  shopId: 0, // 방문할 대리점 ID
  buyPrice: 0, // 사용 중량이 0그램일 때, 시장 매수 거래 금액 + 수수료
  sellingPrice: 0, // 상품 현재가
  standardPrice: 0, // 상품 기준가
  depositPrice: 0, // 결제 예상 금액
  depositMethod: "", // 결제 방법
  finPassword: "", // 헥토 결제 시, 비밀 번호
  tossPaymentKey: "", // 토스 paymentKey
  tossOrderId: "", // 토스 주문 Id
  goodsDisplayMode: "APP",

  // 토스 결제에 사용
  orderName: "", // 상품명
  goodsId: "", // 상품 Id

  isDelivery: true, // 대리점 방문, 택배 수령 여부

  // 택배 배송지 정보
  postCode: "", // 우편번호
  address: "", // 주소
  addressDetail: "", // 상세주소
  addressName: "", // 배송지명
  receiver: "", // 수령인
  phone: { prefix: "", part1: "", part2: "" }, // 연락처1
  mobile: { prefix: "", part1: "", part2: "" }, // 연락처2
  memo: "", // 수령메모
  customMemo: "", // 메모 직접 입력
  isDefault: false, // 기본 배송지로 추가
  editId: 0, // 배송지 ID

  /**
   * UI에 필요한 전역상태: 대리점 정보
   */
  shopName: "", // 대리점 이름
  shopDistance: 0, // 대리점 거리
  isUseKrw: true, // 현금 사용 여부
  initialDelivery: null, // 기존 배송 정보
  isEdit: false, // 수령방법 수정 여부
};

export const buySlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    setPurchaseInfo: (state, action: PayloadAction<Partial<IPurchaseInfo>>) => {
      Object.assign(state, action.payload);
    },
    resetPurchaseInfo: () => initialState,
    resetDeliveryInfo: (state) => {
      Object.assign(state, initialDeliveryInfo);
    },
  },
});

export const { setPurchaseInfo, resetPurchaseInfo, resetDeliveryInfo } =
  buySlice.actions;
export const selectPurchaseInfo = (state: any) => state.purchase;

export default buySlice.reducer;
