import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import arrowD from "@assets/icons/arrow_d.png";
import arrowD02 from "@assets/icons/arrow_d02.png";
import iconNotiBlue from "@assets/icons/ico_noti_blue02.png";
import iconCheckBlue from "@assets/icons/ico_check_14px.png";
import imgCheck from "@assets/icons/img_check.png";
import iconX14 from "@assets/icons/btn_x_14.png";
import * as styles from "./style.css";
import { RootState } from "@/app/store";
import { formatPhoneNumber } from "@/utils/utils";
import {
  PHONE_CODE,
  MEMO_LIST,
  ADDRESS_NAME_LIST,
  CUSTOM_AVAILABLE_MEMO,
} from "@/utils/constants";
import {
  setPurchaseInfo,
  IPurchaseInfo,
} from "@/features/purchase/purchaseSlice";
import { routes } from "@/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "@/features/toast/toastSlice";

interface PhoneNumber {
  prefix: string;
  part1: string;
  part2: string;
}

interface DeliveryCardProps {
  deliveryInfo: {
    postCode: string;
    address: string;
    addressDetail: string;
    addressName: string;
    receiver: string;
    phone: PhoneNumber;
    mobile: PhoneNumber;
    memo: string;
    isDefault: boolean;
  };
  onEdit?: () => void;
  onDelete?: () => void;
  canModifyDelivery?: boolean;
}

export const DeliveryCard: React.FC<DeliveryCardProps> = ({
  deliveryInfo,
  onEdit,
  onDelete,
  canModifyDelivery = true,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const isAddressManagement = currentPath === routes.deliveryAddressManagement;
  const showSelectButton = location.state?.showSelectButton;

  const handleClickAddressManagement = () => {
    dispatch(setPurchaseInfo({ isEdit: true }));
    navigate(routes.deliveryAddressManagement, {
      state: { showSelectButton: true },
    });
  };

  const handleSelectAddress = () => {
    dispatch(setPurchaseInfo({ ...deliveryInfo }));
    dispatch(
      showToast({ message: "배송지가 선택되었습니다.", icon: "success" }),
    );
    navigate(-1);
  };

  return (
    <div className={`shadow88 mb16 ${styles.shoppingBox}`}>
      {!isAddressManagement && <h3 className="shadow88_tit fc-bk01">배송지</h3>}
      <div className={styles.contBoxUl}>
        <h3 className={styles.contBoxUlTit}>
          {deliveryInfo.receiver}{" "}
          {deliveryInfo.addressName ? `/ ${deliveryInfo.addressName}` : ""}
          {deliveryInfo.isDefault ? (
            <span className={styles.contBoxUlTitSpan}>기본</span>
          ) : null}
        </h3>
        {isAddressManagement ? (
          <img
            src={iconX14}
            alt="삭제"
            className={`${styles.deleteBtn}`}
            onClick={onDelete}
          />
        ) : (
          canModifyDelivery && (
            <button
              className={`${styles.contBoxUlButton}`}
              onClick={handleClickAddressManagement}
            >
              변경
            </button>
          )
        )}
        <div className={styles.contBoxList}>
          <ul className={styles.flexColumn}>
            <li className={styles.contBoxListLi}>
              <p className={styles.contBoxListP}>
                {formatPhoneNumber(deliveryInfo.phone)}
              </p>
            </li>
            <li className={styles.contBoxListLi}>
              <p className={styles.contBoxListP}>
                {`(${deliveryInfo.postCode}) ${deliveryInfo.address} ${deliveryInfo.addressDetail}`}
              </p>
            </li>
            {deliveryInfo.memo ? (
              <li className={styles.contBoxListLi}>
                <p className={styles.contBoxListP}>
                  수령방법: {deliveryInfo.memo}
                </p>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
      {isAddressManagement && (
        <div className={styles.btnWrap}>
          <button className={styles.changeBtn} onClick={onEdit}>
            수정
          </button>
          {showSelectButton && (
            <button
              className={`${styles.changeBtn} ${styles.blueChangeBtn}`}
              onClick={handleSelectAddress}
            >
              선택
            </button>
          )}{" "}
        </div>
      )}
    </div>
  );
};

interface DeliveryFormProps {
  handleOpenBottomSheet: (type: string) => void;
  initialState?: Partial<IPurchaseInfo>; // 배송지 수정 시 초기 상태 값
  editMode?: boolean; // 신규 등록(false) or 수정(true)
  onSubmit?: (formState: Partial<IPurchaseInfo>) => void;
  onChangeFormState?: (formState: Partial<IPurchaseInfo>) => void;
}
export const initialDeliveryInfo = {
  // 택배 배송지 정보
  postCode: "", // 우편번호
  address: "", // 주소
  addressDetail: "", // 상세주소
  addressName: "", // 배송지명
  receiver: "", // 수령인
  phone: { prefix: "", part1: "", part2: "" }, // 연락처1
  mobile: { prefix: "", part1: "", part2: "" }, // 연락처2
  memo: "", // 수령메모
  customMemo: "", // 메모 직접 입력
  isDefault: false, // 기본 배송지로 추가
  editId: 0, // 배송지 ID
};

export const DeliveryForm: React.FC<DeliveryFormProps> = ({
  handleOpenBottomSheet,
  initialState = initialDeliveryInfo,
  editMode = false,
  onSubmit,
  onChangeFormState,
}) => {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState(initialState);
  const [initialDefault, setInitialDefault] = useState<boolean>(
    initialState.isDefault || false,
  );

  const currentPath = location.pathname;
  const isAddressManagement = currentPath === routes.deliveryAddressManagement;

  const currentState = isAddressManagement
    ? formState
    : useSelector((state: RootState) => state.purchase);

  const setCurrentState = (updatedState: Partial<IPurchaseInfo>) => {
    if (isAddressManagement) {
      setFormState((prevState) => ({
        ...prevState,
        ...updatedState,
        isDefault: initialDefault
          ? prevState.isDefault
          : updatedState.isDefault, // initialDefault가 true인 경우 기존 값을 유지
      }));
      onChangeFormState?.({
        ...formState,
        ...updatedState,
        isDefault: initialDefault
          ? formState.isDefault
          : updatedState.isDefault,
      });
    } else {
      dispatch(setPurchaseInfo(updatedState));
    }
  };

  useEffect(() => {
    if (isAddressManagement) {
      setFormState((prevState) => ({
        ...prevState,
        ...initialState,
        isDefault: initialDefault
          ? prevState.isDefault
          : initialState.isDefault, // initialDefault가 true일 경우 기존 값을 유지
      }));
    }
  }, [initialState, isAddressManagement]);

  useEffect(() => {
    if (customMemoRef.current) {
      customMemoRef.current.style.height = "auto";
      customMemoRef.current.style.height = `${Math.min(
        customMemoRef.current.scrollHeight,
        50,
      )}px`;
    }
  }, [currentState.customMemo]);

  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const customMemoRef = useRef<HTMLTextAreaElement>(null);

  const handlePrefixChange = (prefix: string, key: "phone" | "mobile") => {
    const updatedValue = { ...currentState[key], prefix };
    setCurrentState({ ...currentState, [key]: updatedValue });
  };

  const handlePhoneChange = (
    value: string,
    key: "phone" | "mobile",
    part: "part1" | "part2",
  ) => {
    const updatedValue = { ...currentState[key], [part]: value };
    setCurrentState({ ...currentState, [key]: updatedValue });
  };

  const handleMemoSelect = (memo: string) => {
    setCurrentState({
      ...currentState,
      memo,
      customMemo: memo !== CUSTOM_AVAILABLE_MEMO ? "" : currentState.customMemo,
    });
    setOpenDropdown(null);
  };

  const handleNameSelect = (name: string) => {
    setCurrentState({ ...currentState, addressName: name });
  };

  const toggleDropdown = (dropdown: string) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const handleCheckIsDefault = () => {
    if (!initialDefault) {
      setCurrentState({ ...currentState, isDefault: !currentState.isDefault });
    } else {
      dispatch(
        showToast({
          icon: "caution",
          message: "다른 배송지를 기본 배송지로 선택하면 자동으로 해제됩니다.",
        }),
      );
    }
  };

  const handleSubmit = () => {
    if (isAddressManagement && onSubmit) {
      onSubmit(formState);
    }
  };

  return (
    <div>
      <div className="shadow88 mb16 shopping_box05 shopping_box05_3">
        <h3 className="shadow88_tit fc-bk01">배송지</h3>
        <div>
          <ul>
            <li className="input_info">
              <p>배송지명</p>
            </li>
            <li className="text_input input_data">
              <input
                type="text"
                placeholder="직접 입력하거나 선택하세요."
                value={currentState.addressName}
                onChange={(e) => handleNameSelect(e.target.value)}
              />
              <br />
              <div className="option">
                {ADDRESS_NAME_LIST.map((name) => (
                  <span
                    key={name}
                    className={
                      currentState.addressName === name ? "checked" : ""
                    }
                    onClick={() => handleNameSelect(name)}
                  >
                    {currentState.addressName === name ? (
                      <img src={imgCheck} alt="배송지명 선택" />
                    ) : null}
                    {name}
                  </span>
                ))}
              </div>
            </li>
          </ul>
          <ul>
            <li className="input_info">
              <p>
                수령인<span className="fc-bl02">*</span>
              </p>
            </li>
            <li className="text_input input_data">
              <input
                type="text"
                placeholder="이름을 입력하세요."
                value={currentState.receiver}
                onChange={(e) =>
                  setCurrentState({ ...currentState, receiver: e.target.value })
                }
              />
            </li>
          </ul>
          <ul>
            <li className="input_info">
              <p>
                연락처1<span className="fc-bl02">*</span>
              </p>
            </li>
            <li className="num_input input_data">
              <div
                className={`${styles.numSel} num_sel ${
                  openDropdown === "phone1" ? "open" : ""
                }`}
                onClick={() => toggleDropdown("phone1")}
              >
                <p className={currentState.phone?.prefix ? "selected" : ""}>
                  {currentState.phone?.prefix || "선택"}
                </p>
                <img src={arrowD} />
                <div className={styles.numSelDropdown}>
                  {PHONE_CODE.map((prefix) => (
                    <span
                      key={prefix}
                      className={`${styles.numDropdownItem} ${
                        currentState.phone?.prefix === prefix ? "selected" : ""
                      }`}
                      onClick={() => handlePrefixChange(prefix, "phone")}
                    >
                      {prefix}
                    </span>
                  ))}
                </div>
              </div>
              <span className="bar"></span>
              <input
                type="text"
                placeholder="0000"
                maxLength={4}
                value={currentState.phone?.part1}
                onChange={(e) =>
                  handlePhoneChange(e.target.value, "phone", "part1")
                }
              />
              <span className="bar"></span>
              <input
                type="text"
                placeholder="0000"
                maxLength={4}
                value={currentState.phone?.part2}
                onChange={(e) =>
                  handlePhoneChange(e.target.value, "phone", "part2")
                }
              />
            </li>
          </ul>
          <ul>
            <li className="input_info">
              <p>연락처2</p>
            </li>
            <li className="num_input input_data">
              <div
                className={`${styles.numSel} num_sel ${
                  openDropdown === "phone2" ? "open" : ""
                }`}
                onClick={() => toggleDropdown("phone2")}
              >
                <p className={currentState.mobile?.prefix ? "selected" : ""}>
                  {currentState.mobile?.prefix || "선택"}
                </p>
                <img src={arrowD} />
                <div className={styles.numSelDropdown}>
                  {PHONE_CODE.map((prefix) => (
                    <span
                      key={prefix}
                      className={`${styles.numDropdownItem} ${
                        currentState.mobile?.prefix === prefix ? "selected" : ""
                      }`}
                      onClick={() => handlePrefixChange(prefix, "mobile")}
                    >
                      {prefix}
                    </span>
                  ))}
                </div>
              </div>
              <span className="bar"></span>
              <input
                type="text"
                placeholder="0000"
                maxLength={4}
                value={currentState.mobile?.part1}
                onChange={(e) =>
                  handlePhoneChange(e.target.value, "mobile", "part1")
                }
              />
              <span className="bar"></span>
              <input
                type="text"
                placeholder="0000"
                maxLength={4}
                value={currentState.mobile?.part2}
                onChange={(e) =>
                  handlePhoneChange(e.target.value, "mobile", "part2")
                }
              />
            </li>
          </ul>
          <ul>
            <li className="input_info">
              <p>
                배송지<span className="fc-bl02">*</span>
              </p>
            </li>
            <li className="ad_input input_data">
              <div className="ft_ad">
                <input
                  type="text"
                  placeholder=""
                  readOnly
                  value={currentState.postCode}
                />
                <button onClick={() => handleOpenBottomSheet("address")}>
                  주소 검색
                </button>
              </div>
              <input
                type="text"
                placeholder=""
                className="mb08"
                readOnly
                value={currentState.address}
              />
              <input
                type="text"
                placeholder=""
                value={currentState.addressDetail}
                onChange={(e) =>
                  setCurrentState({
                    ...currentState,
                    addressDetail: e.target.value,
                  })
                }
              />
            </li>
          </ul>
          <ul>
            <li className="input_info">
              <p>수령메모</p>
            </li>
            <li className="text_input input_data">
              <div
                className={`${styles.numSel} num_sel`}
                onClick={() => toggleDropdown("memo")}
              >
                <p>{currentState.memo || "선택해주세요."}</p>
                <img src={arrowD02} />
              </div>
              {openDropdown === "memo" && (
                <div className={styles.dropdownWrap}>
                  {MEMO_LIST.map((memo) => (
                    <div
                      key={memo}
                      className={`${styles.dropdownItem} ${
                        currentState.memo === memo ? "selected" : ""
                      }`}
                      onClick={() => handleMemoSelect(memo)}
                    >
                      {memo}
                      {currentState.memo === memo && (
                        <img
                          className={styles.selectedCheck}
                          src={iconCheckBlue}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
              {currentState.memo === CUSTOM_AVAILABLE_MEMO && (
                <textarea
                  className={styles.customMemoInput}
                  value={currentState.customMemo}
                  onChange={(e) =>
                    setCurrentState({
                      ...currentState,
                      customMemo: e.target.value,
                    })
                  }
                  placeholder="20자 이내로 입력하세요."
                  maxLength={20}
                  rows={1}
                  ref={customMemoRef}
                />
              )}
            </li>
          </ul>
          {!isAddressManagement && (
            <div className="input_noti">
              <img src={iconNotiBlue} />
              <p>
                입력하신 주소로 택배 기사님이 방문 예정입니다.{" "}
                <span>정확히 입력</span>해 주세요!
              </p>
            </div>
          )}
        </div>
      </div>
      {isAddressManagement && (
        <div>
          <label htmlFor="checkbox-1" className="sub02_aco_l">
            <div className={`agency_check ${styles.mt16}`}>
              <input
                type="checkbox"
                id="check2"
                checked={currentState.isDefault}
                onChange={handleCheckIsDefault}
              />
              <label htmlFor="check2">
                <h3>기본 배송지로 선택</h3>
              </label>
            </div>
          </label>
          <div className={styles.bottomBtnWrap}>
            <button className={`sub_wrap02_btn on`} onClick={handleSubmit}>
              {editMode ? "수정" : "등록"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
