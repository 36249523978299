import { useState } from "react";
import {
  DeliveryCard,
  DeliveryForm,
} from "@components/common/delivery-form/DeliveryForm";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  openBottomSheet,
  closeBottomSheet,
} from "@/features/bottom-sheet/bottomSheetSlice";
import {
  setPurchaseInfo,
  IPurchaseInfo,
} from "@/features/purchase/purchaseSlice";
import { showToast } from "@/features/toast/toastSlice";
import useAddressList from "./hooks/useAddressList";
import { formatDeliveryForm, splitPhoneNumber } from "@/utils/utils";
import { CUSTOM_AVAILABLE_MEMO, MEMO_LIST } from "@/utils/constants";
import useAddressRegister from "./hooks/useAddressRegister";
import useAddressModify from "./hooks/useAddressModify";
import useAddressDelete from "./hooks/useAddressDelete";
import DaumPostcodeEmbed from "react-daum-postcode";
import { BottomSheet } from "@components/index";

import * as styles from "./styles.css";
import icoNoti from "@assets/icons/icon_noti_filled_gray.png";
import iconLeft from "@assets/icons/icon_left.png";
import iconInfo from "@assets/icons/ico_info_24px.png";
import { validateInfo } from "@pages/trade-history/hooks/useShoppingChange";

const renderAddressInfo = () => (
  <div
    style={{
      width: "100%",
      padding: 8,
      background: "#F2F7FF",
      borderRadius: 8,
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 8,
      display: "inline-flex",
      marginBottom: 16,
      boxSizing: "border-box",
    }}
  >
    <img src={iconInfo} style={{ width: 24, height: 24 }} />
    <div>
      <span className={styles.highlightSpan}>배송지</span>
      <span className={styles.normalSpan}>는 </span>
      <span className={styles.highlightSpan}>최대 5개까지 </span>
      <span className={styles.normalSpan}>저장 가능합니다.</span>
    </div>
  </div>
);

const NoAddress = () => (
  <div className={styles.noAddress.pageWrapper}>
    <img
      alt="notification"
      src={icoNoti}
      className={styles.noAddress.ico_noti}
    />
    <div className={styles.noAddress.kor}>등록된 배송지가 없습니다.</div>
  </div>
);
export const initialDeliveryInfo = {
  // 택배 배송지 정보
  postCode: "", // 우편번호
  address: "", // 주소
  addressDetail: "", // 상세주소
  addressName: "", // 배송지명
  receiver: "", // 수령인
  phone: { prefix: "", part1: "", part2: "" }, // 연락처1
  mobile: { prefix: "", part1: "", part2: "" }, // 연락처2
  memo: "", // 수령메모
  customMemo: "", // 메모 직접 입력
  isDefault: false, // 기본 배송지로 추가
  editId: 0, // 배송지 ID
};

const DeliveryAddressManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("list");
  const [isEditing, setIsEditing] = useState(false);
  const [deliveryFormData, setDeliveryFormData] =
    useState<Partial<IPurchaseInfo>>(initialDeliveryInfo);

  const { userAddress, userAddressRefetch } = useAddressList();
  const addressRegisterMutation = useAddressRegister(
    setActiveTab,
    userAddressRefetch,
  );
  const addressModifyMutation = useAddressModify(
    setActiveTab,
    setIsEditing,
    userAddressRefetch,
  );
  const addressDeleteMutation = useAddressDelete(userAddressRefetch);

  const handleFormStateChange = (updatedState: Partial<IPurchaseInfo>) => {
    setDeliveryFormData(updatedState);
  };

  const handleClickBack = () => navigate(-1);

  const handleOpenBottomSheet = () => {
    dispatch(openBottomSheet());
  };

  const handleEditAddress = (address: Partial<IPurchaseInfo>) => {
    setDeliveryFormData({
      ...address,
      memo: MEMO_LIST.includes(address.memo || "")
        ? address.memo
        : CUSTOM_AVAILABLE_MEMO,
      customMemo: MEMO_LIST.includes(address.memo || "") ? "" : address.memo,
    });
    setActiveTab("new");
    setIsEditing(true);
  };

  const handleAddNewAddress = () => {
    setDeliveryFormData(initialDeliveryInfo); // 폼 초기화
    setActiveTab("new");
    setIsEditing(false); // 신규 등록 모드
  };

  const handleCompleteSearch = (result: any) => {
    setDeliveryFormData((prevState) => ({
      ...prevState,
      postCode: result.zonecode,
      address: result.address,
    }));
    dispatch(closeBottomSheet());
  };

  const handleFormSubmit = (formState: any) => {
    const { isValid, message } = validateInfo(true, formState);
    if (!isValid) {
      dispatch(showToast({ icon: "caution", message }));
    } else {
      const formattedData = formatDeliveryForm(formState);
      if (isEditing) {
        addressModifyMutation.mutate({
          editId: formattedData.id || 0,
          deliveryInfo: { ...formattedData },
        });
      } else {
        addressRegisterMutation.mutate({ ...formattedData });
      }
    }
  };

  const handleClickAddressDelete = (editId: number, isDefault: boolean) => {
    if (isDefault) {
      dispatch(
        showToast({
          message: "다른 배송지를 기본 배송지로 선택하면 자동으로 해제됩니다.",
          icon: "error",
          second: 3,
        }),
      );
    } else {
      addressDeleteMutation.mutate(editId);
    }
  };

  return (
    <div>
      <BottomSheet>
        <div className={styles.addressWrap}>
          <DaumPostcodeEmbed
            onComplete={handleCompleteSearch}
            style={{ height: 500 }}
            autoClose={false}
          />
        </div>
      </BottomSheet>
      <div className={styles.subWrap}>
        <div className={styles.subTop}>
          <div className={styles.subTopDiv}>
            <img
              src={iconLeft}
              alt="Back"
              className={styles.subTopImage}
              onClick={handleClickBack}
            />
            <p className={styles.subTopText}>배송지</p>
          </div>
        </div>
        <div className={styles.subTap}>
          <ul className={styles.subTapList}>
            <li
              className={styles.subTapItem}
              onClick={() => {
                setActiveTab("list");
                setIsEditing(false);
              }}
            >
              <p
                className={
                  activeTab === "list"
                    ? styles.subTapItemActive
                    : styles.subTapText
                }
              >
                배송지 목록
              </p>
            </li>
            <li className={styles.subTapBar}></li>
            <li className={styles.subTapItem} onClick={handleAddNewAddress}>
              <p
                className={
                  activeTab === "new"
                    ? styles.subTapItemActive
                    : styles.subTapText
                }
              >
                신규 배송지 입력
              </p>
            </li>
          </ul>
        </div>
        <div
          className={`${styles.subTabContentWrap} ${
            activeTab === "list" ? styles.listPadding : styles.newPadding
          }`}
        >
          {activeTab === "list" &&
            userAddress?.length > 0 &&
            renderAddressInfo()}
          {activeTab === "list" &&
            (userAddress?.length ?? 0 > 0 ? (
              userAddress?.map((address) => {
                const transformedUserAddress = {
                  ...address,
                  addressName: address.addressName ?? "",
                  memo: address.memo ?? "",
                  phone: splitPhoneNumber(address.phone),
                  mobile: address.mobile
                    ? splitPhoneNumber(address.mobile)
                    : { prefix: "", part1: "", part2: "" },
                  editId: address.id,
                };

                return (
                  <DeliveryCard
                    key={address.id}
                    deliveryInfo={transformedUserAddress}
                    onEdit={() => handleEditAddress(transformedUserAddress)}
                    onDelete={() =>
                      handleClickAddressDelete(address.id, address.isDefault)
                    }
                  />
                );
              })
            ) : (
              <NoAddress />
            ))}
          {activeTab === "new" && (
            <DeliveryForm
              handleOpenBottomSheet={handleOpenBottomSheet}
              initialState={deliveryFormData}
              editMode={isEditing}
              onSubmit={handleFormSubmit}
              onChangeFormState={handleFormStateChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliveryAddressManagement;
