import { useDispatch } from "react-redux";
import useCustomQuery from "@/hooks/queries/useCustomQuery";
import * as APIS from "@/services/apis";
import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import { routes } from "@/routes";

interface IDepositConfig {
  fee: number;
  autoLimit: number;
  maxCount: number;
  maxKrw: number;
  withdrawTimeLimit: number;
}

const useWithdrawEnvConfig = () => {
  const dispatch = useDispatch();

  const fetchConfig = async () => {
    dispatch(showLoading());
    try {
      const [
        WITHDRAW_FEE_KRW,
        AUTO_WITHDRAW_LIMIT,
        MAX_COUNT_WITHDRAW_DAY,
        MAX_KRW_WITHDRAW_DAY,
        WITHDRAW_TIME_LIMIT,
      ] = await Promise.all([
        APIS.getEnvConfig("WITHDRAW_FEE_KRW"), // 출금수수료
        APIS.getEnvConfig("AUTO_WITHDRAW_LIMIT"), // 자동 출금 제한
        APIS.getEnvConfig("MAX_COUNT_WITHDRAW_DAY"), // 1일 최대 출금 횟수
        APIS.getEnvConfig("MAX_KRW_WITHDRAW_DAY"), // 1일 출금 종액 최대
        APIS.getEnvConfig("WITHDRAW_TIME_LIMIT"), // 입금 후 출금 제한 시간
      ]);

      dispatch(hideLoading());
      return {
        fee: WITHDRAW_FEE_KRW.data.data?.value || 0,
        autoLimit: AUTO_WITHDRAW_LIMIT.data.data?.value || 0,
        maxCount: MAX_COUNT_WITHDRAW_DAY.data.data?.value || 0,
        maxKrw: MAX_KRW_WITHDRAW_DAY.data.data?.value || 0,
        withdrawTimeLimit: WITHDRAW_TIME_LIMIT.data.data?.value || 0,
      };
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, refetch } = useCustomQuery<IDepositConfig>(
    [routes.withdraw, "config"],
    fetchConfig,
  );

  return { config: data, isLoading, error, refetchConfig: refetch };
};

export default useWithdrawEnvConfig;
