import { showToast } from "@/features/toast/toastSlice";
import { numberWithCommas } from "@/utils/utils";
import { RefObject, createRef, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "@/routes";
import { closeModal, openModal } from "@/features/modal/modalSlice";
import colors from "@assets/styles/colors.css";
interface YearItem {
  id: number;
  feeChargedDate: string;
  feeKrw: number;
}

interface FeesState {
  totalFeeKrw: number;
  storageFeeMonthDtoList: any[];
  beforeStorageFeeList: any[];
  yearList?: any[];
}
interface StorageWeightProps {
  fees: FeesState;
}

const UnpaidStorageFee: React.FC<StorageWeightProps> = ({ fees }) => {
  const [checkboxRefs, setCheckboxRefs] = useState<
    RefObject<HTMLInputElement>[]
  >([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setCheckboxRefs((refs) =>
      Array(fees?.yearList?.length)
        .fill(null)
        .map((_, i) => refs[i] || createRef<HTMLInputElement>()),
    );
  }, []);

  const handleDivClick = (index: number) => {
    const currentRef = checkboxRefs[index]?.current;
    if (currentRef) {
      currentRef.checked = !currentRef.checked;
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleOpenModal = () => {
    dispatch(
      openModal(
        <div className="bg_gr_wrap">
          <div className="modal_wrap04 bd_bl">
            <h3>
              <span className="fc-bl03">
                보관료 {`${numberWithCommas(fees?.totalFeeKrw || 0)}`}원
              </span>
              을<br /> 납부하시겠습니까?
            </h3>
            <p>보유하신 KRW에서 자동 납부됩니다. </p>
            <div className="modal_btn">
              <button className="bg_gr_btn" onClick={handleCloseModal}>
                취소
              </button>
              <button
                className="bg_bk_btn"
                onClick={handleClickPay}
                style={{
                  backgroundColor: colors.buttonColor,
                }}
              >
                납부하기
              </button>
            </div>
          </div>
        </div>,
      ),
    );
  };

  const handleClickPay = () => {
    dispatch(closeModal());
    if (fees?.storageFeeMonthDtoList.length === 0) {
      dispatch(
        showToast({ message: "납부하실 보관료가 없습니다.", icon: "caution" }),
      );
      return;
    }
    navigate(routes.commonSecureKeypad, {
      state: {
        type: "STORAGE_FEE",
        payload: {
          totalFeeKrw: `${fees.totalFeeKrw}`,
        },
      },
    });
  };

  return (
    <div>
      <div className="shadow88 gap">
        <div className="stt_price">
          <ul>
            <li>
              <p className="fc-rd02">총 미납 보관료</p>
            </li>
            <li>
              <h3 className="fc-rd01">
                {`${numberWithCommas(fees?.totalFeeKrw || 0)}`}원
              </h3>
            </li>
          </ul>
        </div>
      </div>
      <div className="sub_wrap09_info gap">
        <div className="sub_wrap09_noti02">
          <div className="accordion">
            {Array.isArray(fees?.yearList) &&
              fees.yearList?.map((year, index) => {
                return (
                  <ul className="aco_ul bb_none" key={`year_${index}`}>
                    <li>
                      <input
                        type="checkbox"
                        id={`checkbox-${index}`}
                        name="checkbox-accordion"
                        ref={checkboxRefs[index]}
                      />
                      <div
                        className="btn_cx_down"
                        onClick={() => handleDivClick(index)}
                      ></div>
                      <label htmlFor={`checkbox-${index}`}>
                        {year?.year}년 내역
                      </label>
                      <div className="content02">
                        <div
                          className="btn_cx_up"
                          onClick={() => handleDivClick(index)}
                        ></div>
                        {year?.data.map((item: YearItem, index: number) => {
                          let month =
                            new Date(item.feeChargedDate).getMonth() + 1;
                          return (
                            <ul
                              key={index}
                              style={{
                                borderBottom: `${
                                  index === year?.data.length - 1 && "unset"
                                }`,
                              }}
                            >
                              <li>
                                <p>{month}월</p>
                              </li>
                              <li>
                                <p>
                                  <span>{`${numberWithCommas(
                                    item?.feeKrw,
                                  )}`}</span>
                                  원
                                </p>
                              </li>
                            </ul>
                          );
                        })}
                      </div>
                    </li>
                  </ul>
                );
              })}
          </div>
        </div>
      </div>
      <div className="sub09_btn">
        <button
          onClick={handleOpenModal}
          style={{
            backgroundColor: colors.buttonColor,
          }}
        >
          보관료 납부하기
        </button>
      </div>
    </div>
  );
};

export default UnpaidStorageFee;
