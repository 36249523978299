import { useState, useEffect } from "react";
import iconCheckSt from "@/assets/icons/icon_check_st.png";
import iconCheckSt2 from "@/assets/icons/icon_check_st2.png";
import iconNotiG from "@/assets/icons/icon_noti_g.png";
import icon16 from "@/assets/icons/ico_16px.png";
import iconCheck from "@/assets/icons/icon_check_st02.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  resetTenSecondAppraisal,
  selectTenAppraisal,
} from "@/features/appraisal-tensecond/appraisalTenSecondSlice";
import { routes } from "@/routes";
import { numberWithCommas, requestNativeLogin } from "@/utils/utils";
import { closeModal, openModal } from "@/features/modal/modalSlice";
import DetailHeader from "@/components/layouts/DetailHeader";
import modalNoti from "@/assets/images/modal_noti.png";
import * as style from "../appraisal-select-asset/style.css";
import { RootState } from "@/app/store";
import ReservationBottomSheet from "@pages/appraisal-agency/components/ReservationBottomSheet";
import {
  closeBottomSheet,
  openBottomSheet,
} from "@/features/bottom-sheet/bottomSheetSlice";
import colors from "@assets/styles/colors.css";

/**
 * TODO
 * [ ] 뒤로가기 누르면 감정완료리스트에 저장된게 빠져야 한다.
 */

const AppraisalResult = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tenAppraisalData = useSelector(selectTenAppraisal).tenSecondAppraisal;
  const auth = useSelector((state: RootState) => state.auth);
  const selected = tenAppraisalData;
  const {
    assetType,
    item,
    purity,
    weight,
    weightUnit,
    estimatedSalesPrice,
    appraisalCompletedList,
    appraisalWeight,
  } = selected;
  const [allChecked, setAllChecked] = useState<boolean>(true);
  const [checkedItems, setCheckedItems] = useState<number[]>(
    appraisalCompletedList.map((_, index) => index),
  );

  const selectedAssetType = assetType
    .replace("GOLD", "금")
    .replace("SILVER", "은");

  const handleClickAddAppraisal = () => {
    navigate(routes.appraisalSelectAsset);
  };

  const handleAllCheck = () => {
    setAllChecked(!allChecked);
  };

  const handleCheckboxChange = (index: number) => {
    setCheckedItems((prevCheckedItems) => {
      if (prevCheckedItems.includes(index)) {
        return prevCheckedItems.filter((item) => item !== index);
      } else {
        return [...prevCheckedItems, index];
      }
    });
  };

  const calculateTotalWeight = () => {
    let totalWeight = 0;
    const reversedCheckedItems = checkedItems
      .map((index) => appraisalCompletedList.length - 1 - index)
      .reverse();

    reversedCheckedItems.forEach((index) => {
      const item = appraisalCompletedList[index];
      let weight = item.appraisalWeight;
      if (item?.weightUnit === "don") {
        weight = Number((item?.appraisalWeight * 3.75).toFixed(3));
      }
      totalWeight += weight;
    });

    return totalWeight;
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    const reversedCheckedItems = checkedItems
      .map((index) => appraisalCompletedList.length - 1 - index)
      .reverse();

    reversedCheckedItems.forEach((index) => {
      const item = appraisalCompletedList[index];
      totalPrice += item.estimatedSalesPrice;
    });

    return numberWithCommas(totalPrice) || 0;
  };

  const handleClickReservation =
    (newOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event === undefined ||
        (event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift"))
      ) {
        dispatch(closeBottomSheet());
      }
      if (!auth.isSigned) {
        requestNativeLogin(navigate);
      } else {
        dispatch(openBottomSheet());
      }
    };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleClickStop = () => {
    dispatch(resetTenSecondAppraisal());
    dispatch(closeModal());
    navigate(routes.sell);
  };

  const handleClickCancel = () => {
    dispatch(
      openModal(
        <div className="bg_gr_wrap">
          <div className="modal_wrap">
            <img src={modalNoti} alt="" />
            <h3>감정하신 모든 정보가 사라집니다.</h3>
            <p>그만하시겠어요?</p>
            <div className="modal_btn">
              <button className="bg_gr_btn" onClick={handleClickStop}>
                그만하기
              </button>
              <button
                className="bg_bk_btn"
                onClick={handleCloseModal}
                style={{
                  backgroundColor: colors.buttonColor,
                }}
              >
                계속하기
              </button>
            </div>
          </div>
        </div>,
      ),
    );
  };
  const handleClickBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (allChecked) {
      setCheckedItems(appraisalCompletedList.map((_, index) => index));
    } else {
      setCheckedItems([]);
    }
  }, [allChecked, appraisalCompletedList]);

  return (
    <div className={style.wrap}>
      <DetailHeader
        title="감정 결과"
        cancelCallback={handleClickCancel}
        backCallback={handleClickBack}
      />
      <div className="sub_wrap02">
        <div className="sell_wrap01 sell_wrap05">
          <div className="check_option">
            <p>
              <img src={icon16} />
              완료 되었습니다.
            </p>
          </div>
          <div className="sell_option">
            <h3 className="op_tit">감정 결과입니다.</h3>
          </div>
          <div className="shadow88 sell_wrap05_box">
            <div className="sell_wrap05_boxtop">
              <h3>
                <img src={iconCheck} alt="" />
                감정 완료
              </h3>
            </div>
            <div className="sell_wrap05_boxcont">
              <h3 className="sell_wrap05_boxcont_tit">{`${selectedAssetType}, ${item}, ${purity}`}</h3>
              <ul>
                <li className="boxcont_t" style={{ borderBottom: "unset" }}>
                  <h3>입력 중량</h3>
                  <p>
                    <span className="spoqa">{weight}</span>
                    {weightUnit.replace("don", "돈")} (
                    <span className="spoqa">
                      {weightUnit === "g"
                        ? numberWithCommas(
                            Math.round((weight / 3.75) * 100) / 100,
                          )
                        : numberWithCommas(
                            Math.round(weight * 3.75 * 100) / 100,
                          )}
                    </span>
                    {weightUnit === "g" ? "돈" : "g"})
                  </p>
                </li>
                <li className="boxcont_t">
                  <h3>감정 중량</h3>
                  <p>
                    <span className="spoqa">{appraisalWeight}</span>
                    {weightUnit.replace("don", "돈")} (
                    <span className="spoqa">
                      {weightUnit === "g"
                        ? numberWithCommas(
                            Math.round((appraisalWeight / 3.75) * 100) / 100,
                          )
                        : numberWithCommas(
                            Math.round(appraisalWeight * 3.75 * 100) / 100,
                          )}
                    </span>
                    {weightUnit === "g" ? "돈" : "g"})
                  </p>
                </li>
                <li className="boxcont_b">
                  <h3>판매 예상가</h3>
                  <p>
                    <span className="spoqa">
                      {numberWithCommas(estimatedSalesPrice)}
                    </span>
                    원
                  </p>
                </li>
              </ul>
            </div>
          </div>
          {appraisalCompletedList.length > 0 && (
            <div className="sell_accordion2 sell_vertical2 sell_main05 shadow88">
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="checkbox-1"
                    name="checkbox-accordion"
                  />
                  <label htmlFor="checkbox-1">
                    <h3>감정 완료 내역</h3>
                    <div className="btn_cx_down"></div>
                    <div className="btn_cx_up"></div>
                  </label>
                  <div className="sell_content">
                    <input
                      type="checkbox"
                      id="all-check"
                      checked={allChecked}
                      onChange={handleAllCheck}
                    />
                    <label htmlFor="all-check">
                      <p>전체 선택</p>
                    </label>
                    <span className="sell_con_op">최근순</span>
                    {appraisalCompletedList
                      .slice()
                      .reverse()
                      .map((item, index) => {
                        return (
                          <div className="sell_main05_box" key={index}>
                            <input
                              type="checkbox"
                              id={`appraisal-item${index}`}
                              checked={checkedItems.includes(index)}
                              onChange={() => handleCheckboxChange(index)}
                            />
                            <label htmlFor={`appraisal-item${index}`}>
                              <p>
                                {`${item.assetType}, ${item.item}, ${item.purity} `}
                              </p>
                            </label>
                            <ul>
                              <li className="boxcont_t">
                                <h3>입력 중량</h3>
                                <p>
                                  <span className="spoqa">{item.weight}</span>
                                  {item.weightUnit.replace("don", "돈")} (
                                  <span className="spoqa">
                                    {item.weightUnit === "g"
                                      ? numberWithCommas(
                                          Math.round(
                                            (item.weight / 3.75) * 100,
                                          ) / 100,
                                        )
                                      : numberWithCommas(
                                          Math.round(item.weight * 3.75 * 100) /
                                            100,
                                        )}
                                  </span>
                                  {item.weightUnit === "g" ? "돈" : "g"})
                                </p>
                              </li>
                              <li className="boxcont_t">
                                <h3>감정 중량</h3>
                                <p>
                                  <span className="spoqa">
                                    {item.appraisalWeight}
                                  </span>
                                  {item.weightUnit.replace("don", "돈")} (
                                  <span className="spoqa">
                                    {item.weightUnit === "g"
                                      ? numberWithCommas(
                                          Math.round(
                                            (item.appraisalWeight / 3.75) * 100,
                                          ) / 100,
                                        )
                                      : numberWithCommas(
                                          Math.round(
                                            item.appraisalWeight * 3.75 * 100,
                                          ) / 100,
                                        )}
                                  </span>
                                  {item.weightUnit === "g" ? "돈" : "g"})
                                </p>
                              </li>
                              <li className="boxcont_b">
                                <h3>판매 예상가</h3>
                                <p>
                                  <span className="spoqa">
                                    {numberWithCommas(
                                      item.estimatedSalesPrice,
                                    ) || 0}
                                  </span>
                                  원
                                </p>
                              </li>
                            </ul>
                            <p className="sell_main05_date">
                              <span className="spoqa">
                                {item.appraisalTime}
                              </span>
                              {index === appraisalCompletedList.length - 1
                                ? "∙감정시간"
                                : "∙추가 감정시간"}
                              <img src={iconCheckSt2} alt="" />{" "}
                            </p>
                          </div>
                        );
                      })}
                    <ul className="boxcont_all">
                      <li className="boxcont_t">
                        <h3>총 감정 중량</h3>
                        <p>
                          <span className="spoqa">
                            {numberWithCommas(
                              calculateTotalWeight().toFixed(2),
                            )}
                          </span>
                          g (
                          <span className="spoqa">
                            {numberWithCommas(
                              Math.round(
                                (calculateTotalWeight() / 3.75) * 100,
                              ) / 100,
                            )}
                          </span>
                          돈)
                        </p>
                      </li>
                      <li className="boxcont_b">
                        <h3>판매 예상가</h3>
                        <p>
                          <span className="spoqa">{calculateTotalPrice()}</span>
                          원
                        </p>
                      </li>
                    </ul>
                    <p className="sell_noti">
                      <img src={iconNotiG} alt="!" />
                      리스트는 저장되지 않습니다.{" "}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="sub_wrap05_btn">
        <button className="bg_gr_btn" onClick={handleClickAddAppraisal}>
          추가 감정하기
        </button>
        <button
          className="bg_bk_btn"
          onClick={handleClickReservation(true)}
          style={{
            backgroundColor: colors.buttonColor,
          }}
        >
          대리점 예약하기
        </button>
      </div>
      <ReservationBottomSheet lastPage="tenSecondAppraisal" />
    </div>
  );
};

export default AppraisalResult;
