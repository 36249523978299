import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router";
import ReactGA from "react-ga4";

const useRouteChangeTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const gaId = import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID;

  useEffect(() => {
    if (!window.location.href.includes("localhost") && gaId) {
      ReactGA.initialize(gaId);
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({
        hitType: "pageview",
        path: location.pathname,
        location: location.pathname,
        title: location.pathname,
      });
      //   ReactGA.send({ hitType: "pageview", page: location.pathname });
      //   ReactGA.send("pageview");
    }
  }, [initialized, location]);
};

export default useRouteChangeTracker;
