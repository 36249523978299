import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { persistStore } from "redux-persist";
import { AuthState } from "@/features/auth/authSlice";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState> & {
  auth: AuthState;
};
export type AppDispatch = typeof store.dispatch;
const persistor = persistStore(store);
export { store, persistor };
