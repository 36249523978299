import React from "react";
import { numberWithCommas } from "../../../utils/utils";

interface OrderListItemProps {
  lastPrice: number;
  data: any;
  donPrice: number;
  inputs: any;
  setInputs: any;
}

const OrderListItem: React.FC<OrderListItemProps> = ({
  lastPrice,
  data,
  donPrice,
  inputs,
  setInputs,
}) => {
  const isLastPrice = lastPrice === data.orderPrice;
  const standardClass = isLastPrice ? "standard" : "";
  const priceClassName =
    data.orderPrice > lastPrice
      ? "fc-rd01"
      : data.orderPrice < lastPrice
      ? "fc-bl01"
      : "fc-gr01";

  const handleClickOrderPrice = () => {
    setInputs({ ...inputs, price: String(data.orderPrice) });
  };
  return (
    <tr
      className={`${
        data.tradeType === "SELL" ? "upper_price" : "lower_price"
      } ${standardClass}`}
      onClick={handleClickOrderPrice}
    >
      <td className="num_chart_won">
        <p className={priceClassName}>{numberWithCommas(data.orderPrice)}</p>
        <span>{numberWithCommas(donPrice)}/돈</span>
      </td>
      <td className="num_chart_gram">
        <p>{numberWithCommas(data.remainGram)}</p>
      </td>
    </tr>
  );
};

export default OrderListItem;
