import React, { useEffect } from "react";
import iconLeft from "@assets/icons/icon_left.png";
import iconX from "@assets/icons/icon_x.png";
import icon88Ok from "@assets/icons/icon_88_ok.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSessionStorageItem } from "@/services/storageService";
import * as APIS from "@/services/apis";
import {
  createPurchaseInfo,
  updatePurchaseInfo,
} from "../hooks/createPaymentInfo";
import { setPurchaseInfo } from "@/features/purchase/purchaseSlice";
import { routes } from "@/routes";
import usePendingCheck from "@/hooks/usePendingCheck";
import { hideLoading, showLoading } from "@/features/loading/loadingSlice";
import { showToast } from "@/features/toast/toastSlice";
import { useSelector } from "react-redux";

import { RootState } from "@/app/store";
interface PurchaseData {
  status: string;
  transId: number;
}

const TossSuccess = () => {
  const { goodsId, txId } = useParams(); // txId: 데이터 임시 정보 ID
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search.replace(/&amp;/gi, "&"));
  const orderId = params.get("orderId");
  const paymentKey = params.get("paymentKey");

  const pendingCheck = usePendingCheck();
  const purchaseInfo = useSelector((state: RootState) => state.purchase);

  // console.log({ orderId, paymentKey, goodsId, txId });

  useEffect(() => {
    const init = async () => {
      if (txId && !sessionStorage.getItem(`processedTxId-${txId}`)) {
        // txId가 있으면 txId로 조회하고, 전역상태 업데이트하는 로직
        if (txId && orderId && paymentKey) {
          await updatePurchaseInfo(txId, dispatch);
          dispatch(
            setPurchaseInfo({
              tossOrderId: orderId,
              tossPaymentKey: paymentKey,
            }),
          );
        }

        // 결제 요청 로직
        await requestPurchase();

        // txId를 SessionStorage에 저장하여 중복 실행 방지
        sessionStorage.setItem(`processedTxId-${txId}`, "true");
      }
    };

    init();
  }, [txId, orderId, paymentKey, dispatch]);

  const requestPurchase = async () => {
    try {
      dispatch(showLoading());
      const purchaseReq = createPurchaseInfo(
        purchaseInfo,
        orderId!,
        paymentKey!,
      );
      const {
        data: { success, data, message },
      } = await APIS.putPurchase(goodsId, purchaseReq);
      if (success) {
        pendingCompletePurchaseCheck(data.goodsMallRequestId);
      } else {
        dispatch(hideLoading());
        dispatch(showToast({ message, icon: "error" }));
      }
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showToast({ message: error.message, icon: "error" }));
    }
  };

  const pendingCompletePurchaseCheck = (goodsMallRequestId: string) => {
    pendingCheck<PurchaseData>({
      checkFn: () => APIS.getPurchaseCheck(goodsMallRequestId),
      successCondition: (data) => data.status === "APPROVAL",
      successMessage: "결제 완료되었습니다.",
      processingMessage: "결제 요청 처리 중 입니다.",
      failureMessage: "결제에 실패하였습니다.",
      navigateOnSuccess: (data) => {
        navigate(`${routes.purchaseSuccess}/${data.transId}`);
      },
      navigateOnProcessing: () => {
        dispatch(hideLoading());
        navigate(routes.main);
      },
      navigateOnFailure: (message: any) => {
        navigate(`${routes.purchaseFail}`, {
          state: {
            failReason: message,
          },
        });
        dispatch(hideLoading());
      },
    });
  };

  return <div></div>;
};

export default TossSuccess;
