import React from "react";
import { useQuery } from "@tanstack/react-query";

/**
 * NOTE: Test page
 */
const Count = () => {
  const { isLoading, data } = useQuery<{ name: string }[]>({
    queryKey: ["allCoins"],
    queryFn: () =>
      fetch(`https://api.coinpaprika.com/v1/coins`).then((response) =>
        response.json(),
      ),
  });
  return (
    <>
      <h1>리액트 쿼리</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <ul>
          {data?.slice(0, 100).map(
            (
              coin, // data 렌더링
            ) => (
              <li>{coin.name}</li>
            ),
          )}
        </ul>
      )}
    </>
  );
};

export default Count;
