import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { routes } from "../../routes";
import { hideLoading, showLoading } from "../../features/loading/loadingSlice";
import { postSignInId } from "../../services/apis";
import { decodeJwt } from "../../utils/utils";
import imgClose from "../../assets/icons/login-close.png";
import imgLogo from "../../assets/images/login-logo.png";
import {
  root,
  close,
  top,
  bottom,
  label,
  sns,
  social,
  socialIcon,
  socialLabel,
  flex,
  imgButton,
  imgButtonImg,
  buttonSection,
} from "./style.css";
import fonts from "../../assets/styles/fonts";
import { signIn } from "../../utils/sign";
import { showToast } from "../../features/toast/toastSlice";

const SignInId = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");

  const handleClickClose = () => {
    navigate(-1);
  };

  const handleClickSignUp = () => {
    navigate(routes.signUpId);
  };

  const handleClickSignIn = () => {
    // if (!loginId) {
    //   dispatch(toastShow("아이디를 확인해주세요."));
    //   return false;
    // } else if (!password) {
    //   dispatch(toastShow("비밀번호를 확인해주세요."));
    //   return false;
    // } else {
    //   dispatch(showLoading);
    postSignInId({ loginId, password }).then(
      ({ data: { success, message, data } }) => {
        if (success) {
          const { authToken } = data;
          // const { roles = "" } = decodeJwt(authToken);
          const decoded = decodeJwt(authToken);
          const roles = decoded?.roles || "";

          if (roles.split(",").includes("ROLE_SELF_VERIFICATION_MALL")) {
            navigate(routes.acceptTerms, {
              state: { purpose: "MALL_TO_APP", token: authToken },
            });
          } else {
            signIn(authToken);
            // dispatch(
            //   showToast({ message: "로그인 되었습니다.", icon: "success" }),
            // );
            navigate(routes.main, { replace: true });
          }
        }
        //   else {
        //     dispatch(actionError(message));
        //   }
      },
    );
    // .finally(() => dispatch(hideLoading));
    // }
  };
  return (
    <div className={`${root} ${flex}`}>
      <button
        onClick={handleClickClose}
        className={`${close} ${imgButton}`}
        // align="right"
      >
        <img src={imgClose} />
      </button>
      <div className={`${top} ${flex}`}>
        <span className={label}>모바일 금은방</span>
        <img src={imgLogo} style={{ width: "50vw" }} />
      </div>
      <div className={`${bottom} ${flex}`}>
        <input
          placeholder="아이디 입력"
          value={loginId}
          onChange={(e) => {
            setLoginId(e.target.value);
          }}
        />
        {/* <InputText label="아이디 입력" value={loginId} onChange={setLoginId} /> */}
        <div className={flex} style={{ height: 10 }} />
        <input
          type="password"
          placeholder="비밀번호 입력"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        {/* <InputText
          type="password"
          label="비밀번호 입력"
          value={password}
          onChange={setPassword}
        /> */}
        <div className={`${buttonSection} ${flex}`}>
          <button className={social} onClick={handleClickSignIn}>
            <span style={{ fontFamily: fonts.notoSansKRMedium }}>로그인</span>
          </button>
          <div className={flex} style={{ marginTop: 20, flexDirection: "row" }}>
            <span
              style={{
                fontSize: 13,
                color: "#717171",
                fontFamily: fonts.notoSansKRRegular,
              }}
            >
              아직 회원이 아니신가요?
            </span>
            <button style={{ marginLeft: 7 }} onClick={handleClickSignUp}>
              <span
                style={{
                  fontSize: 13,
                  color: "#333333",
                  fontFamily: fonts.notoSansKRMedium,
                }}
              >
                회원가입하기
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInId;
