import { formatTime, numberWithCommas } from "../../../utils/utils";
import { CONSTANT } from "../../../utils/constants";
import icoUp from "@assets/icons/ico_up.png";
import icoDown from "@assets/icons/ico_down.png";
import iconSame from "@assets/icons/icon_same.png";

interface CONSTANTType {
  GOLD: {
    TITLE: string;
    TYPE: string;
  };
  SILVER: {
    TITLE: string;
    TYPE: string;
  };
}

interface MarketPriceData {
  updatedAt: string;
}
interface MarketPriceSectionProps {
  assetType: string;
  marketPriceData: MarketPriceData;
  currentPrice: string;
  closingPrice: string;
  setAssetType: React.Dispatch<React.SetStateAction<"GOLD" | "SILVER">>;
}

const MarketPriceSection: React.FC<MarketPriceSectionProps> = ({
  assetType,
  marketPriceData,
  currentPrice,
  closingPrice,
  setAssetType,
}) => {
  const priceChange = Number(currentPrice) - Number(closingPrice);
  const priceChangePercent = (
    (priceChange / Number(closingPrice)) *
    100
  ).toFixed(2);
  const isPriceUp = priceChange > 0;
  const isPriceDown = priceChange < 0;
  const isPriceSame = priceChange === 0;

  const handleClickAssetType = (value: "GOLD" | "SILVER") => {
    setAssetType(value);
  };
  const assetTypeName = CONSTANT[assetType as keyof CONSTANTType]["TITLE"];
  return (
    <div className="sell_main sell_main01 shadow88">
      <div className="home_title">
        <h3> {assetTypeName} 시세</h3>
        <div>
          <span>
            {formatTime(new Date(marketPriceData.updatedAt), "H시 m분")} 기준
          </span>
        </div>
      </div>
      <div className="home_btn">
        <ul>
          <li
            style={{ lineHeight: "36px", width: "36px", height: "36px" }}
            className={`gold_btn ${assetType === "GOLD" ? "on" : ""}`}
            onClick={() => {
              handleClickAssetType("GOLD");
            }}
          >
            금
          </li>
          <li
            style={{ lineHeight: "36px", width: "36px", height: "36px" }}
            className={`silver_btn ${assetType === "SILVER" ? "on" : ""} `}
            onClick={() => {
              handleClickAssetType("SILVER");
            }}
          >
            은
          </li>
        </ul>
      </div>
      <div className="home_price">
        <ul>
          <li className="price_gram">
            <p className="spoqa bg_gray">1g</p>
            <p>
              <span className="spoqa">
                {numberWithCommas(Math.trunc(Number(currentPrice)))}
              </span>
              원
            </p>
            <p
              className={`market_price ${
                isPriceUp ? "plus" : isPriceDown ? "minus" : ""
              }`}
            >
              {isPriceSame ? (
                <>
                  0 <img src={iconSame} alt="가격 변동 없음" />
                </>
              ) : (
                <>
                  {isPriceUp && "+"}
                  {numberWithCommas(priceChange)}
                  <img
                    src={isPriceUp ? icoUp : icoDown}
                    alt="가격 변동 아이콘"
                  />
                </>
              )}
              {priceChangePercent}%
            </p>
          </li>
          <li className="price_don">
            <p className="spoqa bg_gray">1돈</p>
            <p>
              <span className="spoqa">
                {numberWithCommas(Math.trunc(parseInt(currentPrice) * 3.75))}
              </span>
              원
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MarketPriceSection;
