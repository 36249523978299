import React, { useMemo, useState } from "react";
import iconReft from "@assets/icons/icon_left.png";
import iconSearch from "@assets/icons/ico_search.png";
// import imgCaution from "@assets/images/img_caution.png";
import imgCaution from "@assets/images/img_caution_gr2.png";
import iconPhone from "@/assets/icons/ico_phone.png";
import useGetShop, { IShopInfo } from "./hooks/useFindShop";
import { ch2pattern, numberWithCommas } from "@/utils/utils";
import { useDispatch } from "react-redux";
import { closeBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";
import { showToast } from "@/features/toast/toastSlice";
import * as styles from "./style.css";

const FindShop = ({ onSelect, service, goodsId }: any) => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");

  const shopProps =
    service === "appraisal" ? { service } : { goodsId, service };
  const { ShopInfo } = useGetShop(shopProps);
  const handleClickClose = () => {
    dispatch(closeBottomSheet());
  };
  return (
    <div className="sub_bottom search_tab">
      <div className="sub_bottom_wrap">
        <div className="sub_top_fix">
          <div className="sub_top01">
            <h3 className="sub_bottom_tit">
              <img
                src={iconReft}
                alt=""
                className="icon_back"
                onClick={handleClickClose}
              />
              대리점 선택
            </h3>
          </div>
          <div className="sub_search">
            <input
              type="text"
              id="search-1"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="대리점을 검색하세요. (예: 종로, 대전)"
            />
            <label htmlFor="search-1">
              <img src={iconSearch} alt="" className="search_icon" />
            </label>
          </div>
        </div>
        <SearchResults
          hasResults={false}
          results={ShopInfo || []}
          onSelect={onSelect}
          keyword={keyword}
        />
      </div>
    </div>
  );
};

export default FindShop;

interface SearchResultsProps {
  hasResults: boolean;
  results?: Array<IShopInfo>;
  onSelect: (shop: IShopInfo) => void;
  keyword: string;
}

/**
 * 검색 결과
 * @param param0
 * @returns
 */
const SearchResults: React.FC<SearchResultsProps> = ({
  hasResults,
  results,
  onSelect,
  keyword,
}) => {
  const dispatch = useDispatch();

  const createFuzzyMatcher = (input: any) => {
    const pattern = input.split("").map(ch2pattern).join(".*?");
    return new RegExp(pattern);
  };

  const handleClickPhone = (phoneNumber: string) => {
    if (phoneNumber) {
      window.location.href = "tel:" + phoneNumber;
    } else {
      dispatch(
        showToast({
          message: "매장 전화번호가 등록되지 않았습니다.",
          icon: "caution",
        }),
      );
    }
  };

  const ShopList = useMemo(() => {
    let matchShops: any[] = [];
    if (results) {
      matchShops = results.filter(({ name }) => {
        return createFuzzyMatcher(keyword).test(name);
      });
    }

    if (matchShops.length === 0) {
      return (
        <div className="sub_search_c s_none">
          <img src={imgCaution} />
          <h3>검색 결과가 없습니다.</h3>
          {/* <p>ex. 0000 또는 00으로 다시 검색해 보세요!</p> */}
        </div>
      );
    } else {
      return (
        <div className="sub_search_c s_res">
          <h3 className="sub_search_ctit">검색 결과</h3>
          {matchShops?.map((result, index) => (
            <div key={index} className="shadow88">
              <ul>
                <ul onClick={() => onSelect(result)} style={{ flex: 1 }}>
                  <li className="box_num spoqa">
                    <h3>{index + 1}</h3>
                  </li>
                  <li className="box_txt">
                    <h3>{result.name}</h3>
                    {result.distance ? (
                      <p>
                        <span className="spoqa">
                          {numberWithCommas(result.distance)}
                        </span>
                        km
                      </p>
                    ) : null}
                    <span>{result.address}</span>
                  </li>
                </ul>
                <ul>
                  <button
                    className={styles.iconButton}
                    onClick={() => handleClickPhone(result.phoneNumber)}
                  >
                    <img src={iconPhone} />
                  </button>
                </ul>
              </ul>
            </div>
          ))}
        </div>
      );
    }
  }, [results, keyword]);
  return <> {ShopList} </>;
};
