import { useSelector, useDispatch } from "react-redux";
import iconX from "@assets/icons/icon_x.png";
import iconNotiBlue from "@assets/icons/ico_noti_blue.png";

import { RootState } from "@/app/store";
import { numberWithCommas } from "@/utils/utils";
import { showToast } from "@/features/toast/toastSlice";
import useAccountInfo from "@pages/myPage/hooks/useAccountInfo";
import { closeBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";
import colors from "@assets/styles/colors.css";

const VirtualAccountBottomSheet = () => {
  const dispatch = useDispatch();
  const { AccountInfo } = useAccountInfo("DEPOSIT");
  const purchaseInfo = useSelector((state: RootState) => state.purchase);

  const handleClickClose = () => {
    dispatch(closeBottomSheet());
  };

  const handleCopyToClipboard = async () => {
    if (AccountInfo?.virtualBankNumber) {
      try {
        await navigator.clipboard.writeText(AccountInfo.virtualBankNumber);
        dispatch(
          showToast({
            message: "계좌번호가 클립보드에 복사되었습니다.",
            icon: "success",
          }),
        );
      } catch (error) {
        dispatch(
          showToast({
            message: "클립보드 복사에 실패했습니다.",
            icon: "error",
          }),
        );
      }
    } else {
      dispatch(
        showToast({ message: "복사할 계좌번호가 없습니다.", icon: "caution" }),
      );
    }
  };

  return (
    <div className="sub_bottom03">
      <div className="sub_bottom03_wrap">
        <div className="sub_top01">
          <h3 className="sub_bottom_tit">가상 계좌 입금 및 결제</h3>
          <img
            src={iconX}
            alt=""
            className="close"
            onClick={handleClickClose}
          />
        </div>
        <h3 className="sub_bt3_tit">
          금방금방 계좌로 <br />
          <span className="fc-bl03">부족한 금액을 입금</span>해 주세요.
        </h3>

        <div className="bank_box01 mb08 arrow">
          <p>내가 지정한 입금계좌</p>
          <ul>
            <li>
              <p>{AccountInfo?.bankName}</p>
            </li>
            <li>
              <h3>{AccountInfo?.bankAccountNumber}</h3>
            </li>
          </ul>
        </div>
        <div className="bank_box02">
          <ul className="bank_box02_ul01 mb08">
            <li>
              <h3 className="fc-bl03">금방금방 가상계좌</h3>
            </li>
            <li>
              <p onClick={handleCopyToClipboard}>계좌번호 복사</p>
            </li>
          </ul>
          <ul className="bank_box02_ul02">
            <li>
              <p>
                <span>KEB 하나은행</span> (구 외환은행)
              </p>
            </li>
            <li>
              <h3>{AccountInfo?.virtualBankNumber}</h3>
            </li>
          </ul>
        </div>
        <p className="pay_noti">
          <img src={iconNotiBlue} />
          <span>입금 시 지정 계좌의 전용 은행 App</span>을 이용해 입금해주세요.
        </p>
        <ul className="tt_pay">
          <li>
            <h3 className="fc-bk01">부족한 금액</h3>
          </li>
          <li>
            <h3 className="fc-rd01">
              {numberWithCommas(purchaseInfo.depositPrice)}원
            </h3>
          </li>
        </ul>
      </div>
      <div className="sub03b_btn">
        <button
          onClick={handleClickClose}
          style={{
            backgroundColor: colors.buttonColor,
          }}
        >
          확인
        </button>
      </div>
    </div>
  );
};

export default VirtualAccountBottomSheet;
