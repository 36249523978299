import React, { useState, useEffect } from "react";
import buttonPlus from "../../assets/icons/button_plus.png";
import buttonMinus from "../../assets/icons/button_minus.png";
import { openModal, closeModal } from "../../features/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import modalNoti from "../../assets/images/modal_noti.png";
import { useNavigate } from "react-router-dom";
import {
  resetTenSecondAppraisal,
  selectTenAppraisal,
  setTenSecondAppraisal,
} from "../../features/appraisal-tensecond/appraisalTenSecondSlice";
import { routes } from "../../routes";
import * as style from "../appraisal-select-asset/style.css";
import DetailHeader from "../../components/layouts/DetailHeader";
import { formatTime } from "../../utils/utils";
import { RootState } from "../../app/store";
import { selectMarketPrice } from "../../features/market-price/marketPriceSlice";
import { InitialState } from "../../model/marketPrice";
import colors from "@assets/styles/colors.css";

/**
 * NOTE
 * 즉시 판매 예상가
 * = 입력 중량 * 현재 시세가격 - 수수료값(시세가격 * userFeeRate / 100)
 */

const purityMap: { [key: string]: number } = {
  "24k": 100,
  "22k": 91.6,
  "18k": 75.0,
  "14k": 58.5,
  "10k": 41.6,
  "99.9%": 100,
};

const getPurityValue = (purity: string) => {
  if (purityMap[purity] !== undefined) {
    return purityMap[purity] / 100;
  }
  const purityPercentage = parseFloat(purity.replace("%", ""));
  if (purityPercentage >= 60 && purityPercentage <= 99) {
    return purityPercentage / 100;
  }
  return 0;
};

const AppraisalSelectWeight = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tenAppraisalData = useSelector(selectTenAppraisal).tenSecondAppraisal;
  const [weightUnit, setWeightUnit] = useState<string>("g");
  const [weight, setWeight] = useState<number | undefined>(undefined);
  const [estimatedSalesPrice, setEstimatedSalesPrice] = useState(0); // 판매 예상가
  const [appraisalWeight, setAppraisalWeight] = useState(0); // 감정환산 중량
  const selected = tenAppraisalData;
  const { assetType, item, purity, appraisalCompletedList, weightRatePct } =
    selected;
  const marketPriceData = useSelector(selectMarketPrice);
  const { feeRate } = useSelector((state: RootState) => state.feeRate);
  const marketType = assetType
    .replace("GOLD", "gold")
    .replace("SILVER", "silver");
  const marketPrice = marketPriceData[marketType as keyof InitialState];
  const currentPrice = (marketPrice as any)?.current; //시세가
  const selectedAssetType = assetType
    .replace("GOLD", "금")
    .replace("SILVER", "은");

  const handleChangeWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      if (weightUnit === "g") {
        if (assetType === "GOLD") {
          setWeight(Math.round(value * 1000) / 1000);
        } else if (assetType === "SILVER") {
          setWeight(Math.round(value * 10) / 10);
        }
      } else if (weightUnit === "don") {
        if (assetType === "GOLD") {
          setWeight(Math.round(value * 10) / 10);
        } else if (assetType === "SILVER") {
          setWeight(Math.round(value));
        }
      }
    } else {
      setWeight(undefined);
    }
  };

  const handleClickWeight = (type: "plus" | "minus") => {
    setWeight((prevWeight) => {
      if (prevWeight === undefined) {
        return type === "plus" ? 1 : undefined;
      }
      let newWeight = type === "plus" ? prevWeight + 1 : prevWeight - 1;
      // 소수점 3자리까지 반올림
      newWeight = Math.round(newWeight * 1000) / 1000;
      // weight가 0 이상일 때만 반영
      return newWeight >= 0 ? newWeight : undefined;
    });
  };

  const handleClickNext = () => {
    if (weight && weight > 0) {
      dispatch(
        setTenSecondAppraisal({
          weight,
          weightUnit,
          estimatedSalesPrice,
          appraisalWeight,
          appraisalCompletedList: [
            ...appraisalCompletedList,
            {
              assetType,
              item,
              purity,
              weight,
              weightUnit,
              appraisalTime: formatTime(new Date(), "HH:mm:SS"),
              estimatedSalesPrice,
              appraisalWeight,
            },
          ],
        }),
      );
      navigate(routes.appraisalResult);
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleClickStop = () => {
    dispatch(resetTenSecondAppraisal());
    dispatch(closeModal());
    navigate(routes.sell);
  };

  const handleClickCancel = () => {
    dispatch(
      openModal(
        <div className="bg_gr_wrap">
          <div className="modal_wrap">
            <img src={modalNoti} alt="" />
            <h3>감정하신 모든 정보가 사라집니다.</h3>
            <p>그만하시겠어요?</p>
            <div className="modal_btn">
              <button className="bg_gr_btn" onClick={handleClickStop}>
                그만하기
              </button>
              <button
                className="bg_bk_btn"
                onClick={handleCloseModal}
                style={{
                  backgroundColor: colors.buttonColor,
                }}
              >
                계속하기
              </button>
            </div>
          </div>
        </div>,
      ),
    );
  };
  const handleClickBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    let weightCalculation = 0;
    if (weight) {
      const purityValue = getPurityValue(purity);
      const weightRateValue = weightRatePct / 100;
      weightCalculation = weight * weightRateValue * purityValue;

      if (assetType === "GOLD") {
        weightCalculation = Math.ceil(weightCalculation * 100) / 100; // 소수점 2자리까지 올림
      } else if (assetType === "SILVER") {
        weightCalculation = Math.ceil(weightCalculation); // 정수로 올림
      }
      setAppraisalWeight(weightCalculation);
    }

    const selectFeeRate = feeRate.find(
      (item) => item.assetType === assetType && item.configType === "SELL",
    )?.feeRate;
    if (selectFeeRate) {
      const feeValue = 1 - selectFeeRate; //수수료 값
      if (typeof weightCalculation !== "undefined") {
        const value =
          weightUnit === "g"
            ? weightCalculation * parseInt(currentPrice) * feeValue
            : weightCalculation * parseInt(currentPrice) * 3.75 * feeValue;

        setEstimatedSalesPrice(Math.trunc(value));
      }
    }
  }, [assetType, currentPrice, feeRate, weightUnit, weight]);

  return (
    <div className={style.wrap}>
      <DetailHeader
        title="10초 감정하기"
        cancelCallback={handleClickCancel}
        backCallback={handleClickBack}
      />
      <div className="sub_wrap02">
        <div className="sell_wrap01 sell_wrap04">
          <div className="check_option">
            <span className="check_option_sel">선택:</span>
            <span className="check_option_op gd">{selectedAssetType}</span>
            <span className="check_option_op gd">{item}</span>
            <span className="check_option_op gd">{purity}</span>
          </div>
          <div className="spoqa sell_ing">
            <p>
              <span>4</span>/4
            </p>
          </div>
          <div className="sell_option">
            <h3 className="op_tit">
              <span>단위</span>와 <span>중량</span>을 입력해 주세요.
            </h3>
            <p className="op_stit">기준 중량 단위를 선택해주세요.</p>
            {/* <ul>
              <li>
                <div className="op_pd_box">
                  <input
                    type="radio"
                    id="op_pd1"
                    name="op_pd"
                    value={"g"}
                    checked={weightUnit == "g"}
                    onChange={(e) => {
                      setWeightUnit(e.target.value);
                    }}
                  />
                  <label htmlFor="op_pd1">
                    <p>g</p>
                  </label>
                </div>
              </li>
              <li>
                <div className="op_pd_box">
                  <input
                    type="radio"
                    id="op_pd2"
                    name="op_pd"
                    value={"don"}
                    checked={weightUnit == "don"}
                    onChange={(e) => {
                      setWeightUnit(e.target.value);
                    }}
                  />
                  <label htmlFor="op_pd2">
                    <p>돈</p>
                  </label>
                </div>
              </li>
            </ul> */}
            <div className="select_btn">
              <ul>
                <li>
                  <input
                    type="radio"
                    name="select"
                    id="select01"
                    value="g"
                    checked={weightUnit == "g"}
                    onChange={(e) => {
                      setWeightUnit(e.target.value);
                      setWeight(undefined);
                    }}
                  />
                  <label htmlFor="select01">
                    <p>g</p>
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="select"
                    id="select02"
                    value="don"
                    checked={weightUnit == "don"}
                    onChange={(e) => {
                      setWeightUnit(e.target.value);
                      setWeight(undefined);
                    }}
                  />
                  <label htmlFor="select02">
                    <p>돈</p>
                  </label>
                </li>
              </ul>
            </div>
            <p className="op_stit">중량을 입력해주세요.</p>
            <div className="sell_option_nb">
              <button
                className="minus_bt"
                onClick={() => handleClickWeight("minus")}
              >
                <img src={buttonMinus} alt="-" />
              </button>
              <input
                type="number"
                placeholder="0"
                value={weight !== undefined ? weight : ""}
                onChange={handleChangeWeight}
                step={
                  weightUnit === "g"
                    ? assetType === "GOLD"
                      ? 0.001
                      : 0.01
                    : assetType === "GOLD"
                    ? 0.1
                    : 1
                }
              />
              <button
                className="plus_bt"
                onClick={() => handleClickWeight("plus")}
              >
                <img src={buttonPlus} alt="+" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="sub_btn_wrap">
        <button
          className={`sub_wrap02_btn ${weight && weight <= 0 ? "" : "on"}`}
          onClick={handleClickNext}
          style={{
            backgroundColor:
              weight && weight <= 0 ? "#ddd" : colors.buttonColor,
          }}
        >
          감정결과보기
        </button>
      </div>
    </div>
  );
};

export default AppraisalSelectWeight;
