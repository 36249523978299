import { useLayoutEffect, useRef, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./assets/css/reset.css";
import "./assets/css/layout.css";
import { routes } from "@/routes";
import Home from "./pages/home/Home";
import Count from "./pages/count/Count";
import NotFound from "./pages/NotFound";
import Sell from "./pages/sell/Sell";
import AppraisalSelectAsset from "./pages/appraisal-select-asset/AppraisalSelectAsset";
import AppraisalSelectItem from "./pages/appraisal-select-item/AppraisalSelectItem";
import AppraisalSelectPurity from "./pages/appraisal-select-purity/AppraisalSelectPurity";
import AppraisalSelectWeight from "./pages/appraisal-select-weight/AppraisalSelectWeight";
import AppraisalResult from "./pages/appraisal-result/AppraisalResult";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "./utils/sign";
import { APP_VERSION, SERVICE_TYPE, STORAGE_KEY } from "./utils/constants";
import { decodeJwt, scrollToRoot } from "./utils/utils";
import { selectAuth } from "./features/auth/authSlice";
import SignIn from "./pages/sign/SignIn";
import SignInId from "./pages/sign/SignInId";
import AppraisalAgency from "./pages/appraisal-agency/AppraisalAgency";
import Auth from "./hoc/auth";
import { showToast } from "./features/toast/toastSlice";
import * as APIS from "./services/apis";
import useInterval from "./hooks/useInterval";
import CompletedReservation from "./pages/appraisal-agency/components/CompletedReservation";
import AuthGateway from "./pages/auth/AuthGateway";
import TradeAsset from "./pages/assetTrade/TradeAsset";
import TradingWindow from "./pages/trading-window/TradingWindow";
import SecureKeypad from "./components/common/SecureKeypad";
import Buy from "./pages/buy/Buy";
import BuyGoodsDetail from "./pages/buy/components/GoodsDetail";
import BuyGoodsPurchase from "./pages/buy/components/GoodsPurchase";
// import SearchList from "@pages/buy/SearchList";
import MenuHome from "@pages/Menu/MenuHome";
import useRouteChangeTracker from "./hooks/useRouteChangeTracker";
import AccountManagement from "@pages/myPage/AccountManagement";
import AccountVerification from "@components/common/account-verification/AccountVerification";
import Deposit from "@pages/myPage/Deposit";
import Withdraw from "@pages/myPage/Withdraw";
import WithdrawComplete from "@pages/myPage/WithdrawComplete";
import SelfVerification from "@pages/auth/SelfVerification";
import EditProfile from "@pages/myPage/EditProfile";
import AuthRegisterPassword from "@pages/auth/AuthRegisterPassword";
import EditEmail from "@pages/editProfile/EditEmail";
import ReviewWidget from "@components/reviewWidget/ReviewWidget";
import Leave from "@pages/myPage/Leave";
import LeaveReason from "@pages/myPage/LeaveReason";
import LeaveSms from "@pages/myPage/LeaveSms";
import TradeHistory from "@pages/trade-history/TradeHistory";
import TradeCompleteDetail from "@pages/trade-history/components/TradeCompleteDetail";
import AppraiseDetail from "@pages/trade-history/components/AppraiseDetail";
import ShoppingDetail from "@pages/trade-history/components/ShoppingDetail";
import Notification from "@pages/myPage/Notification";
import Notice from "@pages/Menu/Notice";
import NoticeDetail from "@pages/Menu/NoticeDetail";
import TossSuccess from "@pages/buy/components/TossSuccess";
import TossFail from "@pages/buy/components/TossFail";
import PurchaseSuccess from "@pages/buy/components/PurchaseSuccess";
import Event from "@pages/Menu/Event";
import Inquire from "@pages/myPage/Inquire";
import StorageFee from "@pages/myPage/StorageFee";
import AcceptTerms from "@pages/auth/AcceptTerms";
import AuthRegisterEmail from "@pages/auth/AuthRegisterEmail";
import AuthRegisterShop from "@pages/auth/AuthRegisterShop";
import PurchaseFail from "@pages/buy/components/PurchaseFail";
import DeliveryAddressManagement from "@pages/myPage/DeliveryAddressManagement";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Loading from "./components/common/loading/Loading";
import Toast from "@components/common/toast/Toast";
import BasicModal from "@components/common/modal/BasicModal";
import Search from "@pages/buy/search/Search";
import ShopDetail from "@pages/myPage/ShopDetail";
import ReviewGateway from "@pages/auth/ReviewGateway";
import CustomGoBack from "./utils/CustomGoBack";
const isDev = import.meta.env.VITE_SERVICE_TYPE === "DEVELOPMENT";

/**
 * TODO
 * [ ] Auth hoc 컴포넌트 적용
 */
interface Token {
  roles: string;
}
const InitComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector(selectAuth);
  const { isSigned } = auth;
  const originSigned = useRef(isSigned);

  useLayoutEffect(() => {
    // App이 아닌 Web Browser에서 접근할 경우 금방금방.kr 로 리다이렉트
    let agent = sessionStorage.getItem(STORAGE_KEY.USER_AGENT);
    if (!agent?.includes("kumbang -") && SERVICE_TYPE === "PRODUCTION") {
      window.location.href = "https://금방금방.kr/";
    }
    // if (!location.pathname.includes(routes.authGateway)) {
    //   signIn();
    // }
    // if (!location.pathname.includes(routes.authGateway)) {
    signIn();
    // }
  }, []);

  useEffect(() => {
    if (window["GoldMarketApp"]) {
      const listener = (event: any) => {
        if (event.data?.eventType) {
          return;
        }
        const message = JSON.parse(event?.data) || {};
        const { command, param = {} } = message;

        if (command === "RESPONSE_LOGIN" && param.token) {
          const { roles = "" } = decodeJwt(param.token) as Token;
          if (roles.split(",").includes("ROLE_SELF_VERIFICATION_MALL")) {
            navigate(routes.acceptTerms, {
              state: {
                purpose: "MALL_TO_APP",
                token: param.token,
              },
            });
          } else {
            signIn(param.token);
            // dispatch(
            //   showToast({ message: "로그인 되었습니다.", icon: "success" }),
            // );
          }
        } else if (command === "RESPONSE_CHANGE_PASSWORD") {
          dispatch(
            showToast({
              message: "비밀번호가 변경되었습니다.",
              icon: "success",
            }),
          );
        }
      };

      document.addEventListener("message", listener);
      window.addEventListener("message", listener);

      return () => {
        document.removeEventListener("message", listener);
        window.removeEventListener("message", listener);
      };
    }
  }, []);

  useEffect(() => {
    if (originSigned.current && !isSigned) {
      // 로그아웃 이벤트 발생
      navigate(routes.main);
      dispatch({ type: "clear" });
      dispatch(showToast({ message: "로그아웃 되었습니다.", icon: "caution" }));
    }

    originSigned.current = isSigned;
  }, [isSigned]);

  useEffect(() => {
    if (window?.vreviewWidget) {
      window.vreviewWidget.initialize({
        uuid: import.meta.env.VITE_VREVIEW_UUID,
        originMemberId: "",
      });
      if (isSigned) {
        window.vreviewWidget.login(auth?.userId.toString());
      }
    }
  }, [window.vreviewWidget, isSigned, auth?.userId]);

  return null;
};

const VersionChecker = () => {
  const getVersion = (version: string) => {
    const reg = /(\d*)\.(\d*)\.(\d*)/;
    const match = version.match(reg);
    if (match) {
      const [FULL, MAJOR, MINOR, PATCH] = match;
      return { FULL, MAJOR, MINOR, PATCH };
    }
    return null;
  };

  const [checkCycle, setCheckCycle] = useState(3); // second
  useInterval(async () => {
    // API 호출 주기
    const cycle = await APIS.getEnvConfig("FRONT_REFRESH_PERIOD").then(
      ({ data: { success, data } }) => {
        if (success) {
          return data?.value;
        }
      },
    );
    // Front 최종 버전
    const latest = await APIS.getEnvConfig("FRONT_LATEST_VERSION_2").then(
      ({ data: { success, data } }) => {
        if (success) {
          return data?.value;
        }
      },
    );

    cycle && setCheckCycle(cycle);

    if (latest && APP_VERSION) {
      const appVersion = getVersion(APP_VERSION);
      const latestVersion = getVersion(latest);

      if (latestVersion && appVersion) {
        if (+latestVersion.MAJOR > +appVersion.MAJOR) {
          location.reload();
        } else if (+latestVersion.MAJOR === +appVersion.MAJOR) {
          if (+latestVersion.MINOR > +appVersion.MINOR) {
            location.reload();
          } else if (+latestVersion.MINOR === +appVersion.MINOR) {
            if (+latestVersion.PATCH > +appVersion.PATCH) {
              location.reload();
            }
          }
        }
      }
    }
  }, checkCycle * 1000);
  return null;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    scrollToRoot({ isSmooth: false });
  }, [pathname]);

  return null;
};

const GoogleAnalyticsTracker = () => {
  useRouteChangeTracker();
  return null;
};

const App = () => {
  return (
    <BrowserRouter>
      <VersionChecker />
      <ScrollToTop />
      <GoogleAnalyticsTracker />
      <InitComponent />
      <CustomGoBack />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={routes.menu} element={<MenuHome />} />
        <Route path={routes.editProfile} element={<EditProfile />} />
        <Route path={routes.editEmail} element={<EditEmail />} />
        <Route path={routes.notification} element={<Notification />} />
        <Route path={routes.reviewHistory} element={<ReviewWidget />} />
        <Route path={routes.leave} element={<Leave />} />
        <Route path={routes.leaveReason} element={<LeaveReason />} />
        <Route path={routes.leaveSms} element={<LeaveSms />} />
        <Route path={routes.commonSecureKeypad} element={<SecureKeypad />} />
        {/* 살래요 */}
        <Route path={routes.buy} element={<Buy />} />
        <Route
          path={`${routes.buyGoodsDetail}/:goodsId`}
          element={<BuyGoodsDetail />}
        />
        <Route
          path={`${routes.buyGoodsPurchase}/:goodsId`}
          element={<BuyGoodsPurchase />}
        />
        {/* 팔래요 */}
        <Route path={routes.sell} element={<Sell />} />
        <Route path={routes.tradeAsset} element={<TradeAsset />} />
        <Route path={routes.count} element={<Count />} />
        <Route path={routes.signIn} element={Auth(SignIn)} />
        <Route path={routes.signInId} element={Auth(SignInId)} />
        <Route path={routes.authGateway} element={Auth(AuthGateway)} />
        <Route
          path={routes.appraisalSelectAsset}
          element={<AppraisalSelectAsset />}
        />
        <Route
          path={routes.appraisalSelectItem}
          element={<AppraisalSelectItem />}
        />
        <Route
          path={routes.appraisalSelectPurity}
          element={<AppraisalSelectPurity />}
        />
        <Route
          path={routes.appraisalSelectWeight}
          element={<AppraisalSelectWeight />}
        />
        <Route path={routes.appraisalResult} element={<AppraisalResult />} />
        <Route path={routes.appraisalAgency} element={<AppraisalAgency />} />
        <Route
          path={routes.appraisalReservationComplete}
          element={<CompletedReservation />}
        />
        <Route path={routes.trading} element={<TradingWindow />} />
        <Route path={routes.buySearch} element={<Search />} />
        <Route
          path={routes.myAccountManagement}
          element={<AccountManagement />}
        />
        <Route
          path={routes.myAccountVerification}
          element={<AccountVerification />}
        />
        <Route path={routes.selfVerification} element={<SelfVerification />} />
        <Route
          path={routes.authRegisterPassword}
          element={<AuthRegisterPassword />}
        />
        <Route path={routes.deposit} element={<Deposit />} />
        <Route path={routes.withdraw} element={<Withdraw />} />
        <Route path={routes.withdrawComplete} element={<WithdrawComplete />} />
        <Route path={routes.tradeHistory} element={<TradeHistory />} />
        <Route
          path={routes.tradeCompleteDetail}
          element={<TradeCompleteDetail />}
        />
        <Route path={routes.appraiseDetail} element={Auth(AppraiseDetail)} />
        <Route path={routes.notice} element={<Notice />}></Route>
        <Route path={`${routes.notice}/:noticeId`} element={<NoticeDetail />} />
        <Route path={routes.event} element={<Event />}></Route>
        <Route path={`${routes.event}/:noticeId`} element={<NoticeDetail />} />

        <Route path={routes.inquire} element={<Inquire />} />
        <Route
          path={`/mypage/trade-history/shoppingDetail/:id`}
          element={Auth(ShoppingDetail)}
        />
        <Route path="/toss-success/:goodsId/:txId" element={<TossSuccess />} />
        <Route path="/toss-fail/:goodsId/:txId" element={<TossFail />} />
        <Route
          path={`${routes.purchaseSuccess}/:transId`}
          element={<PurchaseSuccess />}
        />
        <Route path={routes.purchaseFail} element={<PurchaseFail />} />
        <Route path={routes.storageFee} element={Auth(StorageFee)} />
        <Route path={routes.acceptTerms} element={<AcceptTerms />} />
        <Route path={routes.authRegisterShop} element={<AuthRegisterShop />} />
        <Route
          path={routes.authRegisterEmail}
          element={<AuthRegisterEmail />}
        />
        <Route
          path={routes.deliveryAddressManagement}
          element={<DeliveryAddressManagement />}
        />
        <Route path={`${routes.shopDetail}/:shopId`} element={<ShopDetail />} />
        <Route
          path={`${routes.reviewGateway}/:id`}
          element={<ReviewGateway />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/*  )} */}
      {isDev && <ReactQueryDevtools initialIsOpen={false} />}
      <BasicModal />
      <Loading />
      <Toast />
    </BrowserRouter>
  );
};

export default App;

/**
 * NOTE : Login 관련 화면은 적용 안해도 된다. app에서 구현되어 있음.
 */
