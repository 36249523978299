import ic_close from "@/assets/icons/icon_x.png";
import ic_recommend from "@/assets/images/ico_recommendation_36.png";
import ic_market from "@/assets/images/img_thunder_36.png";
import ic_calculate from "@/assets/images/img_calculator_36.png";
import { BottomSheet } from "@/components";
import { ButtonBase } from "@mui/material";
import { numberWithCommas } from "@/utils/utils";
import CommonKeypad from "@/components/common/CommonKeypad";
import { useDispatch, useSelector } from "react-redux";
import {
  closeBottomSheet,
  selectBottomSheet,
} from "@/features/bottom-sheet/bottomSheetSlice";
import { selectMarketPrice } from "@/features/market-price/marketPriceSlice";
import { InitialState } from "@/model/marketPrice";
import { postExpectTrade } from "@/services/apis";
import { showToast } from "@/features/toast/toastSlice";
import { orderConst } from "@/utils/constants";
import {
  currentPriceAccentBtn,
  gradientBackground,
  orderCheckBtn,
} from "../styles.css";
import { useEffect, useRef } from "react";

export type ModalType = "" | "priceType" | "price" | "amount" | "orderCheck";
// export type orderPriceType = "recommend" | "market" | "direct";
export type orderPriceType = "RECOMMEND" | "MARKET" | "LIMITS";

type Props = {
  onClose: Function;
  modalType: ModalType;
  inputs: any;
  resultInputs: any;
  onChangeInputs: Function;
  onChangeTemptoResult: Function;
  onChangeOrderType: Function;
  assetType: string;
  posibleprice: number;
  minPrice: number;
  maxPrice: number;
  totalPrice: number;
  selectOrderType: string;
  feeValue?: number;
  onClickConfirm: Function;
  tradeType: string;
  onChangeTotalPrice: Function;
  baseWeight?: string;
};

/**
 * NOTE:
 * * 변수 명, 사용 용도
 * onClose: modal open / close
 * modalType
 *  - 주문 방법 선택(priceType)
 *  - 가격 입력(price)
 *  - 수량 입력(amount)
 *  - 매수 / 매도 가격 확인(orderCheck)
 * inputs (바텀시트 임시 입력값)
 *  - 가격(price) / 수량(amount) 값
 * resultInputs (바텀시트 결과값)
 *  - 가격(price) / 수량(amount) 값
 * onChangeInputs: 가격 수량 변경 함수
 * onChangeOrderType: 주문 방법 변경 함수
 * assetType: 금 / 은
 * posibleprice:
 * minPrice: 하한가
 * maxPrice: 상한가
 * selectOrderType: 선택된 주문 방법
 * feeValue: 매수 / 매도 수수료
 * onClickConfirm:
 */

function TradeModal({
  onClose,
  modalType,
  inputs,
  resultInputs,
  onChangeInputs,
  onChangeTemptoResult /* 임시값에서 결과값으로 변경 함수 */,
  onChangeOrderType,
  assetType,
  posibleprice,
  minPrice,
  maxPrice,
  totalPrice,
  selectOrderType,
  feeValue,
  onClickConfirm,
  tradeType,
  onChangeTotalPrice,
  baseWeight = "g",
}: Props) {
  const dispatch = useDispatch();
  const marketPriceData = useSelector(selectMarketPrice);
  const marketType = assetType
    .replace("GOLD", "gold")
    .replace("SILVER", "silver");
  const marketPrice = marketPriceData[marketType as keyof InitialState];
  const currentPrice = (marketPrice as any)?.current;
  const assetTypeName = assetType.replace("GOLD", "금").replace("SILVER", "은");
  const tradeTypeName = tradeType
    .replace("SELL", "판매")
    .replace("BUY", "구매");

  const contentRef = useRef<HTMLDivElement>(null);
  const { isOpen } = useSelector(selectBottomSheet);

  useEffect(() => {
    // console.log(contentRef);
    if (contentRef.current) {
      // 바텀시트 열리면 상단 이동
      contentRef.current.scrollTop = 0;
    }
  }, [isOpen]);

  const handleClickKeypad = (item: any) => {
    let data = inputs[`${modalType}`];

    if (typeof item === "number") {
      if (data === "0") {
        if (item !== 0) {
          // "0"으로 시작하고 다른 숫자가 올 때, "0"을 제거하고 입력된 숫자로 대체
          onChangeInputs(`${item}`, modalType);
        } else {
          // "0"이 연속으로 입력되지 않도록 처리
          onChangeInputs(data, modalType);
        }
      } else {
        onChangeInputs(data.concat(`${item}`), modalType);
      }
    } else if (item === "delete") {
      onChangeInputs(data.slice(0, -1), modalType);
    } else if (item === "deleteAll") {
      onChangeInputs("", modalType);
    } else if (item === "decimal") {
      if (!data.includes(".")) {
        onChangeInputs(data.concat(`.`), modalType);
      }
    }
  };

  const handleClickInput = () => {
    const amount = +inputs["amount"];
    if (modalType === "amount") {
      if (
        ["0", "0."].includes(inputs["amount"]) ||
        /^0\.0+$/.test(inputs["amount"])
      ) {
        dispatch(
          showToast({ message: "중량을 입력해 주세요", icon: "caution" }),
        );
        return;
      }
      if (assetType === "GOLD") {
        if (baseWeight === "g") {
          if (Math.abs(Number((amount * 1000).toFixed(10)) % 5) > 1e-10) {
            dispatch(
              showToast({
                message: "0.005g 단위로 중량을 입력해주세요.",
                icon: "caution",
              }),
            );
            return;
          }
        } else {
          if (Math.abs(Number((amount * 10).toFixed(10)) % 1) > 1e-10) {
            dispatch(
              showToast({
                message: "0.1g 단위로 중량을 입력해주세요.",
                icon: "caution",
              }),
            );
            return;
          }
        }
      } else {
        if (Math.abs(Number((amount * 10).toFixed(10)) % 5) > 1e-10) {
          dispatch(
            showToast({
              message: "0.5g 단위로 중량을 입력해주세요.",
              icon: "caution",
            }),
          );
          return;
        }
      }
    } else if (modalType === "price") {
      let price = parseFloat(inputs["price"]);
      if (price < 1) {
        dispatch(
          showToast({ message: "가격을 입력해 주세요", icon: "caution" }),
        );
        return;
      }
      if (+price < minPrice) {
        dispatch(
          showToast({
            message: `하한가(${numberWithCommas(
              minPrice,
            )}원) 이상으로 입력해주세요.`,
            icon: "caution",
          }),
        );
        return false;
      }

      if (+price > maxPrice) {
        dispatch(
          showToast({
            message: `상한가(${numberWithCommas(
              maxPrice,
            )}원) 이하로 입력해주세요.`,
            icon: "caution",
          }),
        );
        return false;
      }
      if (assetType === "GOLD") {
        if (price % 100 !== 0) {
          dispatch(
            showToast({
              message: "100원단위로 가격을 입력해주세요.",
              icon: "caution",
            }),
          );
          return;
        }
      } else {
        if (price % 5 !== 0) {
          dispatch(
            showToast({
              message: "5원단위로 가격을 입력해주세요.",
              icon: "caution",
            }),
          );
          return;
        }
      }
    }
    if (modalType === "amount" && selectOrderType === "MARKET") {
      if (inputs[`${modalType}`] === "" || inputs[`${modalType}`] === null) {
        dispatch(
          showToast({
            message: "값을 입력해주세요.",
            icon: "caution",
          }),
        );
      } else {
        const payload = {
          tradeType: tradeType,
          assetType: assetType,
          orderType: selectOrderType,
          requestGram:
            baseWeight === "g"
              ? inputs[`${modalType}`]
              : baseWeight === "don"
              ? `${inputs[`${modalType}`] * 3.75}`
              : inputs[`${modalType}`],
        };
        postExpectTrade(payload).then(
          ({ data: { success, data, message } }) => {
            if (success) {
              const fee = tradeType === "BUY" ? data.tradeFee : -data.tradeFee;
              onChangeTemptoResult(data.orderPrice, "price");
              onChangeTemptoResult(inputs[modalType], "amount");
              onChangeTotalPrice(data?.tradeKrw, fee);
              dispatch(closeBottomSheet());
            } else {
              dispatch(showToast({ message: message, icon: "error" }));
            }
          },
        );
      }
    } else {
      onChangeTemptoResult(inputs[modalType], modalType);
      dispatch(closeBottomSheet());
    }
  };

  const formatAmount = (
    amount: string,
    assetType: string,
    targetWeight: string, // 표시할 단위
  ): string => {
    const numericAmount = parseFloat(amount);
    let formattedAmount: number = 0;
    if (baseWeight === "don") {
      if (targetWeight === "g") {
        if (assetType === "GOLD") {
          formattedAmount = Number((numericAmount * 3.75).toFixed(3));
        }
      } else {
        if (assetType === "GOLD") {
          formattedAmount = Number(numericAmount.toFixed(1));
        }
      }
    } else {
      if (targetWeight === "don") {
        formattedAmount = Number((numericAmount / 3.75).toFixed(3));
      } else {
        if (assetType === "GOLD") {
          formattedAmount = Number(numericAmount.toFixed(3));
        } else {
          formattedAmount = Number(numericAmount.toFixed(1));
        }
      }
    }
    return numberWithCommas(formattedAmount);
  };

  const handleClickOrder = () => {
    onClickConfirm();
  };
  const handleClickClose = () => {
    dispatch(closeBottomSheet());
  };
  return (
    <BottomSheet>
      {/* <div className="bg_gr_wrap">
      </div> */}
      {modalType === "priceType" && (
        <div className="sub_bottom rv_tab sell_tab">
          <div className="sub_bottom_wrap">
            <div className="sub_top_fix">
              <div className="sub_top01">
                <h3 className="sub_bottom_tit">주문 방법 선택</h3>
                <ButtonBase
                  onClick={() => {
                    onClose();
                  }}
                >
                  <img src={ic_close} alt="" className="close" />
                </ButtonBase>
              </div>
            </div>
            <div className="bottom_box_wrap">
              <ButtonBase
                style={{ display: "flex", width: "100%" }}
                onClick={() => {
                  onChangeOrderType("RECOMMEND");
                  dispatch(closeBottomSheet());
                }}
              >
                <div
                  className={`shadow88 bottom_box ${
                    selectOrderType === "RECOMMEND" ? "checked" : ""
                  }`}
                  style={{ width: "100%" }}
                >
                  <div className="box_tit">
                    <img
                      src={ic_recommend}
                      alt="recommend_price_selection_icon"
                    />
                    <div className="box_cont">
                      <h3>추천가</h3>
                      <p>
                        조금 더
                        {`${
                          tradeType === "SELL"
                            ? " 비싸게 팔래요"
                            : " 저렴하게 살래요"
                        }.`}
                      </p>
                    </div>
                  </div>
                </div>
              </ButtonBase>
              <ButtonBase
                style={{ display: "flex", width: "100%" }}
                onClick={() => {
                  onChangeOrderType("MARKET");
                  dispatch(closeBottomSheet());
                }}
              >
                <div
                  className={`shadow88 bottom_box ${
                    selectOrderType === "MARKET" ? "checked" : ""
                  }`}
                  style={{ width: "100%" }}
                >
                  <div className="box_tit">
                    <img src={ic_market} alt="ic_market" />
                    <div className="box_cont">
                      <h3>시장가</h3>
                      <p>
                        즉시 {`${tradeType === "SELL" ? "팔래요" : "살래요"}.`}
                      </p>
                    </div>
                  </div>
                </div>
              </ButtonBase>

              <ButtonBase
                style={{ display: "flex", width: "100%" }}
                onClick={() => {
                  onChangeOrderType("LIMITS");
                  dispatch(closeBottomSheet());
                }}
              >
                <div
                  className={`shadow88 bottom_box ${
                    selectOrderType === "LIMITS" ? "checked" : ""
                  }`}
                  style={{ width: "100%" }}
                >
                  <div className="box_tit">
                    <img src={ic_calculate} alt="ic_calculate" />
                    <div className="box_cont">
                      <h3>직접 입력</h3>
                      <p>
                        내가 원하는 가격에
                        {` ${tradeType === "SELL" ? "팔래요" : "살래요"}.`}
                      </p>
                    </div>
                  </div>
                </div>
              </ButtonBase>
            </div>
          </div>
        </div>
      )}
      {modalType === "price" && (
        <div className="sub_bottom rv_tab keypad_tab02">
          <div className="sub_bottom_wrap">
            <div className="sub_top_fix">
              <div className="sub_top01">
                <h3 className="sub_bottom_tit">가격 입력</h3>
                <ButtonBase
                  onClick={() => {
                    onClose();
                  }}
                >
                  <img src={ic_close} alt="" className="close" />
                </ButtonBase>
              </div>
            </div>
            <div className="bottom_box_wrap">
              {/* <p className="keypad_tit">직접입력</p> */}
              <input
                className="keypad_input"
                type="text"
                placeholder="가격 입력"
                value={numberWithCommas(inputs[modalType])}
                onChange={(e) => {
                  let values = e.target.value;
                  values = values.replace(/,/gi, "");
                  let regexp = /^[0-9]*$/;
                  if (!regexp.test(values)) {
                    return;
                  } else {
                    values = values.replace(/,/gi, "");
                  }
                  onChangeInputs(values, modalType);
                }}
              />
              <ul className="keypad_per keypad_per02">
                <li className={currentPriceAccentBtn}>{`현: ${numberWithCommas(
                  currentPrice,
                )}원`}</li>
                <li className="keypad_per_gr">{`상: ${numberWithCommas(
                  maxPrice,
                )}원`}</li>
                <li className="keypad_per_gr">{`하: ${numberWithCommas(
                  minPrice,
                )}원`}</li>
              </ul>
              <CommonKeypad
                onClickKeypad={handleClickKeypad}
                modalType="price"
              />
            </div>
          </div>
          <button
            className={`sub_wrap03_btn ${tradeType === "BUY" ? "buy" : ""} ${
              !["0", "", "0."].includes(inputs[modalType]) &&
              !/^0\.0+$/.test(inputs[modalType])
                ? "on"
                : ""
            }`}
            onClick={handleClickInput}
          >
            입력하기
          </button>
        </div>
      )}
      {modalType === "amount" && (
        <div className="sub_bottom rv_tab keypad_tab">
          <div className="sub_bottom_wrap">
            <div className="sub_top_fix">
              <div className="sub_top01">
                <h3 className="sub_bottom_tit">중량 입력</h3>
                <ButtonBase
                  onClick={() => {
                    onClose();
                  }}
                >
                  <img src={ic_close} alt="" className="close" />
                </ButtonBase>
              </div>
            </div>
            <div className="bottom_box_wrap">
              {/* <p className="keypad_tit">{orderConst[selectOrderType]}로 거래</p> */}
              <input
                className="keypad_input"
                type="text"
                placeholder="중량 입력"
                value={numberWithCommas(inputs[modalType])}
                onChange={(e) => {
                  let values = e.target.value;
                  let decimalAlreadyExists = values.includes(".");
                  let lastChar = values.slice(-1);
                  if (decimalAlreadyExists) {
                    if (
                      lastChar === "." &&
                      values.indexOf(".") !== values.length - 1
                    ) {
                      return;
                    } else {
                      if (values === "0" && lastChar !== ".") {
                        return;
                      }
                    }
                  }
                  // 입력된 값이 소수점 이하 세 자리를 초과하는지 확인
                  let indexOfDecimal = values.indexOf(".");
                  if (
                    indexOfDecimal !== -1 &&
                    values.length - indexOfDecimal > 4
                  ) {
                    return;
                  }
                  onChangeInputs(values, modalType);
                }}
              />
              {tradeType === "SELL" ? (
                <span className="keypad_price">
                  보유 {assetTypeName} {numberWithCommas(posibleprice)}g
                </span>
              ) : (
                <span className="keypad_price"></span>
              )}
              <CommonKeypad
                onClickKeypad={handleClickKeypad}
                modalType="amount"
              />
            </div>
          </div>
          <button
            className={`sub_wrap03_btn ${tradeType === "BUY" ? "buy" : ""} ${
              !["0", "", "0."].includes(inputs[modalType]) &&
              !/^0\.0+$/.test(inputs[modalType])
                ? "on"
                : ""
            }`}
            onClick={handleClickInput}
          >
            입력하기
          </button>
        </div>
      )}
      {modalType === "orderCheck" && (
        <div className="sub_bottom rv_tab receipt_tab" ref={contentRef}>
          <div className={gradientBackground} />
          <div className="sub_bottom_wrap" style={{ paddingBottom: "96px" }}>
            <div className="sub_top_fix">
              <div className="sub_top01">
                <h3 className="sub_bottom_tit">내역 확인</h3>
                <img
                  src={ic_close}
                  alt=""
                  className="close"
                  onClick={handleClickClose}
                />
              </div>
            </div>
            <div className="bottom_box_wrap">
              <h3 className={"receipt_tit"}>
                <span
                  className={`${tradeType !== "SELL" ? "fc-rd01" : "fc-bl01"}`}
                >{`${assetTypeName},  ${formatAmount(
                  resultInputs.amount,
                  assetType,
                  "g",
                )}g ${tradeTypeName}`}</span>
                합니다.
                <br />
                아래 내용이 맞나요?
              </h3>
              <div className="receipt_wrap">
                <div className="receipt_top">
                  <ul className="receipt_ul01">
                    <li className="receipt_left">자산</li>
                    <li className="receipt_right">
                      <span>{assetTypeName}</span>
                    </li>
                  </ul>
                  <ul className="receipt_ul01">
                    <li className="receipt_left">주문 방식</li>
                    <li className="receipt_right">
                      <span>{orderConst[selectOrderType]}</span>
                    </li>
                  </ul>
                  <ul className="receipt_ul02">
                    <li className="receipt_left">1g 가격</li>
                    <li className="receipt_right">
                      <p className="won">
                        {selectOrderType !== "MARKET" ? (
                          <>
                            <span>
                              {numberWithCommas(Math.trunc(resultInputs.price))}
                            </span>
                            원
                          </>
                        ) : (
                          "즉시 체결 가격"
                        )}
                      </p>
                      {selectOrderType !== "MARKET" && (
                        <p className="don">
                          <span>
                            {numberWithCommas(
                              Math.trunc(resultInputs.price * 3.75),
                            )}
                          </span>
                          원/돈
                        </p>
                      )}
                    </li>
                  </ul>
                  <ul className="receipt_ul02">
                    <li className="receipt_left">{`${tradeTypeName}중량`}</li>
                    <li className="receipt_right">
                      <p className="won">
                        <span>
                          {formatAmount(resultInputs.amount, assetType, "g")}
                        </span>
                        g
                      </p>
                      <p className="don">
                        <span>
                          {formatAmount(resultInputs.amount, assetType, "don")}
                        </span>
                        돈
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="receipt_bottom">
                  <ul className="receipt_ul01" style={{ height: "24px" }}>
                    <li className="receipt_left">
                      {`${tradeTypeName} ${
                        selectOrderType === "MARKET" ? "예상 " : ""
                      }금액`}
                    </li>
                    <li className="receipt_right">
                      <span>{numberWithCommas(totalPrice)}</span>원
                    </li>
                  </ul>
                  <ul className="receipt_ul01" style={{ height: "24px" }}>
                    <li className="receipt_left">
                      {selectOrderType === "MARKET" && "예상 "}수수료
                    </li>
                    <li className="receipt_right">
                      <span>{feeValue && numberWithCommas(feeValue)}</span>원
                    </li>
                  </ul>
                </div>
                <div className="receipt_price">
                  <ul>
                    <li className="receipt_left">
                      {`최종 ${tradeTypeName} ${
                        selectOrderType === "MARKET" ? "예상 " : ""
                      } 금액`}
                    </li>
                    <li
                      className={`${
                        tradeType !== "SELL" ? "fc-rd01" : ""
                      } receipt_right`}
                    >
                      {feeValue &&
                        numberWithCommas(Number(totalPrice + feeValue))}
                      원
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <button
            className={`sub_wrap02_btn on ${orderCheckBtn}`}
            onClick={handleClickOrder}
          >
            확인
          </button>
        </div>
      )}
    </BottomSheet>
  );
}

export default TradeModal;
