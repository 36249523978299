import React from "react";
import * as styles from "../styles.css";
import { useDispatch } from "react-redux";
import imgInfo from "@assets/icons/ico_info_24px.png";
import { closeModal } from "@/features/modal/modalSlice";
import { numberWithCommas } from "@/utils/utils";

interface IPriceInfo {
  prevPrice: number;
  nextPrice: number;
}

const ChangePriceModal: React.FC<IPriceInfo> = ({ prevPrice, nextPrice }) => {
  const dispatch = useDispatch();

  const handleClickContinue = () => {
    dispatch(closeModal());
  };

  return (
    <div className="bg_gr_wrap">
      <div className="modal_wrap">
        <div className={styles.flexColumnCenter}>
          <img src={imgInfo} alt="에러 아이콘" className={styles.errorIcon} />
          <div className={styles.title}>
            <span className={styles.titleBold}> 시장가 변동</span>
            으로
          </div>
          <div className={styles.title}>
            가격이 <span className={styles.titleBold}>변경</span>되었습니다
          </div>
          <div className={styles.priceWrapper}>
            <div className={styles.prevPrice}>
              이전 가격
              <div>
                <span className={styles.prevPriceText}>
                  {numberWithCommas(prevPrice || 0)}
                </span>
                <span className={styles.won}>원</span>
              </div>
            </div>
            <div className={styles.nextPrice}>
              변경된 가격
              <div>
                <span className={styles.nextPriceText}>
                  {numberWithCommas(nextPrice)}
                </span>
                <span className={`${styles.won} ${styles.wonRed}`}>원</span>
              </div>
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <button
              className={styles.purchaseBtn}
              onClick={handleClickContinue}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePriceModal;
