import useCustomQuery from "@/hooks/queries/useCustomQuery";
import * as APIS from "@/services/apis";
import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { routes } from "@/routes";

export interface IShoppingGetDetailResponse {
  earliestVisitDate: string;
  goodsTrans: {
    goodsId: number;
    goodsTransId: number;
    goodsImage: string;
    brand: string;
    goodsName: string;
    totalGram: number;
    qty: number;
    optionName: string;
    goodsTransStatus: string;
    goodsStatusDescription: string;
    createdAt: string;
    orderNumber: string;
    assetType: string;
    cashReceipt: null;
    paymentPrice: number;
    isDelivery: boolean;
    isPost: boolean;
    canModifyDelivery: boolean;
    isShopTaken: boolean;
  };
  shopInfo: {
    shopName: string;
    businessHr: string;
    visitDate: string;
    address: string;
    addressDetail: string;
    phoneNumber: string;
  };
  payment: {
    gram: number;
    krw: number;
    paymentPrice: number;
    point: number;
    sellingPrice: number;
    depositPrice: number;
  };
  history: [
    {
      id: number;
      status: string;
      description: string;
      createdAt: string;
    },
  ];
  delivery: {
    address: string;
    addressDetail: string;
    addressName: string;
    collectDate: null;
    deliveryCorp: null;
    id: number;
    memo: string;
    mobile: string;
    passwordDoor: null;
    phone: string;
    postCode: string;
    receiver: string;
    regiNo: null;
  };
  deliveryTrace: null;
}

const useShoppingDetail = (transId: string) => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getShoppingDetail(transId);
      dispatch(hideLoading());
      return response.data.data as IShoppingGetDetailResponse;
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, refetch } =
    useCustomQuery<IShoppingGetDetailResponse>(
      [routes.shoppingDetail, transId],
      fetchDetails,
    );

  return {
    ShoppingDetail: data,
    isLoading,
    error,
    ShoppingDetailRefetch: refetch,
  };
};
export default useShoppingDetail;
