import { getCurrentClosingMarketPrice } from "../../services/apis";
import { useDispatch } from "react-redux";
import { updateMarketPrice } from "../../features/market-price/marketPriceSlice";
import { GoldPrice, SilverPrice } from "../../model/marketPrice";

interface MarketData {
  current: string;
  closing: string;
}

const parseData = ({
  data: { data },
}: {
  data: { data: string };
}): MarketData => {
  const [current, closing] = data.split(",");
  return { current, closing };
};

export const useRefreshMarketPrice = () => {
  const dispatch = useDispatch();

  const refreshMarketPrice = () => {
    const getGoldPrice = getCurrentClosingMarketPrice("GOLD").then(parseData);
    const getSilverPrice =
      getCurrentClosingMarketPrice("SILVER").then(parseData);

    Promise.all([getGoldPrice, getSilverPrice]).then(
      ([
        goldData = { current: 0, closing: 0 },
        silverData = { current: 0, closing: 0 },
      ]) => {
        const updatedAt = new Date();
        const gold: GoldPrice = {
          current: Number(goldData.current),
          closing: Number(goldData.closing),
        };
        const silver: SilverPrice = {
          current: Number(silverData.current),
          closing: Number(silverData.closing),
        };
        const payload = { updatedAt, gold, silver };
        dispatch(updateMarketPrice(payload));
      },
    );
  };

  return refreshMarketPrice;
};
