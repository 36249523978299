import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectModal } from "../../../features/modal/modalSlice";

const BasicModal = () => {
  const { isOpen, content } = useSelector(selectModal);

  useEffect(() => {
    if (isOpen === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <div style={{ display: isOpen ? "block" : "none" }}>
      <div>{content}</div>
    </div>
  );
};

export default BasicModal;
