import { formatTime, numberWithCommas } from "@/utils/utils";
import GoodsCardInfo from "@pages/buy/components/GoodsCardInfo";
import { GoodsListInfo } from "@pages/buy/hooks/useGoodsList";
import { useState } from "react";
import { SEARCH, sortByDropDown } from "../Search.const";

interface SearchSuccessProps {
  matchedItems: GoodsListInfo | undefined;
}

const SearchSuccess = (props: SearchSuccessProps) => {
  const { matchedItems } = props;
  const [dropDown, setDropDown] = useState<{
    isOpen: boolean;
    item: (typeof SEARCH.DROP_DOWN_ITEMS)[number];
  }>({
    isOpen: false,
    item: "인기순",
  });

  const sortedGoodsList = sortByDropDown(matchedItems, dropDown.item);
  return (
    <>
      <div className="search_box01_list">
        <p className="search_box_t02">
          <span>{numberWithCommas(matchedItems?.length ?? 0)}개</span>의 검색
          결과
        </p>
      </div>
      <div className="buy_main01_menu">
        <div className="buy_main01">
          <div className="buy_main01_top">
            <span className="time">오늘 {formatTime()}시 기준</span>
            <div className="dropdown_filter">
              <div className="drop-down">
                <div className="selected">
                  <a
                    onClick={() =>
                      setDropDown((prev) => ({
                        ...prev,
                        isOpen: !prev.isOpen,
                      }))
                    }
                  >
                    <span>{dropDown.item}</span>
                  </a>
                </div>
                <div className="options">
                  {dropDown.isOpen && (
                    <ul>
                      {SEARCH.DROP_DOWN_ITEMS.map((item) => {
                        return (
                          <li key={item}>
                            <a
                              onClick={() =>
                                setDropDown({ isOpen: false, item })
                              }
                            >
                              {item}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GoodsCardInfo goodsListData={sortedGoodsList} />
    </>
  );
};

export default SearchSuccess;
