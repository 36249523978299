import imgCaution from "@assets/images/img_caution_gr2.png";
import { SEARCH } from "../Search.const";
import { useSearch } from "../hooks/useSearch";

interface SearchFailedProps {
  hanldeSearchInput: ReturnType<typeof useSearch>["hanldeSearchInput"];
  handleSearch: (searchInput: string) => () => void;
}

const SearchFailed = (props: SearchFailedProps) => {
  const { hanldeSearchInput, handleSearch } = props;

  const handleClick = (clickedKeyword: string) => {
    hanldeSearchInput(clickedKeyword, "WORD");
    handleSearch(clickedKeyword)();
  };

  return (
    <>
      <div className="search_box01 none02">
        <p className="search_none02">
          <img src={imgCaution} /> 검색 결과가 없습니다.
        </p>
      </div>
      <div className="search_box02">
        <p className="search_box_t">추천 중량</p>
        <ul>
          {SEARCH.RECOMMEND_KEYWORDS.map((keyword) => {
            return (
              <li key={keyword} onClick={() => handleClick(keyword)}>
                <p>{keyword}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
export default SearchFailed;
