import iconHomeOn from "@assets/icons/ico_home_on_24px.png";
import iconHomeOff from "@assets/icons/ico_home_off_24px.png";
import iconShoppingOn from "@assets/icons/ico_shoppingbag_on_24px.png";
import iconShoppingOff from "@assets/icons/ico_shoppingbag_off_24px.png";
import iconCoinOn from "@assets/icons/ico_coin_on_24px.png";
import iconCoinOff from "@assets/icons/ico_coin_off_24px.png";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "@/routes";

const BottomNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isSell = location.pathname === routes.sell;
  const isBuy = location.pathname === routes.buy;
  const isHome = location.pathname === "/";

  const handleNavigate = (where: string) => {
    if (where === "sell") {
      navigate(routes.sell);
    } else if (where === "buy") {
      navigate(routes.buy);
    } else {
      navigate("/");
    }
  };

  return (
    <div className="bottom_nav">
      <ul>
        <li onClick={() => handleNavigate("buy")}>
          <img src={isBuy ? iconShoppingOn : iconShoppingOff} alt="살래요" />
          <p className={isBuy ? "selected" : ""}>살래요</p>
        </li>
        <li onClick={() => handleNavigate("sell")}>
          <img src={isSell ? iconCoinOn : iconCoinOff} alt="팔래요" />
          <p className={isSell ? "selected" : ""}>팔래요</p>
        </li>
        <li onClick={() => handleNavigate("home")}>
          <img src={isHome ? iconHomeOn : iconHomeOff} alt="홈" />
          <p className={isHome ? "selected" : ""}>홈</p>
        </li>
      </ul>
    </div>
  );
};

export default BottomNavigation;
