import React, { useState, useEffect } from "react";
import IconLeft from "@assets/icons/icon_left.png";
import PassbookImg from "@assets/images/img_passbook_03_96px.png";
import SecurityImg from "@assets/images/img_security2.png";
import SafeIcon from "@assets/icons/ico_safe_18px.png";
import ImgPassbook0393 from "@assets/images/img_passbook_03_93px.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  closeBottomSheet,
  openBottomSheet,
} from "@/features/bottom-sheet/bottomSheetSlice";
import SelectBank from "./components/SelectBank";
import useBankList, { IBankInfo } from "./hooks/useBank";
import useBankAccountAssign, { IBankProps } from "./hooks/useBankAccountAssign";
import { showToast } from "@/features/toast/toastSlice";
import * as styles from "./styles.css";
import { closeModal, openModal } from "@/features/modal/modalSlice";
import useAccountInfo from "@pages/myPage/hooks/useAccountInfo";
import useAccountChange from "@pages/myPage/hooks/useAccountChange";
import colors from "@assets/styles/colors.css";

interface AccountVerificationLocationProps {
  bankType: "DEPOSIT" | "WITHDRAW";
  isAcccountChange?: boolean;
  fromPurchase?: boolean;
}

const AccountVerification: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation() as { state: AccountVerificationLocationProps };

  const bankType = location.state.bankType;
  const fromPurchase = location.state.fromPurchase;
  const isAcccountChange = location.state.isAcccountChange;

  const [step, setStep] = useState<number>(1);
  const [selectedBank, setSelectedBank] = useState<IBankInfo | null>(null);
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const { BankList } = useBankList();

  const { AccountInfo: withdrawAccount } = useAccountInfo("WITHDRAW");
  const bankAccountAssign = useBankAccountAssign();
  const bankAccountChange = useAccountChange("DEPOSIT", () => navigate(-1));

  const hasWithdrawAccount =
    withdrawAccount?.bankAccountStatus === "COMPLETED" &&
    !!withdrawAccount?.bankAccountNumber;

  const handleOpenSelectBank = () => {
    dispatch(openBottomSheet());
  };

  const handleBankChange = (bank: IBankInfo) => {
    setSelectedBank(bank);
    dispatch(closeBottomSheet());
  };

  const handleAccountNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = e.target.value;

    if (inputValue.includes("-") || inputValue.includes(".")) {
      return;
    }

    if (!isNaN(Number(inputValue))) {
      setAccountNumber(inputValue);
    }
  };

  const handlePrevStep = () => {
    if (step === 2) {
      setStep(1);
    } else {
      // 그 전 페이지로 보내기
      navigate(-1);
    }
  };

  const handleNextStep = () => {
    if (step === 1) {
      // 주문에서 왔으면서 출금 계좌 있을 때
      if (fromPurchase && hasWithdrawAccount) {
        dispatch(
          openModal(
            <div className="bg_gr_wrap">
              <div className="modal_wrap02">
                <img src={ImgPassbook0393} alt="" />
                <h3>등록된 출금계좌가 있어요.</h3>
                <p>
                  등록하신 <span className="fc-bl03">출금 계좌</span>를{" "}
                  <span className="fc-bl03">입금 계좌</span>로
                  <br />
                  사용하시겠어요?
                </p>
                <div className="modal_btn">
                  <button
                    className="bg_gr_btn"
                    onClick={() => {
                      setStep(2);
                      dispatch(closeModal());
                    }}
                  >
                    신규등록
                  </button>
                  <button
                    className="bg_bk_btn"
                    style={{
                      backgroundColor: colors.buttonColor,
                    }}
                    onClick={() => {
                      bankAccountChange.mutate("DEPOSIT");
                    }}
                  >
                    출금계좌 사용
                  </button>
                </div>
              </div>
            </div>,
          ),
        );
      } else {
        // 출금 게좌 없을 때
        setStep(2);
      }
    } else {
      if (!selectedBank) {
        dispatch(
          showToast({ message: "은행을 선택해주세요.", icon: "caution" }),
        );
        return;
      }
      if (!accountNumber) {
        dispatch(
          showToast({ message: "계좌번호를 확인해주세요.", icon: "caution" }),
        );
        return;
      }
      const params: IBankProps = {
        bankCode: selectedBank?.code || "",
        number: accountNumber,
        bankType: bankType,
      };
      bankAccountAssign.mutate(params);
    }
  };

  useEffect(() => {
    if (selectedBank === null) {
      setIsInputFocused(false);
    }
  }, [selectedBank]);

  return (
    <div>
      <SelectBank
        bankList={BankList}
        selectedBank={selectedBank}
        onChange={handleBankChange}
      />
      <div className="sub_top">
        <div>
          <img
            src={IconLeft}
            alt=""
            className="icon_back"
            onClick={handlePrevStep}
          />
          <p>{isAcccountChange ? "계좌 변경" : "계좌 인증"}</p>
        </div>
      </div>
      <div className="sub03_wrap">
        {step === 1 ? (
          // 1) 계좌인증 안내 페이지
          <div className="passbook_top">
            <img
              className={isAcccountChange ? styles.securityImg : ""}
              src={isAcccountChange ? SecurityImg : PassbookImg}
              alt="passbook"
            />
            <h3>
              계좌 {isAcccountChange ? "변경" : "등록"}을 위한
              <br />
              <span className="fc-bl03">계좌 인증</span>이 필요합니다.
            </h3>
          </div>
        ) : (
          // 2) 은행 및 계좌 입력 페이지
          <>
            <div className="sell_wrap06 sell_wrap00">
              <div className="sub03_wrap_top">
                <h3>
                  계좌 인증 <img src={SafeIcon} alt="Safe Icon" />
                </h3>
                <p>
                  <span>본인 계좌</span>만 <span>등록 가능</span>합니다.
                </p>
              </div>
              <div className="sell_sub_wrap01">
                <ul>
                  <li
                    className={`op_sel ${
                      selectedBank === null ? styles.checkedAfter : ""
                    }`}
                    onClick={handleOpenSelectBank}
                  >
                    <p>은행</p>
                    <h3>
                      {selectedBank ? selectedBank.name : "선택해 주세요."}
                    </h3>
                  </li>
                  <li
                    className={`price02 ${
                      isInputFocused ? styles.checkedAfter : ""
                    }`}
                  >
                    <input
                      type="tel"
                      placeholder="숫자만 입력해 주세요."
                      id="num"
                      value={accountNumber}
                      onChange={handleAccountNumberChange}
                      onFocus={() => setIsInputFocused(true)}
                      onBlur={() => setIsInputFocused(false)}
                    />
                    <label htmlFor="num" style={{ width: "100%" }}>
                      <p>계좌번호</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="sub_btn_wrap">
        {step === 1 ? (
          <button
            className="sub_wrap02_btn on"
            onClick={handleNextStep}
            style={{
              backgroundColor: colors.buttonColor,
            }}
          >
            계좌 인증 하기
          </button>
        ) : (
          <button
            className={`sub_wrap02_btn ${accountNumber ? "on" : ""}`}
            onClick={handleNextStep}
            disabled={!selectedBank && !accountNumber}
            style={{
              backgroundColor: accountNumber && colors.buttonColor,
            }}
          >
            확인
          </button>
        )}
      </div>
    </div>
  );
};

export default AccountVerification;
