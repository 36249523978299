import { ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";

import iconMenu from "@assets/icons/icon_menu.png";
import { openMenu } from "@/features/drawer/drawerSlice";
import { routes } from "@/routes";

type Props = {};

function HamburgerMenu({}: Props) {
  const navigate = useNavigate();

  return (
    <ButtonBase
      className="menu"
      onClick={() => {
        openMenu();
        navigate(routes.menu);
      }}
    >
      <img src={iconMenu} alt="" />
    </ButtonBase>
  );
}

export default HamburgerMenu;
