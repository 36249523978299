import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as styles from "../styles.css";
import { RootState } from "@/app/store";
import { numberWithCommas } from "@/utils/utils";
import { showToast } from "@/features/toast/toastSlice";
import { setPurchaseInfo } from "@/features/purchase/purchaseSlice";
import useUserAssets from "../hooks/useUserAssets";
import checkboxOn from "@assets/icons/checkBox_on.png";
import checkboxOff from "@assets/icons/checkBox_off.png";

interface IAssetsProps {
  assetType: string;
  goodsGram: number;
}

const Assets: React.FC<IAssetsProps> = ({ assetType, goodsGram }) => {
  const dispatch = useDispatch();
  const purchaseInfo = useSelector((state: RootState) => state.purchase);
  const [useGram, setUseGram] = useState<string | number>(
    purchaseInfo?.useGram || "",
  );
  const [isInputChanged, setIsInputChanged] = useState(false); // input 변경 상태를 추가
  const { UserAssets } = useUserAssets();
  const { isUseKrw } = purchaseInfo;
  const inputRef = useRef<HTMLInputElement>(null);

  // 결제 수단이 가상계좌가 되면 현금 사용 필수
  useEffect(() => {
    if (purchaseInfo.depositMethod === "NONE") {
      dispatch(setPurchaseInfo({ isUseKrw: true }));
    }
  }, [purchaseInfo.depositMethod]);

  // 외부 클릭 감지
  useEffect(() => {
    const handleOutsideInteraction = (event: Event) => {
      if (
        isInputChanged &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        handleInputBlur({ target: { value: useGram.toString() } });
      }
    };

    document.addEventListener("touchstart", handleOutsideInteraction); // 모바일웹 터치 이벤트
    document.addEventListener("mousedown", handleOutsideInteraction); // 데스크탑용 마우스 클릭 이벤트

    return () => {
      document.removeEventListener("touchstart", handleOutsideInteraction);
      document.removeEventListener("mousedown", handleOutsideInteraction);
    };
  }, [useGram, isInputChanged]);

  const handleInputBlur = (e: { target: { value: string } }) => {
    const value = e.target.value;
    if (value === "") {
      setUseGram("");
      dispatch(setPurchaseInfo({ useGram: 0 }));
      setIsInputChanged(false);
      return;
    }
    const inputValue = parseFloat(value) || 0;
    const maxGram = goodsGram * purchaseInfo.qty;
    const userGram =
      assetType === "GOLD"
        ? UserAssets?.acceptableGold
        : UserAssets?.acceptableSilver;
    // 상품 중량 및 가용 자산 초과 시 최대값으로 조정
    const adjustedGram = Math.min(inputValue, maxGram, userGram ?? 0);
    setUseGram(adjustedGram);
    dispatch(setPurchaseInfo({ useGram: adjustedGram }));
    setIsInputChanged(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    // 소수점이 포함된 경우, 선행 0을 유지
    if (inputValue.startsWith("0.") || inputValue === "0") {
      setUseGram(inputValue);
      setIsInputChanged(true);
      return;
    }

    // 소수점이 없는 경우, 선행 0 제거
    if (inputValue.startsWith("0")) {
      inputValue = inputValue.replace(/^0+/, "");
    }
    setUseGram(inputValue);
    setIsInputChanged(true);
  };

  const handleAssetToggle = () => {
    const maxGram = goodsGram * purchaseInfo.qty;
    const userGram =
      assetType === "GOLD"
        ? UserAssets?.acceptableGold
        : UserAssets?.acceptableSilver;
    const maxAvailableGrams = Math.min(maxGram, userGram ?? 0);

    if (useGram === maxAvailableGrams) {
      setUseGram("");
      dispatch(setPurchaseInfo({ useGram: 0 }));
    } else {
      setUseGram(maxAvailableGrams);
      dispatch(setPurchaseInfo({ useGram: maxAvailableGrams }));
    }
    setIsInputChanged(true);
  };

  const handleCashToggle = () => {
    if (purchaseInfo.depositMethod === "NONE") {
      dispatch(
        showToast({
          message:
            "가상 계좌 입금 및 결제 방식 선택 시 보유한 현금을 사용해서 결제합니다.",
          icon: "info",
        }),
      );
    } else {
      dispatch(setPurchaseInfo({ isUseKrw: !isUseKrw }));
    }
  };
  const maxGram = goodsGram * purchaseInfo.qty;
  const userGram =
    assetType === "GOLD"
      ? UserAssets?.acceptableGold
      : UserAssets?.acceptableSilver;
  const maxAvailableGrams = Math.min(maxGram, userGram ?? 0);

  return (
    <div>
      <div className="shadow88 sub02_04">
        <h3 className="shadow88_tit">보유 자산</h3>
        <div className="box_tit02 mb08">
          <div className="box_stit02">
            <h3>현금</h3>
            <div className={styles.flexRow}>
              <img
                className={styles.checkImg}
                src={isUseKrw ? checkboxOn : checkboxOff}
                alt="checkbox"
                onClick={handleCashToggle}
              />
              <div className={styles.checkboxLabelText}>
                보유한 현금 전액 사용
              </div>
            </div>
          </div>
          <ul className="sub02_04_ul01">
            <li>
              <p>사용 가능</p>
            </li>
            <li className="fc-bl02">
              <span>{numberWithCommas(UserAssets?.acceptableKrw ?? 0)}</span>{" "}
              KRW
            </li>
          </ul>
        </div>
        <div className="box_tit02 mb08">
          <div className="box_stit02">
            <h3>{assetType === "GOLD" ? "금" : "은"}</h3>
            <button className={styles.allUseBtn} onClick={handleAssetToggle}>
              {useGram === maxAvailableGrams ? "사용 안함" : "모두 사용"}
            </button>
          </div>
          <ul className="sub02_04_ul01">
            <li>
              <p>사용 가능</p>
            </li>
            <li className="fc-bl02">
              <span>{numberWithCommas(userGram)}</span> g
            </li>
          </ul>
          <input
            ref={inputRef}
            type="number"
            placeholder="사용할 중량을 입력하세요."
            value={useGram.toString()}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleInputBlur({ target: { value: useGram.toString() } });
              }
            }}
            onChange={(e) => {
              const value = e.target.value;
              const isValidInput =
                assetType === "GOLD"
                  ? /^\d*\.?\d{0,3}$/.test(value) // 금: 소수점 셋째 자리까지 허용
                  : /^\d*\.?\d{0,1}$/.test(value); // 은: 소수점 첫째 자리까지 허용
              if (isValidInput) handleInputChange(e);
            }}
          />
        </div>
        <div className="box_tit02 mb08">
          <div className="box_stit02">
            <h3>포인트</h3>
          </div>
          <ul className="sub02_04_ul01">
            <li>
              <p>사용 가능</p>
            </li>
            <li className="fc-bl02">
              <span>{numberWithCommas(UserAssets?.acceptablePoint ?? 0)}</span>{" "}
              pt
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Assets;
