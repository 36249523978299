import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";

const initialState = {
  isOpen: false,
};

export const DrawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    openMenu: (state) => {
      state.isOpen = true;
    },
    // 바텀시트 닫기
    closeMenu: (state) => {
      state.isOpen = false;
    },
  },
});

export const { openMenu, closeMenu } = DrawerSlice.actions;
export const selectMenuState = (state: RootState) => state.drawer;

export default DrawerSlice.reducer;
