import useCustomQuery from "@/hooks/queries/useCustomQuery";
import * as APIS from "@/services/apis";
import {
  showLoading,
  selectLoading,
  hideLoading,
} from "@/features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { routes } from "@/routes";
import { GoodsOptionInfo } from "../components/SelectOption";

interface GoodsDetailInfo {
  goodsId: number;
  goodsImages: string[];
  brand: string;
  goodsName: string;
  goodsGram: number;
  standardPrice: number;
  isSoldOut: boolean;
  sellingPrice: number;
  goodsDetailImages: string[];
  goodsInfos: string[];
  goodsDetailInfoImage: string;
  goodsOptionsInfos: GoodsOptionInfo[];
  assetType: string;
  earliestVisitDate: string;
  isPost: boolean;
  comment: string;
  eventPoint: number;
  isShopTaken: boolean;
  fee: number; // 실물인출 수수료
}

const useGoodsDetail = (goodsId: string) => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getGoodsDetail(goodsId);
      dispatch(hideLoading());
      return response.data.data as GoodsDetailInfo;
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading } = useCustomQuery<GoodsDetailInfo>(
    [routes.buyGoodsDetail, { goodsId }],
    fetchDetails,
  );

  return { GoodsDetailInfo: data, isLoading, error };
};

export default useGoodsDetail;
