import { ButtonBase } from "@mui/material";
import ic_arrow_left from "@/assets/icons/icon_arrow_left_c.png";

type Props = {
  onClickKeypad: Function;
  modalType: string;
};

function CommonKeypad({ onClickKeypad, modalType }: Props) {
  return (
    <div className="keypad">
      <table>
        <tbody>
          {[1, 4, 7, 10].map((item: number) => {
            if (item < 10) {
              return (
                <tr key={`keypad_row_${item}`}>
                  <td>
                    <ButtonBase
                      onClick={() => {
                        onClickKeypad(item);
                      }}
                    >
                      {item}
                    </ButtonBase>
                  </td>
                  <td>
                    <ButtonBase
                      onClick={() => {
                        onClickKeypad(item + 1);
                      }}
                    >
                      {item + 1}
                    </ButtonBase>
                  </td>
                  <td>
                    <ButtonBase
                      onClick={() => {
                        onClickKeypad(item + 2);
                      }}
                    >
                      {item + 2}
                    </ButtonBase>
                  </td>
                </tr>
              );
            } else {
              return (
                <tr key={`keypad_row_${item}`}>
                  <td>
                    {modalType === "price" ? (
                      <ButtonBase
                        onClick={() => {
                          onClickKeypad("deleteAll");
                        }}
                      >
                        <p>전체삭제</p>
                      </ButtonBase>
                    ) : (
                      <ButtonBase
                        onClick={() => {
                          onClickKeypad("decimal");
                        }}
                      >
                        ・
                      </ButtonBase>
                    )}
                  </td>
                  <td>
                    <ButtonBase
                      onClick={() => {
                        onClickKeypad(0);
                      }}
                    >
                      0
                    </ButtonBase>
                  </td>
                  <td>
                    <ButtonBase
                      onClick={() => {
                        onClickKeypad("delete");
                      }}
                    >
                      <img src={ic_arrow_left} alt="marketprice_delete_icon" />
                    </ButtonBase>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
}

export default CommonKeypad;
