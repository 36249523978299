import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { routes } from "@/routes";

function ReviewGateway() {
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;

  useEffect(() => {
    const isShop = id?.includes("-");
    if (isShop) {
      navigate(`${routes.shopDetail}/${id}`, { replace: true });
    } else {
      navigate(`${routes.buyGoodsDetail}/${id}?from=reviewHistory`, {
        replace: true,
      });
    }
  }, [id, navigate]);

  return null;
}

export default ReviewGateway;
