import iconX from "@assets/icons/icon_x.png";
import icon88X from "@assets/icons/icon_88_x.png";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "@/routes";
import { RootState } from "@/app/store";
import { useSelector } from "react-redux";
import * as styles from "../styles.css";
import colors from "@assets/styles/colors.css";

const PurchaseFail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const purchaseInfo = useSelector((state: RootState) => state.purchase);

  const handleClickPurchase = () => {
    navigate(`${routes.buyGoodsPurchase}/${purchaseInfo.goodsId}`);
  };

  return (
    <div>
      <div className="sub_top02">
        <div className={styles.headerTitle}>주문/결제</div>
        <img src={iconX} className="close" onClick={handleClickPurchase} />
      </div>
      <div className="sub02_wrap sub02_wrap03">
        <div className="sub02_wrap03_top">
          <img src={icon88X} className="icon_88" />
          <h3 className="sub02_wrap03_tit">
            <span className="fc-rd02">결제 실패</span> 되었습니다.
          </h3>
        </div>
        <div className="shadow88 gap">
          <div className="sub02_02 fail">
            <h3 className="shadow88_tit">결제 정보</h3>
            <ul className="sub02_02_ul02">
              <li>
                <p>이유</p>
              </li>
              <li>
                <h3 className="fc-rd02">{location.state?.failReason}</h3>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub02_wrap03_btn">
          <button
            className="bk-btn01 tooltip01"
            onClick={handleClickPurchase}
            style={{
              backgroundColor: colors.buttonColor,
            }}
          >
            돌아가기
          </button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseFail;
