import React, { useState } from "react";
import IconLeft from "@assets/icons/icon_left.png";
import ProgressIng from "@assets/images/img_progress_ing.png";
import ProgressDefault from "@assets/images/img_progress02.png";
import ProgressPin from "@assets/images/img_progress_fin.png";
import iconCau from "@/assets/icons/ico_56px_cau.png";

import { useNavigate, useParams } from "react-router-dom";
import useShoppingDetail from "../hooks/useShoppingDetail";
import consts, { TRANS_HISTORIES } from "@/utils/constants";
import { formatDateTime, numberWithCommas, postMessage } from "@/utils/utils";
import useBuyConfirm from "../hooks/useBuyConfirm";
import ShoppingHowReceive from "./ShoppingHowReceive";
import useShoppingCancel from "../hooks/useShoppingCancel";
import * as styles from "../styles.css";
import colors from "@assets/styles/colors.css";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "@/features/modal/modalSlice";

const ShoppingDetail: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const transId = param.id || "";
  const {
    ShoppingDetail,
    isLoading: ShoppingLoading,
    ShoppingDetailRefetch,
  } = useShoppingDetail(transId);
  const [isCashReceipt, setIsCashReceipt] = useState<boolean>(false);
  const shoppingConfirm = useBuyConfirm(ShoppingDetailRefetch);
  const shoppingCancel = useShoppingCancel();

  if (!ShoppingDetail) {
    return null;
  }

  const {
    earliestVisitDate,
    goodsTrans,
    shopInfo,
    payment,
    history,
    delivery,
    deliveryTrace,
  } = ShoppingDetail;

  const { goodsTransStatus } = goodsTrans;

  const assetTypeKor = goodsTrans?.assetType === "GOLD" ? "금" : "은";
  // 결제일시
  const PaymentCompleted =
    history
      ?.find((step) => step.status === "PAYMENT_COMPLETED")
      ?.createdAt.replace("T", " ") || "";
  // 현재 상태
  const currentStatus =
    history.length > 0 ? history[history.length - 1].status : null;
  const currentStatusDescription = goodsTrans.goodsStatusDescription;
  // 진행과정
  const goodsTransHistory =
    TRANS_HISTORIES[delivery === null ? "VISIT" : "DELIVERY"];
  // 현금영수증 탭 노출상태
  const canCashReceiptStatus =
    goodsTransStatus === "RECEIVED" || goodsTransStatus === "CONFIRMED";

  const findCreatedAt = (status: string) => {
    const item = history.find((h) => h.status === status);
    return item ? item.createdAt : null;
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleClickConfirm = () => {
    shoppingConfirm.mutate({ transId, isCashReceipt });
  };

  const handleClickCancel = () => {
    dispatch(
      openModal(
        <div className="bg_gr_wrap">
          <div className="modal_wrap">
            <img src={iconCau} alt="caution" />
            <h3>인출 주문을 취소하시겠어요?</h3>
            <p>
              주문 취소시 구매 중량과 <br />
              인출 수수료로 환불 처리 됩니다.
            </p>
            <div className="modal_btn">
              <button
                className="bg_gr_btn"
                onClick={() => {
                  shoppingCancel.mutate(transId);

                  dispatch(closeModal());
                }}
                style={{ backgroundColor: "#ffe5e5", color: "#f22" }}
              >
                인출 주문 취소
              </button>
              <button
                className="bg_bk_btn"
                onClick={() => dispatch(closeModal())}
                style={{ backgroundColor: "#eee", color: "#222" }}
              >
                아니요
              </button>
            </div>
          </div>
        </div>,
      ),
    );
  };

  const handleChangeIsCashReceipt = (isCashReceipt: boolean) => {
    if (goodsTransStatus !== "CONFIRMED") {
      setIsCashReceipt(isCashReceipt);
    }
  };

  const DELIVERY_CORP = [
    {
      name: "VALEX",
      url: "https://vnos.valex.co.kr/pages/progressStatus/nonScheduleStts/popup/jobOrderList2/",
    },
    {
      name: "EPOST",
      url: "https://m.epost.go.kr/postal/mobile/mobile.trace.RetrieveDomRigiTraceList.comm?sid1=",
    },
  ];

  const handleOpenDeliveryTracking = () => {
    if (delivery && delivery.deliveryCorp && delivery.regiNo) {
      const deliveryInfo = DELIVERY_CORP.find(
        (corp) => corp.name === delivery.deliveryCorp,
      );
      if (deliveryInfo) {
        const url = `${deliveryInfo.url}${delivery.regiNo}`;
        postMessage({
          command: "WINDOW_OPEN",
          param: { url },
        });
      }
    }
  };

  const getDeliveryCorpName = (corpCode: string | null | undefined): string => {
    if (corpCode === "VALEX") {
      return "발렉스 특수물류";
    } else if (corpCode === "EPOST") {
      return "우체국 택배";
    } else {
      return "";
    }
  };

  return (
    <div>
      <div className="sub_top04">
        <div>
          <img
            src={IconLeft}
            alt=""
            className="icon_back"
            onClick={handleClickBack}
          />
          <p>쇼핑 내역</p>
        </div>
      </div>
      <div className="sub_wrap08 shopping">
        <div className="shadow88 mb16 shopping_box01">
          <div className="cont_number02">
            <ul>
              <li className="cont_numli01">
                <p>주문 번호</p>
              </li>
              <li className="cont_numli02">
                <p>{goodsTrans?.orderNumber}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="shadow88 mb16  shopping_box02">
          <h3 className="shadow88_tit fc-bk01">주문 상품</h3>
          <div className="pd_option_wrap">
            <div className="option_img">
              <img src={consts.s3BaseUrl + "/" + goodsTrans?.goodsImage} />
            </div>
            <div className="option_cont">
              <p className="pd_brand">{goodsTrans?.brand}</p>
              <p className="pd_name">{goodsTrans?.goodsName}</p>
              <div className="option_box">
                <ul>
                  {goodsTrans?.optionName && (
                    <li>
                      <p>
                        <span>선택</span>
                        {goodsTrans?.optionName}
                      </p>
                    </li>
                  )}
                  <li>
                    <p>
                      <span>수량</span>
                      {numberWithCommas(goodsTrans?.qty || 0)}개
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <h3 className="tt_price">
            {numberWithCommas(goodsTrans?.paymentPrice || 0)}원
          </h3>
          {canCashReceiptStatus && (
            <div className="shopping_box07">
              <h3 className="shadow88_tit fc-bk01">현금영수증 발급 신청</h3>

              <div
                className={`select_btn mb08 ${
                  goodsTransStatus === "CONFIRMED" ? "selected" : ""
                }`}
              >
                <ul>
                  <li>
                    <input
                      type="radio"
                      name="select02"
                      id="box7_select01"
                      checked={
                        goodsTransStatus === "CONFIRMED"
                          ? goodsTrans.cashReceipt === "발급요청"
                          : isCashReceipt
                      }
                      onClick={() => handleChangeIsCashReceipt(true)}
                    />
                    <label htmlFor="box7_select01">
                      <p>신청</p>
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="select02"
                      id="box7_select02"
                      checked={
                        goodsTransStatus === "CONFIRMED"
                          ? goodsTrans.cashReceipt === null
                          : !isCashReceipt
                      }
                      onClick={() => handleChangeIsCashReceipt(false)}
                    />
                    <label htmlFor="box7_select02">
                      <p>미신청</p>
                    </label>
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  <span>가입 시 입력하신 휴대폰 번호로 발급</span>됩니다.
                </p>
              </div>
              <div className="purchase_btn">
                <button
                  className={goodsTransStatus === "RECEIVED" ? "on" : "fin"}
                  onClick={() => {
                    goodsTransStatus === "RECEIVED" && handleClickConfirm();
                  }}
                >
                  구매 확정
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="sell_sub_accordion sell_sub_vertical shadow88 mb16 shopping_box03">
          <h3 className="shadow88_tit fc-bk01">진행 상황</h3>
          <ul>
            <li className="acco_li">
              <input
                type="checkbox"
                id="checkbox-1"
                name="checkbox-accordion"
              />
              <label htmlFor="checkbox-1">
                <div className="btn_cx_down"></div>
                <div className="btn_cx_up"></div>
              </label>
              <div className="sell_sub_content">
                <div className="sell_sub_content_box">
                  {goodsTransHistory.map((step, index) => {
                    const isCurrentStatus = step.status === currentStatus;

                    const isBeforeCurrent =
                      goodsTransHistory.findIndex(
                        (s) => s.status === currentStatus,
                      ) > index;
                    const iconSrc = isCurrentStatus
                      ? ProgressIng
                      : isBeforeCurrent
                      ? ProgressPin
                      : ProgressDefault;
                    const createdAt = findCreatedAt(step.status);

                    return (
                      <div
                        className={isCurrentStatus ? "on" : "off"}
                        key={step.status}
                      >
                        <p className="box-tit">
                          <img src={iconSrc} />
                          {step.description}
                        </p>
                        {createdAt && <span>{formatDateTime(createdAt)}</span>}
                      </div>
                    );
                  })}
                </div>
              </div>
              <h3 className="acco_main ing">{currentStatusDescription}</h3>
            </li>
          </ul>
        </div>
        {delivery && delivery.regiNo && (
          <div className={`shadow88 mb16 ${styles.shoppingBox06}`}>
            <h3 className={styles.shadow88Tit}>배송 조회</h3>
            <div>
              <ul className={styles.shoppingUlListItem}>
                <li>
                  <p className={styles.text}>송장번호</p>
                </li>
                <li>
                  <h3 className={styles.heading}>{delivery.regiNo}</h3>
                </li>
              </ul>
              <ul className={styles.shoppingUlListItem}>
                <li>
                  <p className={styles.text}>배송업체</p>
                </li>
                <li>
                  <h3 className={styles.heading}>
                    {getDeliveryCorpName(delivery.deliveryCorp)}
                  </h3>
                </li>
              </ul>
            </div>{" "}
            <div className={styles.purchaseBtn}>
              <button
                className={styles.button}
                onClick={handleOpenDeliveryTracking}
              >
                배송 조회
              </button>
            </div>
          </div>
        )}
        <div className="shadow88 mb16  shopping_box06">
          <h3 className="shadow88_tit fc-bk01">결제 정보</h3>
          <div className="shopping_ul">
            <ul>
              <li>
                <p className="fc-gr01">결제일시</p>
              </li>
              <li>
                <h3 className="fc-gr02">{PaymentCompleted}</h3>
              </li>
            </ul>
            <ul>
              <li>
                <p className="fc-gr01">포인트 사용</p>
              </li>
              <li>
                <h3 className="fc-gr02">
                  -{numberWithCommas(payment?.point || 0)}pt
                </h3>
              </li>
            </ul>
            <ul>
              <li>
                <p className="fc-gr01">보유현금 차감</p>
              </li>
              <li>
                <h3 className="fc-gr02">
                  -{numberWithCommas(payment?.krw || 0)}원
                </h3>
              </li>
            </ul>
            <ul>
              <li>
                <p className="fc-gr01">보유자산 {assetTypeKor}</p>
              </li>
              <li>
                <h3 className="fc-gr02">-{payment?.gram || 0}g</h3>
              </li>
            </ul>
          </div>
          <ul className="tt_price">
            <li>
              <p className="fc-bk01">결제 금액</p>
            </li>
            <li>
              <h3 className="fc-bk01">
                {numberWithCommas(payment?.depositPrice || 0)}원
              </h3>
            </li>
          </ul>
        </div>
        <ShoppingHowReceive
          goodsTrans={goodsTrans}
          delivery={delivery}
          shopInfo={shopInfo}
          earliestVisitDate={earliestVisitDate}
          ShoppingLoading={ShoppingLoading}
          ShoppingDetailRefetch={ShoppingDetailRefetch}
        />
        {goodsTransStatus === "PAYMENT_COMPLETED" && (
          <button className="cancel_btn" onClick={handleClickCancel}>
            주문 취소
          </button>
        )}
      </div>
    </div>
  );
};

export default ShoppingDetail;
