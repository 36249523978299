import React, { useEffect } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useSelector, useDispatch } from "react-redux";
import { ComponentProps } from "../../../model/bottomSheet";
import {
  closeBottomSheet,
  openBottomSheet,
  selectBottomSheet,
} from "@/features/bottom-sheet/bottomSheetSlice";
import Loading from "../loading/Loading";
import { drawerStyle } from "./style.css";

const BottomSheet: React.FC<ComponentProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(selectBottomSheet);

  const handleClickReservation =
    (newOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event === undefined ||
        (event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift"))
      ) {
        dispatch(closeBottomSheet());
        return;
      }
      dispatch(newOpen ? openBottomSheet() : closeBottomSheet());
    };

  useEffect(() => {
    return () => {
      dispatch(closeBottomSheet());
    };
  }, []);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={handleClickReservation(false)}
      onOpen={handleClickReservation(true)}
      classes={{ paper: drawerStyle }}
    >
      {children}
      <Loading />
    </SwipeableDrawer>
  );
};

export default BottomSheet;
